import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfilePhoto, getUpdateProfile, getUserProfile } from "./editProfileApi";
import { toast } from "react-toastify";

const initialState = {
  profilePhoto: null,
  updateprofile: null,
  userProfile: null,
  status: "idle",
  status1: {
    profilePhotoStatus: "idle"
  }
};

export const profilePhotoAsync = createAsyncThunk("profile/photo", async (data) => {
  const response = await getProfilePhoto(data);
  return response.data;
});

export const updateProfileAsync = createAsyncThunk("profile/update", async (data) => {
  const response = await getUpdateProfile(data);
  return response.data;
});

export const userProfileAsync = createAsyncThunk("profile/user", async (data) => {
  const response = await getUserProfile(data);
  return response.data;
});

export const profilePhotoSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    logout: (state) => {
      state.profilePhoto = null;
      toast.success("Successfully Logout")
    },
  },

  extraReducers: {

    [profilePhotoAsync.pending](state) {
      state.status = "loading";
    },
    [profilePhotoAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.profilePhoto = payload;
    },
    [profilePhotoAsync.rejected](state, { error }) {
      state.status = "idle";
      state.profilePhoto = [];
    },
    [updateProfileAsync.pending](state) {
      state.status = "loading";
    },
    [updateProfileAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.updateprofile = payload;
    },
    [updateProfileAsync.rejected](state, { error }) {
      state.status = "idle";
      state.updateprofile = [];
    },
    [userProfileAsync.pending](state) {
      state.status = "loading";
    },
    [userProfileAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.userProfile = payload;
    },
    [userProfileAsync.rejected](state, { error }) {
      state.status = "idle";
      state.userProfile = [];
    }
  },
});

export const selectProfilePhoto = (state) => state.editProfilePhoto.profilePhoto;
// export const selectUpdateProfileData = (state) => state;
export const selectUserProfile = (state) => state.userProfile.userProfile;
export default profilePhotoSlice.reducer;