import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import CustomModal from "../../components/shared/CustomModal";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Info, orderDetails, bxsCoinStackImg, orderTwoSvg, leftArrowImg, starCircleImg, WalletBlue, plusImg, LogoIcon, arrowRightSideImg, LocationDark, locationImg, edit2Img, OrderBlue, veirfy2Img, Waiting } from "../../../utilities/images";
import { selectAllAddress } from "../addresses/addressSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { getReorderByUserAsync, selectReorderByUser } from "../dashboard/dashboardSlice";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { createOrderApiAsync } from "../cart/cartSlice";
import moment from "moment-timezone";

const PastOrderView = (props) => {
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()
    const [zoom, setZoom] = useState(15)
    const [latitude, setLatitude] = useState(30.900965)
    const [longitude, setLongitude] = useState(75.8572758)
    const addressData = useSelector(selectAllAddress)
    console.log(addressData, 'addressData');
    const toastId = React.useRef(null)
    const { id } = useParams()
    const auth = useSelector(selectLoginAuth)
    const reorderProducts = useSelector(selectReorderByUser)
    console.log(reorderProducts, 'reorderProducts')
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap
                    defaultZoom={zoom}
                    defaultCenter={{ lat: latitude, lng: longitude }}
                >
                    <Marker
                        name={''}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                        position={{ lat: reorderProducts?.data?.payload?.coordinates[0], lng: reorderProducts?.data?.payload?.coordinates[1] }}
                    />
                </GoogleMap>
            )
        )
    );
    
    const onMarkerDragEnd = (event) => {
        //dragger event
    }

    const data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        dispatch(getReorderByUserAsync(data))
    }, [dispatch])

    let reorderData = {
        "postData":
        {
            // "cart_id": reorderProducts?.data?.payload,
            "address_id": reorderProducts?.data?.payload?.address_id,
            "address_type": reorderProducts?.data?.payload?.address_type,
            "address": reorderProducts?.data?.payload?.address,
            "address_2": reorderProducts?.data?.payload?.address_2,
            "city": reorderProducts?.data?.payload?.city,
            "state": reorderProducts?.data?.payload?.state,
            "zip": reorderProducts?.data?.payload?.zip,
            "country": reorderProducts?.data?.payload?.country,
            "fax": reorderProducts?.data?.payload?.fax,
            "shipping": reorderProducts?.data?.payload?.shipping,
            "coordinates": [
                location?.state ? locationData?.longitude : reorderProducts?.data?.payload?.coordinates[0],
                location?.state ? locationData?.latitude : reorderProducts?.data?.payload?.coordinates[1]
            ],
            "date": moment(reorderProducts?.data?.payload?.date).format('YYYY-MM-DD'),
           
            "service_id": "1"
        },
        "tokenData": auth?.payload?.token
    }

    const handleOrder = async () => {
        await dispatch(createOrderApiAsync(reorderData))
            .then(unwrapResult)
            .then(async (obj) => {
                console.log(obj, "oreder response")
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/tracking')
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                // }
            })
    }

    const renderOrderPlacedHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h4>Order Placed</h4>
                            <img src={veirfy2Img} alt="veirfy2Img" />
                            <p>Your order placed successfully</p>
                            <h6>Order is processing</h6>
                        </div>
                        <div className="mdlverify-btn mdllogin-btn" onClick={handleOrder}>
                            <Link to='#' className="active">Track order</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    let locationData = location?.state ? JSON.parse(location?.state) : ""
    

   
    const renderWarningHtml = () => {
        return (
            <div className="modal-body">
                <div className="modal-mainprt warning-mdlbox">
                    <div className="mdlverify-cnt cardmdl-verify">
                        <h4>Warning</h4>
                        <img src={Waiting} alt="" />
                        <p>No showing up</p>
                        <h6>No Refund!</h6>
                    </div>
                    <div className="mdlverify-btn mdllogin-btn">
                        <Link to="#" onClick={() => { setModalDetail({ show: true, flag: "OrderPlaced" }); setKey(Math.random()); }} className="active">Confirm & Place the order</Link>
                    </div>
                </div>
            </div>
        )
    }
    const renderServiceFeesHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-header address-mdlheader">
                    <h5><strong>Whats included?</strong></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { handleOnCloseModal() }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body address-mdlbody pt-0">
                    <div className="taxes-mdlbox-cnt">
                        <h4>Service fees <span>$1.50</span></h4>
                        <p>Cras lobortis ligula sed metus tempor, nec aliquet dolor suscipit. Nullam eleifend condimentum sollicitudin.</p>
                    </div>
                    <div className="taxes-mdlbox-cnt">
                        <h4>Taxes <span>$1.50</span></h4>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/orders'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Order Details </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="placeord-main">
                    {
                        addressData?.payload?.data?.length > 0
                            ?
                            <div className="delvaddres-boxr">
                                <h2><img src={LocationDark} alt="LocationDark" />Delivery Address </h2>
                                {/* <img className="delv-map" src={DeliveryMap} alt="DeliveryMap" /> */}
                                <div className="delv-map">
                                    <AsyncMap
                                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`}
                                        loadingElement={
                                            <div style={{ height: `100%` }} />
                                        }
                                        containerElement={
                                            <div style={{ height: '300px' }} />
                                        }
                                        mapElement={
                                            <div style={{ height: `100%` }} />
                                        }
                                    />
                                </div>
                                <h4>{reorderProducts?.data?.payload?.address_type}</h4>
                                <p>{reorderProducts?.data?.payload?.address}</p>
                                <div onClick={() => history.push({ pathname: '/addresses', state: "placing" })} className="aprt-btn">{addressData?.payload?.data[0]?.custom_address} <i className="fas fa-chevron-right"></i></div>
                            </div>
                            :
                            <div className="delvaddres-boxr">
                                <h2><img src={locationImg} alt="locationImg" />Add Delivery Address <Link to="#" onClick={() => history.push({ pathname: '/new-address', state: "placing" })}><img src={edit2Img} alt="edit2Img" /></Link></h2>
                            </div>
                    }
                    <div className="delvpaymnt-box">
                        <h2><img src={WalletBlue} alt="" /> Balance <Link to="#"><img src={plusImg} alt="plusImg" /></Link></h2>
                        <Link className="paymnt-dlbtn" to='/payment'><img src={LogoIcon} alt="LogoIcon" /> JBR<span>JBR 0.00</span></Link>
                    </div>

                    <div className="orderdlv-detailsbox">
                        <h2><img src={OrderBlue} alt="OrderBlue" /> Order details</h2>
                        <div className="details-ordmdl border-top-0 pt-0">
                            <ul>
                                {
                                    reorderProducts?.data?.payload?.order_details?.map((val, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="details-mdlleft">
                                                    <strong>{val?.qty}</strong> <small>x</small> {val?.product_details?.name} <span></span>
                                                </div>
                                                <p>${(reorderProducts?.data?.payload?.actual_amount)}</p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                        </div>

                        <div className="total-part">
                            <ul>
                                <li>Sub Total {reorderProducts?.data?.payload?.actual_amount ? <span>${(reorderProducts?.data?.payload?.actual_amount)}</span> : ""}</li>
                                <li>Coupon <span>-${reorderProducts?.data?.payload?.discount}</span></li>
                                <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>${reorderProducts?.data?.payload?.tax}</span></li>
                                <li>Delivery fees <span>${Number(reorderProducts?.data?.payload?.delivery_charge).toFixed(2)}</span></li>
                                <li><strong>Total</strong> <strong>${Number(reorderProducts?.data?.payload?.payable_amount).toFixed(2)}</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="compl-orcnt">
                        <h6>By completing this order, I agree to all <Link to="/terms-&-conditions">terms & conditions</Link></h6>
                    </div>
                </div>

                <div className="selected-product checkout-box">
                    <div className={(addressData?.payload?.data?.length > 0) ? "selected-itembox place-ordbtn" : "selected-itembox place-ordbtn place-misaddr"}>
                        <div className="selected-leftcnt">
                            <p><strong>Place Order</strong>{(addressData?.payload?.data?.length > 0) ? "" : <small>(Missing address)</small>} </p>
                        </div>
                        <div className="selected-rightbtn">
                            <Link to='#' onClick={() => { setModalDetail({ show: true, flag: "refund" }); setKey(Math.random()); }}>
                                <img src={arrowRightSideImg} alt="arrowRightSideImg" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={false}
                mediumWidth={modalDetail.flag === 'OrderPlaced' ? false : true}
                darkBackground={modalDetail.flag === 'OrderPlaced' ? true : false}
                size="md"
                child={modalDetail.flag === 'OrderPlaced' ?
                    renderOrderPlacedHtml()
                    : (modalDetail.flag === "refund") ?
                        renderWarningHtml()
                        :
                        renderServiceFeesHtml()
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default PastOrderView;
