import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../../public/images/logo.svg";
import user from "../../../public/images/user.svg";
import eamil1 from "../../../public/images/eamil1.svg";
import calender from "../../../public/images/calender.svg";
import lock from "../../../public/images/lock.svg";
import objectImg from "../../../public/images/objects.png";
import { registerAsync, selectVerifyResponse } from '../authSlice';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

const Step2 = (props) => {
    const history = useHistory();
    let data1 = JSON.parse(localStorage.getItem("phonedetails"))
    const [errorMessages, setErrorMessages] = useState({});
    const { status1 } = useSelector(state => state.auth)
    const [dob, setDob] = useState("")
    const toastId = React.useRef(null)
    const [formData, setFormData] = useState({
        userName: "",
        firstName: "",
        lastName: "",
        pin: "",
        confirmPin: ""
    });
    const dispatch = useDispatch()
    const data = {
        "username": formData.userName,
        "firstname": formData.firstName,
        "lastname": formData.lastName,
        "dob": dob,
        "security_pin": formData.pin,
        "confirm_security_pin": formData.confirmPin,
        "email": formData.email,
        "role_id": 2,
        "phone_code": data1?.phone_code,
        "phone_no": data1?.phone_no
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "sdf" });
    }

    const handleOnSubmit = () => {
        let regName = /^[a-zA-Z]+$/;
        let checkEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        if (!formData.userName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your user name");
            }
            return false;
        }
        else if (formData.userName.length < 3) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Username should be of 3 digits");
            }
            return false;
        }
        else if (!regName.test(formData.userName)) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Invalid username given.");
            }
            return false;
        }
        if (!formData.firstName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your first name");
            }
            return false;
        }
        else if (formData.firstName.length < 3) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("First Name should be of 3 digits");
            }
            return false;
        }
        else if (!regName.test(formData.firstName)) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Invalid firstname given.");
            }
            return false;
        }
        if (!formData.lastName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your last name");
            }
            return false;
        }
        else if (formData.lastName.length < 3) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Last Name should be of 3 digits");
            }
            return false;
        }
        else if (!regName.test(formData.lastName)) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Invalid lastname given.");
            }
            return false;
        }
        if (!dob) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select your date of birth");
            }
            return false;
        }
        if (!formData.pin) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter PIN");
            }
            return false;
        }
        if (!formData.email) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your email");
            }
            return false;
        }
        else if (!checkEmail.test(formData.email)) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Invalid email given.");
            }
            return false;
        }
        if (formData.pin.length < 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("PIN should be of 4 digits");
            }
            return false;
        }
        if (!formData.confirmPin) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please confirm your pin");
            }
            return false;
        }
        else if (formData.pin != formData.confirmPin) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("PIN and confirm PIN should be same");
            }
            return false;
        }
        dispatch(registerAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/dashboard')
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     console.log(obj)
                //     toastId.current = toast.error(obj?.message)
                // }
            })
    }

    useEffect(() => {
        // props.logout();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="logo-part">
                <Link to="#">
                    <img src={logo} alt="" />
                </Link>
            </div>

            <div className="register-main">
                <div className="container">
                    <div className="register-item">
                        <div className='formBox'>
                            <label htmlFor="r1">Choose a username</label>
                            <form>
                                <input autoComplete="off" className="user-name Customform-control" type="text" id="r1" value={formData.userName} placeholder="User name" name="userName" onChange={(e) => { handleChange(e, 'userName') }} />
                            </form>
                            <img className="imgicon" src={user} alt="" />
                        </div>
                        <div className='formBox'>
                            <label htmlFor="rf1">First name</label>
                            <form>
                                <input autoComplete="off" className="user-name Customform-control" type="text" id="rf1" value={formData.firstName} placeholder="First name" name="firstName" onChange={(e) => { handleChange(e, 'firstName') }} />
                            </form>
                            <img className="imgicon" src={user} alt="" />
                        </div>
                        <div className='formBox'>
                            <label htmlFor="rf2">Last name</label>
                            <form>
                                <input autoComplete="off" className="user-name Customform-control" type="text" id="rf2" value={formData.lastName} placeholder="Last name" name="lastName" onChange={(e) => { handleChange(e, 'lastName') }} />
                            </form>
                            <img className="imgicon" src={user} alt="" />
                        </div>
                        <div className='formBox'>
                            <label htmlFor="rf2">Email</label>
                            <form>
                                <input autoComplete="off" className="email Customform-control" type="text" id="rf2" value={formData.email} placeholder="Please enter your email" name="email" onChange={(e) => { handleChange(e, 'email') }} />
                            </form>
                            <img className="imgicon" src={eamil1} />
                        </div>
                        <div className='formBox'>
                            <label htmlFor="r2">Date of birth</label>
                            <form>
                                <DatePicker
                                    selected={dob}
                                    name="birth"
                                    className="birth Customform-control"
                                    onChange={(date) => setDob(date)}
                                    dateFormat="MM/dd/yyyy"
                                    maxDate={moment().subtract(10, 'years')._d}
                                    placeholderText="MM/DD/YYYY"
                                    autoComplete="off"
                                />
                            </form>
                            <img className="imgicon" src={calender} />
                        </div>
                        <div className='formBox'>
                            <label htmlFor="r3">Choose a PIN</label>
                            <form>
                                <input autoComplete="off" className="pin Customform-control" type="password" id="r3" value={formData.pin} maxLength="4" placeholder="0- 0- 0- 0" name="pin" onChange={(e) => { handleChange(e, 'pin') }} />
                            </form>
                            <span className="mb-4">Must be 4 digit </span>
                            <img className="imgicon" src={lock} />
                        </div>
                        <div className='formBox'>
                            <label htmlFor="r4">Confirm PIN</label>
                            <form>
                                <input autoComplete="off" className="pin Customform-control" type="password" id="r4" value={formData.confirmPin} maxLength="4" placeholder="0- 0- 0- 0" name="confirmPin" onChange={(e) => { handleChange(e, 'confirmPin') }} />
                            </form>
                            <img className="imgicon" src={lock} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom-part bottom-prt2 reg-bottom">
                <img src={objectImg} alt="objectImg" />
                <button type="button" className="btn btn-primary continue" disabled={status1.userStatus === "loading"} onClick={() => { handleOnSubmit() }}>
                    {status1.userStatus === "loading" && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Next</span>
                </button>
            </div>
        </>
    )
}

export default Step2;