import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getSupportApi,
    getSubjectApi,
    supportDocumentUrlApi,
    createSupportRequestApi
} from "./SupportRequestApi";

const initialState = {
    supportdetails: null,
    support: [],
    subject: [],
    supportImage: null,
    supportCreate: [],
    status: {
        supportdetails: 'idle',
        support: 'idle',
        subject: 'idle',
        supportImage: 'idle',
        supportCreate: 'idle'
    },
};

export const getSupportApiAsync = createAsyncThunk("dashboard/supportlist", async (data) => {
    const response = await getSupportApi(data);
    return response;
});
export const getSubjectApiAsync = createAsyncThunk("dashboard/subject", async () => {
    const response = await getSubjectApi();
    return response;
});
export const supportApiUrlAsync = createAsyncThunk("dashboard/spporturl", async (data) => {
    const response = await supportDocumentUrlApi(data);
    return response;
});
export const supportCreateApiAsync = createAsyncThunk("dashboard/supportcreate", async (data) => {
    const response = await createSupportRequestApi(data.postData, data.tokenData);
    return response;
});

export const supportSlice = createSlice({
    name: "dashboard",
    initialState,

    extraReducers: {

        [getSupportApiAsync.pending](state) {
            state.status.support = "loading";
        },
        [getSupportApiAsync.fulfilled](state, { payload }) {
            state.status.support = "idle";
            state.support = payload;
        },
        [getSupportApiAsync.rejected](state, { error }) {
            state.status.support = "idle";
            state.support = [];
        },
        [getSubjectApiAsync.pending](state) {
            state.status.subject = "loading";
        },
        [getSubjectApiAsync.fulfilled](state, { payload }) {
            state.status.subject = "idle";
            state.subject = payload;
        },
        [getSubjectApiAsync.rejected](state, { error }) {
            state.status.subject = "idle";
            state.subject = [];
        },
        [supportApiUrlAsync.pending](state) {
            state.status.supportImage = "loading";
        },
        [supportApiUrlAsync.fulfilled](state, { payload }) {
            state.status.supportImage = "idle";
            state.supportImage = payload;
        },
        [supportApiUrlAsync.rejected](state, { error }) {
            state.status.supportImage = "idle";
            state.supportImage = [];
        },
        [supportCreateApiAsync.pending](state) {
            state.status.supportCreate = "loading";
        },
        [supportCreateApiAsync.fulfilled](state, { payload }) {
            state.status.supportCreate = "idle";
            state.supportCreate = payload;
        },
        [supportCreateApiAsync.rejected](state, { error }) {
            state.status.supportCreate = "idle";
            state.supportCreate = [];
        }
    },
});

export const selectSupportData = (state) => state.support.support;
export const selectSubjectData = (state) => state.support.subject;
export const selectUploadedUrlData = (state) => state.support.supportImage;

export default supportSlice.reducer;