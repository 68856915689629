import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, editProfileImg, bxsCoinStackImg, profileImageImg, user2Img, emailImg, tellImg, verifyImg, calender2Img } from "../../../utilities/images";
import { toast } from "react-toastify";
import { profilePhotoAsync, selectProfilePhoto, selectUserProfile, userProfileAsync, updateProfileAsync } from "./profileSlice";
import { useSelector, useDispatch } from "react-redux";
import { selectLoginProfile } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const EditProfile = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const toastId = React.useRef(null)
    const selectProfile = useSelector(selectUserProfile);
    let userProfile = selectProfile?.payload?.user_profiles;
    // console.log(selectProfile,"99999999999999")
    let userName = '';
    if (userProfile) {
        if (userProfile?.firstname) {
            userName = userProfile.firstname;
        }
        // if (userProfile?.lastname) {
        //     userName = userName + " " + userProfile.lastname;
        // }
    }
    const [img, setImg] = useState("")
    const [document, setDocument] = useState()
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [userEmail, setUserEmail] = useState(userProfile ? userProfile.username : "");
    const [fullname, setFullname] = useState(userName ? userName : "");
    const [phoneNo, setPhoneNo] = useState(userProfile ? userProfile.full_phone_number : "");
    const [ssnNo, setSsnNo] = useState(userProfile ? userProfile.ssn_number : "");
    const [dob, setDob] = useState(userProfile ? userProfile.dob : "");
    const updatedProfileImage = useSelector(selectProfilePhoto);
    console.log(updatedProfileImage, "updatedProfileImage");
    const selectLoginUser = useSelector(selectLoginProfile);
    let loginId = selectLoginUser?.payload.id;
    console.log(img.myfile, "imggggggggggg")
    const status1w = useSelector(state => state?.editProfilePhoto)
    // console.log(status1w.status, "status1deeeeeeeeeee");
    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName == "userName") {
            setFullname(value);
        }
        else if (fieldName === "email") {
            setUserEmail(value.trim());
        }
        else if (fieldName === "mobile") {
            setPhoneNo(value)

        }
        else if (fieldName === "ssn") {
            setSsnNo(value)
        }
        else if (fieldName === "dob") {
            setDob(value)
        }

    };

    const handleCheck = async () => {
        if (!fullname) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your user name");
            }
            return;
        }
        else if (fullname.length < 3) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Username should be of 3 digits");
            }
            return;
        }
        // else if (!userEmail) {
        //     toast.error("Please enter your email");
        //     return;
        // }
        else if (!phoneNo) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter phone number");
            }
            return;
        }
        else if (phoneNo && phoneNo.length <= 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Phone number length should be of 5 to 15 digits");
            }
            return;
        }

        let params = {
            profile_photo: updatedProfileImage?.payload?.profile_photo,
            loginId: loginId,
            firstname: fullname,
            // lastname: "aaa",
            email: "dsdfs",
            dob: dob,
            ssn_number: ssnNo,
            token: selectLoginUser.payload.token,
            // email: "gurdeep@yopmail.com"
        }
        await dispatch(updateProfileAsync(params))
        history.push('/profile')


    }

    const handleProfileChange = async (e) => {
        setLoading(true);
        // setTimeout(() => {
        //   setLoading(false);
        // }, 2000);
        let param = {
            loginId: loginId,
            token: selectLoginUser.payload.token
        }
        await dispatch(userProfileAsync(param))
        const imageFile = e.target.files[0];
        const img_data = URL.createObjectURL(imageFile)
        setDocument(img_data);
        setLoading(false);
        let params = {
            profile: imageFile
        }
        console.log(params, "params");
        await dispatch(profilePhotoAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj, "objjjjjjjjj")
                let newFormValues = [...img];

                setImg(obj?.payload?.profile_photo);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                //     console.log(obj)
                // }
            })
        // let wasNull = false;
        // for (var i in img) {
        //     if ( img[i].image == "" || Object.keys(img[i]).length == 0) {
        //     wasNull = true
        // }
        // }

    }

    // useEffect(() => {
    //     let params = {
    //         loginId: loginId,
    //         token: selectLoginUser.payload.token
    //     }
    //     dispatch(userProfileAsync(params))
    // }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/profile'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Profile</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#" onClick={() => handleCheck()}>Save</Link>
                </div>
            </div>

            <div className="main-content">
                <div className="upload-btn-wrapper upload-btn-wrapper2">
                    <div className="profile-imageprt">
                        {status1w?.status === "loading" && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <img src={img ? img : selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : editProfileImg} alt="editProfileImg" />
                        <input type="file" name="myfile" style={{ opacity: '0' }} onChange={(e) => handleProfileChange(e)} />
                    </div>
                </div>
                <div className="profile-describe">
                    <form action="#" method="POST">
                        <div className="profile-info">
                            <label htmlFor="">Name</label>
                            <p><img src={user2Img} alt="user2Img" /><input className="user-name p-edit" type="text" id="r1" value={fullname} name="userName" onChange={(e) => { onChangeInput(e, 'userName') }} /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Email address</label>
                            <p><img src={emailImg} alt="emailImg" /> <input disabled className="user-name p-edit" type="text" id="r2" value={userEmail ? userEmail : "dummy@yopmail.com"} name="email" onChange={(e) => { onChangeInput(e, 'email') }} /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Phone number</label>
                            <p><img src={tellImg} alt="tellImg" /><input disabled className="user-name p-edit" type="text" id="r2" value={phoneNo} name="mobile" onChange={(e) => { onChangeInput(e, 'mobile') }} /><img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">SSN</label>
                            <p><img src={calender2Img} alt="calender2Img" /> <input className="user-name p-edit" type="text" id="r2" value={ssnNo} name="ssn" onChange={(e) => { onChangeInput(e, 'ssn') }} /><img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Date of Birth</label>
                            <p><img src={calender2Img} alt="calender2Img" />
                                <input className="birth p-edit" type="date" id="r3" value={dob} placeholder="mm / dd / yyyy" name="dob" onChange={(e) => { onChangeInput(e, 'dob') }} />
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default EditProfile;