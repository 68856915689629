import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, editImg, bxsCoinStackImg, profileImageImg, user2Img, emailImg, tellImg, verifyImg, calender2Img } from "../../../utilities/images";
import { selectLoginProfile } from "../auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, userProfileAsync } from "./profileSlice";
import EditProfile from "./editProfile";

const Profile = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profileData = useSelector(selectLoginProfile);
    const selectLoginUser = useSelector(selectLoginProfile);
    const selectProfile = useSelector(selectUserProfile);
    let loginId = selectLoginUser.payload.id;
    let profileInfo = profileData.user_profiles;
    let userProfile = selectProfile?.user_profiles;
    // const { status1 } = useSelector(state => state.profile)

    console.log(selectProfile, "PROFILE");
    let userName = '';

    if (selectProfile) {
        if (selectProfile?.payload?.user_profiles?.firstname) {
            userName = selectProfile?.payload?.user_profiles?.firstname;
        }
        // if(selectProfile?.payload?.user_profiles?.lastname){
        //     userName = userName + " " + selectProfile?.payload?.user_profiles?.lastname;
        // }
    }

    useEffect(() => {
        let params = {
            loginId: loginId,
            token: selectLoginUser.payload.token
        }
        dispatch(userProfileAsync(params))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Profile</h4>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => { history.push('/edit-profile') }}><img src={editImg} alt="editImg" /></button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="profile-imageprt">
                    <Link to="#">
                        <img src={selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : profileImageImg} alt="profileImageImg" />
                    </Link>
                </div>

                <div className="profile-describe">
                    <form action="#" method="POST">
                        <div className="profile-info">
                            <label htmlFor="">Name</label>
                            <p><img src={user2Img} alt="user2Img" /> {userName ? userName : ""}</p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Email address</label>
                            <p><img src={emailImg} alt="emailImg" /> {selectProfile?.payload ? selectProfile?.payload?.email : ""}</p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Phone number</label>
                            <p><img src={tellImg} alt="tellImg" /> {selectProfile?.payload ? selectProfile?.payload?.user_profiles?.full_phone_number : ""} <img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">SSN</label>
                            <p><img src={calender2Img} alt="calender2Img" /> {selectProfile?.payload ? selectProfile?.payload?.user_profiles?.ssn_number : ""} <img className="verified" src={verifyImg} alt="verifyImg" /></p>
                        </div>
                        <div className="profile-info">
                            <label htmlFor="">Date of Birth</label>
                            <p><img src={calender2Img} alt="calender2Img" /> {selectProfile?.payload ? selectProfile?.payload?.user_profiles?.dob : ""}</p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Profile;