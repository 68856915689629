import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle";
import {
    leftArrowImg, coinStackImg, bellImg, bagImg, Newhm1, Newhm2, Newhm3, Newhm4, Newhm5, Newhm6, Trslider1,
    Trslider2, Trslider3, Trslider4, Trslider5, Trslider7, Trslider6, offerCrsImg12, starCircleImg
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { getOfferAsync, selectOfferData, selectDashboardData, getDashboardAsync, getServicesDataAsync, selectServiceData, getHomeDataApiAsync, selectHomeData } from "./dashboardSlice"
import { useSelector, useDispatch } from "react-redux";
import MobileHeader from "../../components/shared/MobileHeader";
import { selectLoginAuth } from "../auth/authSlice";
import { getAllDeliveryTypesApiAsync } from "../delivery/deliverySlice";
import { userProfileAsync } from "../profile/profileSlice";

const Dashboard = (props) => {
    const history = useHistory();
    const offerData = useSelector(selectOfferData);
    const DashboardData = useSelector(selectDashboardData);
    const ServicesData = useSelector(selectServiceData);
    const HomeData = useSelector(selectHomeData);
    console.log(HomeData, 'HomeData');
    const { status } = useSelector(state => state.dashboard)
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch();

    const handleClick = (e) => {
        console.log(e, "click event")
        // OwlCarousel.trigger('to.owl.carousel', [this.index(), 1000]);
    }
    const foodSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '23px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(HomeData?.data?.payload?.food?.length)}`}</div>
    };
    const fashionSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '23px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(HomeData?.data?.payload?.fashion?.length)}`}</div>
    };
    const retailSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '23px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(HomeData?.data?.payload?.retail?.length)}`}</div>
    };
    const healthSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '23px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(HomeData?.data?.payload?.health?.length)}`}</div>
    };
    const serviceSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '23px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(HomeData?.data?.payload?.services?.length)}`}</div>
    };
    const offersSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        centerMode: true,
        centerPadding: '40px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };
    const recentlyAddedSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '23px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(HomeData?.data?.payload?.recent_products?.length)}`}</div>
    };
    const onlineSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '23px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(HomeData?.data?.payload?.classes?.length)}`}</div>
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(getDashboardAsync())
        dispatch(getOfferAsync())
        dispatch(getServicesDataAsync())
        dispatch(getHomeDataApiAsync())
        let params = {
            loginId: auth?.payload?.id,
            token: auth?.payload?.token
        }
        dispatch(userProfileAsync(params))
    }, [])

    useEffect(() => {
        dispatch(getAllDeliveryTypesApiAsync(auth?.payload?.token))
    }, [])

    return (
        <>
            <MobileHeader />
            {status.homeData === "loading" ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div className="main-content">
                    <div className="home-tab1prt">
                        <div className="search-barhm">
                            <form action="#" method="POST">
                                <input type="search" placeholder="Search here" onFocus={() => history.push('/browse')} />
                            </form>
                        </div>

                        {/* tab section */}
                        {
                            HomeData?.data?.payload?.services_all?.length > 0 ?
                                <div className="newhome-mainprt">
                                    <div className="row">
                                        {
                                            HomeData?.data?.payload?.services_all?.map((val, index) => {
                                                return (
                                                    <div className="col-6" key={index}>
                                                        <div className={(index == 0) ? "newhome-item newhome-itembox1" : (index == 3) ? "newhome-item newhome-itembox3" : "newhome-item"}>
                                                            <Link to={(val?.name == "Food Delivery") ? '/home-food' : (val?.name == "Services") ? "/home-service" : (val?.name == "Fashion") ? "/home-fashion" : (val?.name == "Retail Shop") ? "/home-retail" : (val?.name == "Health & Fitness") ? "/home-health-fitness" : (val?.name == "Classes") ? "/home-classes" : "#"}>
                                                                <img src={val?.image} alt="" />
                                                                <h4>{val?.name}</h4>
                                                                <p>{val?.description}</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : <></>
                        }


                        {/* Treding food slider */}
                        {HomeData?.data?.payload?.food?.length > 0 ?
                            <div className="banner-slider trslider-part" id="bannerSlider">
                                <div className="trslider-title">
                                    <h2>Trending Food</h2>
                                </div>
                                <SliderWrapper>
                                    <Slider {...foodSettings}>
                                        {
                                            HomeData?.data?.payload?.food?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell dotsClass">
                                                            <div className="trslider-main">
                                                                <img src={val?.user_profiles?.profile_photo} alt="" />
                                                                <div className="trslider-cnt">
                                                                    <h4>Top {HomeData?.data?.payload?.food?.length} Foods items in your city</h4>
                                                                    <Link to="#">SHOP NOW</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>
                            </div>
                            : ""}

                        {/* Treding Fashion */}
                        {HomeData?.data?.payload?.fashion?.length > 0 ?
                            <div className="banner-slider trslider-part" id="bannerSlider">
                                <div className="trslider-title">
                                    <h2>Trending Fashion</h2>
                                </div>
                                <SliderWrapper>
                                    <Slider {...fashionSettings}>
                                        {
                                            HomeData?.data?.payload?.fashion?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell dotsClass" key={index}>
                                                            <div className="trslider-main">
                                                                <img src={val?.user_profiles?.profile_photo} alt="" />
                                                                {/* val?.user_profiles?.profile_photo */}
                                                                <div className="trslider-cnt">
                                                                    <h4>Top {HomeData?.data?.payload?.fashion?.length} Fashion brand in your city</h4>
                                                                    <Link to="#">SHOP NOW</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>
                            </div>
                            : ""}
                        {/* Treding Retail */}

                        {HomeData?.data?.payload?.retail?.length > 0 ?
                            <div className="banner-slider trslider-part" id="bannerSlider">
                                <div className="trslider-title">
                                    <h2>Trending Retail Item</h2>
                                </div>
                                <SliderWrapper>
                                    <Slider {...retailSettings}>
                                        {
                                            HomeData?.data?.payload?.retail?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell dotsClass" key={index}>
                                                            <div className="trslider-main">
                                                                <img src={val?.user_profiles?.profile_photo} alt="" />
                                                                {/* val?.user_profiles?.profile_photo */}
                                                                <div className="trslider-cnt">
                                                                    <h4>Most people brought items</h4>
                                                                    <Link to="#">SHOP NOW</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>
                            </div>
                            : ""}

                        {/* Treding HealthCare */}
                        {HomeData?.data?.payload?.health?.length > 0 ?
                            <div className="banner-slider trslider-part" id="bannerSlider">
                                <div className="trslider-title">
                                    <h2>Trending Healthcare & Fitness</h2>
                                </div>
                                <SliderWrapper>
                                    <Slider {...healthSettings}>
                                        {
                                            HomeData?.data?.payload?.health?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell dotsClass" key={index}>
                                                            <div className="trslider-main">
                                                                <img src={val?.user_profiles?.profile_photo} alt="" />
                                                                {/* val?.user_profiles?.profile_photo */}
                                                                <div className="trslider-cnt">
                                                                    <h4>Top {HomeData?.data?.payload?.health?.length} Gym in your city</h4>
                                                                    <Link to="#">SHOP NOW</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>
                            </div>
                            : ""}
                        {/* Treding Services */}

                        {HomeData?.data?.payload?.services?.length > 0 ?
                            <div className="banner-slider trslider-part" id="bannerSlider">
                                <div className="trslider-title">
                                    <h2>Trending Services</h2>
                                </div>
                                <SliderWrapper>
                                    <Slider {...serviceSettings}>
                                        {
                                            HomeData?.data?.payload?.services?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell dotsClass" key={index}>
                                                            <div className="trslider-main">
                                                                <img src={val?.user_profiles?.profile_photo} alt="" />
                                                                {/* val?.user_profiles?.profile_photo */}
                                                                <div className="trslider-cnt">
                                                                    <h4>Top {HomeData?.data?.payload?.services?.length} Services</h4>
                                                                    <Link to="#">SHOP NOW</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                    </Slider>
                                </SliderWrapper>
                            </div>
                            : ""}

                        {/* Treding Online Course */}

                        {HomeData?.data?.payload?.classes?.length > 0 ?
                            <div className="banner-slider trslider-part" id="bannerSlider">
                                <div className="trslider-title">
                                    <h2>Trending Online Classes</h2>
                                </div>
                                <SliderWrapper>
                                    <Slider {...onlineSettings}>
                                        {
                                            HomeData?.data?.payload?.classes?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell dotsClass" key={index}>
                                                            <div className="trslider-main">
                                                                <img src={val?.user_profiles?.profile_photo} alt="" />
                                                                {/* val?.user_profiles?.profile_photo */}
                                                                <div className="trslider-cnt">
                                                                    <h4>Top online classes</h4>
                                                                    <Link to="#">SHOP NOW</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>
                            </div>
                            : ""}

                        {/* offer */}
                        {HomeData?.data?.payload?.offers?.length > 0 ?
                            <div className="offer-areahm p-0">
                                <div className="offer-title">
                                    <h2>Offers</h2>
                                </div>
                                <div className='offercontain' >
                                    {/* <OwlCarousel items={2.5}
                                        className="owl-theme"
                                        nav={false}
                                        dots={false}
                                        loop={true}
                                        autoplay={true}
                                        autoplayTimeout={2000}
                                        margin={10} >
                                        {HomeData?.data?.payload?.offers?.map((offerData, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="carousel-cell w-94">
                                                        <div className="offer-crslpart">
                                                            <div className="offer-crslitem">
                                                                <img src={offerData?.Image} alt="offerCrsImg1" />
                                                                <div className="offer-crslinner">
                                                                    <h2>{offerData.discount_percentage}% off</h2>
                                                                    <p>Upto ${offerData.max_discount} <span>AD</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </OwlCarousel> */}

                                    <SliderWrapper>
                                        <Slider {...offersSettings}>
                                        {HomeData?.data?.payload?.offers?.map((offerData, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="carousel-cell w-94">
                                                        <div className="offer-crslpart">
                                                            <div className="offer-crslitem">
                                                                <img src={offerData?.Image} alt="offerCrsImg1" />
                                                                <div className="offer-crslinner">
                                                                    <h2>{offerData.discount_percentage}% off</h2>
                                                                    <p>Upto ${offerData.max_discount} <span>AD</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                        </Slider>
                                    </SliderWrapper>
                                </div>
                            </div>
                            : ""}

                        {/* Recently Added */}
                        {HomeData?.data?.payload?.recent_products?.length > 0 ?
                            <div className="banner-slider trslider-part" id="bannerSlider">
                                <div className="trslider-title">
                                    <h2>Recently Added</h2>
                                </div>
                                <SliderWrapper>
                                    <Slider {...recentlyAddedSettings}>
                                        {
                                            HomeData?.data?.payload?.recent_products?.map((val, index) => {
                                                return (
                                                    <div className="carousel-cell" key={index}>
                                                        <div className="trslider-main">
                                                            <img src={val?.product_images[0]?.url} alt="" />
                                                            <div className="trslider-cnt">
                                                                <h6>{val?.name} <img src={starCircleImg} alt="" /> <span>{val?.rating ? val?.rating : "4.5"}</span></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>
                            </div> : ""}
                    </div>
                </div>
            }
        </>
    );
};

export default Dashboard