import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAddressDetail, getAllAddress, changeCurrentAddressApi } from "./addressApi";

const initialState = {
  addressDetail: [],
  allAddress: [],
  changeAddress: null,
  status: "idle",
};

export const addressDetailAsync = createAsyncThunk("address/detail", async (data) => {
  const response = await getAddressDetail(data);
  return response.data;
});
export const allAddressAsync = createAsyncThunk("all/address", async (data) => {
  const response = await getAllAddress(data);
  return response.data;
});
export const changeCurrentAddressApiAsync = createAsyncThunk("all/changeCurrentAddressApi", async (data) => {
  const response = await changeCurrentAddressApi(data.id,data.postData, data.tokenData);
  return response.data;
});

export const addressDetailSlice = createSlice({
  name: "address",
  initialState,

  extraReducers: {
    [addressDetailAsync.pending](state) {
      state.status = "loading";
    },
    [addressDetailAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.addressDetail = payload;
    },
    [addressDetailAsync.rejected](state, { error }) {
      state.status = "idle";
      state.addressDetail = [];
    },
    [allAddressAsync.pending](state) {
      state.status = "loading";
    },
    [allAddressAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.allAddress = payload;
    },
    [allAddressAsync.rejected](state, { error }) {
      state.status = "idle";
      state.allAddress = [];
    },
    [changeCurrentAddressApiAsync.pending](state) {
      state.status = "loading";
    },
    [changeCurrentAddressApiAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.changeAddress = payload;
    },
    [changeCurrentAddressApiAsync.rejected](state, { error }) {
      state.status = "idle";
      state.changeAddress = [];
    }
  },
});

export const selectAllAddress = (state) => state.address.allAddress;
export default addressDetailSlice.reducer;