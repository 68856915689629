import axios from "axios";
import { toast } from "react-toastify";

export function getBookingList(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/bookings/booking-list`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function cancelBookingByUser(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_ORDER_URL}api/v1/bookings/booking-cancel/${data}`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function updateBookingByUser(id,data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_ORDER_URL}api/v1/bookings/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}