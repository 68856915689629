import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSearchProduct } from './SearchApi';
const initialState = {
  searchProductList: null,
  status: {
    searchProductList: "idle"
  },
};
export const searchProductListAsync = createAsyncThunk("productSearch/searchProductList", async (data) => {
  const response = await getSearchProduct(data.postData, data.token);
  return response;
});

export const SearchSlice = createSlice({
  name: "productSearch",
  initialState,

  extraReducers: {
    [searchProductListAsync.pending](state) {
      state.status.searchProductList = "loading";
    },
    [searchProductListAsync.fulfilled](state, { payload }) {
      state.status.searchProductList = "idle";
      state.searchProductList = payload;
    },
    [searchProductListAsync.rejected](state, { error }) {
      state.status.searchProductList = "idle";
      state.searchProductList = [];
    }
  },
});
export const selectedsearchProductListData = (state) => state.productSearch.searchProductList;
export default SearchSlice.reducer;