import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { arrowBlueImg, BellIcon, BurgerKing, deliveryParcelImg, discountOutlineImg, dotCircleImg, LeftCircle2, Rest1, Rest2, Rest3, Rest4, searchCircleImg, starCircleImg } from '../../../utilities/images'
import CustomModal from "../../components/shared/CustomModal";
import { selectLoginAuth } from '../auth/authSlice';
import { getCategoryApiAsync, getMenuApiAsync, getMenuProductApiAsync, getProductListAsync, selectedMenuList, selectedProductData, selectProductInventoryData } from './dashboardSlice';
import Geocode from "react-geocode";
import OwlCarousel from 'react-owl-carousel';
import { getCartApiAsync, selectCartData } from '../cart/cartSlice';
import Slider from "react-slick";
import SliderWrapper from "../../containers/dashboard/offerStyle";

const HomeRestaurent = () => {
    const history = useHistory()
    const { id } = useParams()
    const { status } = useSelector(state => state.dashboard)
    const [key, setKey] = useState(Math.random());
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const location = useLocation()
    const dispatch = useDispatch()
    const cartData = useSelector(selectCartData)
    const auth = useSelector(selectLoginAuth)

    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };

    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            Geocode.setApiKey("AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    //   console.log(city, state, country);
                    setAddress(address)
                    setCity(city)
                    //   console.log(address,"address");
                },
                (error) => {
                    console.error(error);
                }
            )
        })
    }

    const productList = useSelector(selectedProductData)
    console.log(productList, 'productList')
    const productInventoryData = useSelector(selectProductInventoryData)
    console.log(productInventoryData, "product inventory data")
    const menuList = useSelector(selectedMenuList)
    console.log(menuList, "menu list")
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = async (data) => {
        let sendData = {
            menu_id: data,
            seller_id: id
        }
        await dispatch(getMenuProductApiAsync(sendData))
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleClick = (e) => {
        // console.log(e,"data")
        localStorage.setItem("productData", JSON.stringify(e))
        history.push(`/home-restaurent-menu/${e.id}/${id}`)
    }
    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        dispatch(getProductListAsync(data))
        dispatch(getCartApiAsync(auth?.payload?.token))
        dispatch(getMenuApiAsync(id))
        geocodeHandler()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="main-content">
                {
                    status.productList === "loading" ?
                        <div className="pageLoader d-flex justify-content-center align-items-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        :
                        <>
                            <div className="main">
                                <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.banner_image} className="img-fluid" />
                                <div className="topMain">
                                    <div className="left-circle"><div onClick={() => history.goBack()}><img src={LeftCircle2} alt="" /></div></div><div className="store-iconrit rest-menu"><Link to="#"><img src={searchCircleImg} alt="" /></Link><a className="dot-menu" href="/home-restaurent/b169ed4d-be27-44eb-9a08-74f997bc6a2a"><img src={dotCircleImg} alt="" /></a></div>
                                </div>
                            </div>
                            <div className="store-banner-part">
                                <div className="store-box">
                                    <div className="store-item">
                                        <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.profile_photo} alt="" />
                                    </div>
                                    <div className="store-cnt">
                                        <h4>{productList?.data?.payload?.sellerDetails[0]?.user_profiles?.username} <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                                        <p><img src={starCircleImg} alt="" /> {productList?.data?.payload?.sellerRatings} ({productList?.data?.payload?.totalCountOfRatings}+ ratings)</p>
                                        <h6>Food store</h6>
                                        <ul>
                                            <li>Outlate <span>{productList?.data?.payload?.outlateLocation}</span></li>
                                            <li>30 mins <Link to="#">{city} <i className="fas fa-chevron-down"></i></Link></li>
                                        </ul>
                                        <h3><Link to="#"><img src={deliveryParcelImg} alt="" /> ${productList?.data?.payload?.deliveryFee} Delivery fee</Link></h3>
                                    </div>
                                </div>
                                <div className="banner-slider trslider-part" id="offerSlider">
                                <SliderWrapper>
                                        <Slider {...offersSettings}>
                                        {
                                            productList?.data?.payload?.sellerOffersList?.length > 0 && productList?.data?.payload?.sellerOffersList?.map((val, index) => {
                                                return (
                                                    <>
                                                        <div className="store-btn" key={index}>
                                                            <Link to="#"><img src={discountOutlineImg} alt="" />
                                                                {val?.discount_percentage}% off upto {val?.max_discount}
                                                            </Link>
                                                            <div className="owl-dots dotstab d-block">
                                                            <span>{(index + 1)}/{productList?.data?.payload?.sellerOffersList?.length}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                        </Slider>
                                    </SliderWrapper>

                                </div>
                            </div>


                            <div className="restaurant-mainprt">
                                {productInventoryData?.length > 0 ?
                                    productInventoryData?.map((value, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="restaurant-item" onClick={() => handleClick(value)} style={{ cursor: 'pointer' }}>
                                                    <div className="restaurant-left">
                                                        <h4>{value?.name}</h4>
                                                        <p>{value?.description}</p>
                                                    </div>
                                                    <div className="restaurant-right">
                                                        <img src={value?.product_images[0]?.url} alt="" />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }) : "No Product Found"}
                                <div className="belllIcon" onClick={() => { setModalDetail({ show: true, flag: 'add', }); setKey(Math.random()); }}>
                                    <img src={BellIcon} alt="bellicon" />
                                </div>

                                {cartData?.data?.payload?.cart_products.length > 0 ?
                                    <div className="cartItemsbox">
                                        <div className="selected-itembox">
                                            <div className="selected-leftcnt">
                                                <p>Items: <strong>{cartData?.data?.payload?.cart_products.length}</strong></p>
                                                <p>$ <strong>{(cartData?.data?.payload?.amout?.total_amount).toFixed(2)}</strong></p>
                                            </div>
                                            <div className="selected-rightbtn">
                                                <Link to='/cart'>
                                                    <img src={arrowBlueImg} alt="arrowBlueImg" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div> : ""}

                            </div>
                        </>}


            </div>
            {/* Start Model */}
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                isHomeFoodModal={true}
                child={
                    <div className='menuPopup'>
                        <ul>
                            {
                                menuList?.data?.payload?.data?.map((val, index) => {
                                    return (
                                        <li key={index} className='restaurent_menu' onClick={() => handleOnCloseModal(val?.menue_id)}><Link to="#">{val?.menues?.name} <span>{val?.product_count}</span></Link></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal}
            />
        </>
    )
}

export default HomeRestaurent