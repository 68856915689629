import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { CalenderDark2, ClockDark2, MinusCircle, Person, PlusCircle, UserDark2 } from '../../../utilities/images'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../auth/authSlice'
import { createBookingApiAsync } from './dashboardSlice'
import { toast } from "react-toastify";
import { unwrapResult } from '@reduxjs/toolkit'

const ReserveTableModal = (props) => {
    const [count, setCount] = useState(1)
    const toastId = React.useRef(null)
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const handleIncrement = () => {
        setCount(Number(count) + 1)
    }
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const handleDecrement = () => {
        setCount(Number(count) - 1)
    }
    const date = new Date();

    let data = {
        "postData": {
            "service_id": "1",
            "slot_id": props?.slotID,
            "seller_id": props?.sellerId,
            "booking_date": props?.date ? props?.date : moment(date).format('MM-DD-YYYY'),
            "booking_info": "xyz",
            "table_no": "1",
            "no_of_members": `${count}`
        },
        "tokenData": auth?.payload?.token
    }

    console.log(props?.slotID, "submitted data")

    const handleReserve = async () => {
        if (props?.slotID) {
            dispatch(createBookingApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    console.log(obj, "obj data")
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.data?.msg)
                    }
                }
                )
                .catch((obj) => {
                    // if (!toast.isActive(toastId.current)) {
                    //     toastId.current = toast.error(obj?.message)
                    //     console.log(obj)
                    // }
                })
            props.close()
        }
        else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select time slot first")
            }
        }
    }

    // console.log(dateValue, "date value")
    return (
        <div className="modal-content">
            <div className="modal-header modal-headerbok">
                <button type="button" className="close" onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="coinplan-modal">
                    <div className="booktbl-modalcnt appoint-timemain">
                        <h2>Book A Table</h2>
                        <p>When do you want to book table?</p>
                        <ul>
                            <li onClick={() => { props.details('date') }}>
                                <Link to="#">
                                    <div className="appont-tmleft">
                                        <h4><img src={CalenderDark2} alt="" /> Reservation </h4>
                                    </div>
                                    <div className="appont-tmright">
                                        <span>{props?.date ? moment(props?.date).format('dddd, MMM Do YYYY') : <></>} <i className="fas fa-chevron-right"></i></span>
                                    </div>
                                </Link>
                            </li>
                            {
                                props?.date &&
                                <li onClick={() => { props.details('time') }}>
                                    <Link to="#">
                                        <div className="appont-tmleft">
                                            <h4><img src={ClockDark2} alt="" />Time</h4>
                                        </div>
                                        <div className="appont-tmright">
                                            <span>{props.slotStartTime ? `${props.slotStartTime} - ${props.slotEndTime}` : <></>} <i className="fas fa-chevron-right"></i></span>
                                        </div>
                                    </Link>
                                </li>
                            }
                            <li>
                                <div className="appont-tmleft">
                                    <h4><img src={UserDark2} alt="" />How many people?</h4>
                                </div>
                            </li>
                        </ul>
                        <div className="people-number">
                            <div className="quantity">
                                <form action="#" method="POST">
                                    <span className="input-number-decrement" onClick={(count > 1) ? () => handleDecrement() : void (0)}><i className="fas fa-minus"></i></span>
                                    <input className="input-number" type="text" value={count} onKeyDown={blockInvalidChar} onChange={(e) => setCount(e.target.value)} min="1" />
                                    <span className="input-number-increment" onClick={() => handleIncrement()}><i className="fas fa-plus"></i></span>
                                </form>
                            </div>
                        </div>

                        <div className="person-item">
                            <img src={Person} alt="" />
                        </div>
                        <div className="reserve-btn" onClick={handleReserve}>
                            <Link to="#">Reserve</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReserveTableModal