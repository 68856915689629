import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, logoImg, biArrowDownLeftImg, biArrowUpImg, biArrowDownImg } from "../../../utilities/images";

const Wallet = (props) => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.push('/more') }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> JBR Wallet</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">Buy</Link>
                </div>
            </div>

            <div className="main-content">
                <div className="wallet-mainprt">
                    <div className="wallet-balance-box">
                        <div className="wallet-balance-upper">
                            <img src={logoImg} alt="logoImg" />
                            <div className="wallet-balance-uprcnt">
                                <p>Avaiable Balance</p>
                                <h4>JBR 150.00</h4>
                            </div>
                        </div>
                        <ul>
                            <li><Link to="#" onClick={() => history.push('/wallet-2')}><img src={biArrowDownLeftImg} alt="biArrowDownLeftImg" /> Added</Link></li>
                            <li><Link to="#"><img src={biArrowUpImg} alt="biArrowUpImg" /> Purchases</Link></li>
                            <li><Link to="#"><img src={biArrowDownImg} alt="biArrowDownImg" /> Earn</Link></li>
                        </ul>
                    </div>

                    <div className="wallet-balance-links">
                        <h2>Buying capacity </h2>
                        <ul>
                            <li><Link to="#" onClick={() => { history.push('/wallet-brands') }}><strong>5</strong> Brands</Link></li>
                            <li><Link to="#" onClick={() => { history.push('/wallet-products') }}><strong>19</strong> Products </Link></li>
                            <li><Link to="#" onClick={() => { history.push('/wallet-shops') }}><strong>3</strong> Shops</Link></li>
                        </ul>
                    </div>

                    <div className="transection-history">
                        <h2>Transection history</h2>
                        <ul>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownLeftImg} alt="biArrowDownLeftImg" />
                                    <div className="transhist-cnt">
                                        <h4>JBR added</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 150.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowUpImg} alt="biArrowUpImg" />
                                    <div className="transhist-cnt">
                                        <h4>Purchased #12364449</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>-JBR 50.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownImg} alt="biArrowDownImg" />
                                    <div className="transhist-cnt">
                                        <h4>Earn_ref</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 15.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownImg} alt="biArrowDownImg" />
                                    <div className="transhist-cnt">
                                        <h4>Earn_ref</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 15.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownImg} alt="biArrowDownImg" />
                                    <div className="transhist-cnt">
                                        <h4>Earn_ref</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 15.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownImg} alt="biArrowDownImg" />
                                    <div className="transhist-cnt">
                                        <h4>Earn_ref</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 15.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownLeftImg} alt="biArrowDownLeftImg" />
                                    <div className="transhist-cnt">
                                        <h4>JBR added</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 150.00</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Wallet;
