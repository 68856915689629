import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getNotifications,
} from "./notificationApi";

const initialState = {
    notification: [],
    status: "idle"
};

export const getNotificationAsync = createAsyncThunk("notification/data", async (data) => {
    const response = await getNotifications();
    return response;
});

export const notificationSlice = createSlice({
    name: "notification",
    initialState,

    extraReducers: {

        [getNotificationAsync.pending](state) {
            state.status = "loading";
        },
        [getNotificationAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.notification = payload;
        },
        [getNotificationAsync.rejected](state, { error }) {
            state.status = "idle";
            state.notification = [];
        }
    },
});

export const selectNotificationData = (state) => state.notification.notification;

export default notificationSlice.reducer;