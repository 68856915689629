import axios from "axios";
import getOffersData from "../../apis/getOffers.json";
import getBrandData from "../../apis/brands.json";
import getProductData from "../../apis/products.json";
import DashboardData from "../../apis/dashboard.json"
import FoodData from "../../apis/foodData.json"
import RetailData from "../../apis/retailData.json"
import FashionStoreData from "../../apis/fashionStore.json"
import { isRejected } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const queryString = require('query-string');
// A mock function to mimic making an async request for data
export function getAllOffers() {
  return getOffersData
}
export function getAllBrands() {
  return getBrandData
}
export function getAllProducts() {
  return getProductData
}
export function getDashboardData() {
  return DashboardData
}
export function getFoodData() {
  return FoodData
}
export function getRetailData() {
  return RetailData
}
export function getFashionStoreData() {
  return FashionStoreData
}
export function getAllServices() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/services?page=1&limit=10`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getHomeData() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/dashboards/customer`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getCategories(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/categories/service/${data}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getTrendingNearBySeller(data) {

  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/trending_sellers/nearsellers`+ '?' + query, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getProductListBySupplier(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/supplies/${data}?lat=30.7355&long=76.7121/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function createBookingApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/bookings/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function getBookingSlot(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/slots/get-slots/${data.id}?date=${data.date}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getTrendingNearBySellerReservation(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/trending_sellers/nearsellers?lat=30.7355&long=76.7121&service_id=${data}&serviceType=3`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getMenuApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/menues_products/get-list-by-seller/${data}?page=1&limit=5`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getMenuProductApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/menues_products/get-list-by-products/${data.menu_id}/${data.seller_id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getProductByIdApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/products/${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getSellersByCategoryId(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/supplies/get-seller-by-category-id/${data}?lat=30.7355&long=76.7121`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getBannerByServiceType(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/banners?service_id=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getTrendingCategoryServiceType(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/categories/trending?service_id=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getTrendingProduct(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/products/trending?limit=5&service_id=1`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getCategoryWithProducts(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/categories/get-with-products?category_ids=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token        
        }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
        })
    )
  }
export function getCouponByServiceId(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/coupons/get-coupons-by-service-id/${data}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getAllProductsByCategoryId(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/products/category/${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token        
        }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
        })
    )
  }
  export function getProductsRetailById(data, token) {
    return new Promise((resolve, reject) =>
      axios
        .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/products/retail/${data}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token        
          }
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.error(error);
          })
      )
    }
    export function getCategoryBySellerId(data, token) {
      return new Promise((resolve, reject) =>
        axios
          .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/supplies/get-category-by-seller-id/${data}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token        
            }
            })
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              console.error(error);
            })
        )
      }
      export function getAllOrderByUser(data) {
        return new Promise((resolve, reject) =>
          axios
            .get(`${process.env.REACT_APP_ORDER_URL}api/v1/orders/user`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': data
              }
            })
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error)
              console.error(error);
            })
        )
      }
      export function getReorderByUser(data, token) {
        return new Promise((resolve, reject) =>
          axios
            .get(`${process.env.REACT_APP_ORDER_URL}api/v1/orders/${data}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token
              }
            })
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error)
              console.error(error);
            })
        )
      }

      export function cancelOrderByUser(data, token) {
        return new Promise((resolve, reject) =>
          axios
            .put(`${process.env.REACT_APP_ORDER_URL}api/v1/orders/cancel/${data}`,{}, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token
              }
            })
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error)
              console.error(error);
            })
        )
      }

      export function ratingFeedbackByUser(data, token) {
      
        return new Promise((resolve, reject) =>
          axios
            .put(`${process.env.REACT_APP_ORDER_URL}api/v1/orders/feedback`,data, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token
              }
            })
            .then(function (response) {
              resolve(response);
            })
            .catch(function (error) {
              reject(error)
              console.error(error);
            })
        )
      }
      export function getExtraProductsByCategoryId(data, token) {
        let query = queryString.stringify(data);
        console.log(query,'data');

        return new Promise((resolve, reject) =>
          axios
            .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/products/products-by-category`+'?'+query, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token        
              }
              })
              .then(function (response) {
                resolve(response);
              })
              .catch(function (error) {
                console.error(error);
              })
          )
        }