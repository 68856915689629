import axios from "axios";

export function getSupportApi(token) {
    return new Promise((resolve, reject) =>
      axios
        .get(`${process.env.REACT_APP_SUPPORT}api/v1/supports/user`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
        })
    )
  }
  
  export function getSubjectApi() {
    return new Promise((resolve, reject) =>
      axios
        .get(`${process.env.REACT_APP_SUPPORT}api/v1/subjects?page=1&limit=10`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
        })
    )
  }
  
  export function supportDocumentUrlApi(data) {
    var formData = new FormData();
    formData.append('document', data.document);
    return new Promise((resolve, reject) =>
      axios
        .post(`${process.env.REACT_APP_SUPPORT}api/v1/supports/document`, formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.error(error);
        })
    )
  }
  
  export function createSupportRequestApi(data, token) {
    return new Promise((resolve, reject) =>
      axios
        .post(`${process.env.REACT_APP_SUPPORT}api/v1/supports`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        })
        .then(function (response) {
          localStorage.removeItem("invaliddata")
          resolve(response);
        })
        .catch(function (error) {
          localStorage.setItem("invaliddata", error?.response?.data?.msg)
          console.error(error);
        })
    )
  }