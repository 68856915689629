import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../../public/images/logo.svg";
import LockOneImg from "../../../public/images/lock-1.png";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { registerOtpSendAsync, selectOtpId } from '../authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';

const Step1 = (props) => {
    const history = useHistory();
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneCode, SetPhoneCode] = useState("");
    const toastId = React.useRef(null)
    const { status1 } = useSelector(state => state.auth)

    let otpId = useSelector(selectOtpId)
    const dispatch = useDispatch()
    let value = {
        "phone_code": `+${phoneCode}`,
        "phone_no": phoneNo
    }

    const onChangePhoneNumber = (value, data, event, formattedValue) => {
        let phoneCode = data.dialCode;
        let phoneNumber = value.slice(data.dialCode.length);
        setPhoneNo(phoneNumber);
        SetPhoneCode(phoneCode);
    }
    const sendOtp = async () => {
        if (!phoneNo) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter phone number");
            }
            return;
        }
        else if (phoneNo && phoneNo.length <= 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Phone number length should be of 5 to 15 digits");
            }

            return;
        }
        else if (phoneNo && !phoneCode) {
            toastId.current = toast.error("Please select your phone code");
            return;
        }
        await dispatch(registerOtpSendAsync(value))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj,"obj data")
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                if(obj?.payload?.is_phone_exits){
                    history.push('/login')
                }
                else{
                    history.push('/registartion/otp-verification');
                }
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.msg)
                // }
            })
        localStorage.setItem("phonedetails", JSON.stringify(value))
    }
    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])
    return (
        <>
            <div className="logo-part">
                <Link to="#">
                    <img src={logo} alt="" />
                </Link>
            </div>

            <div className="lock-part">
                <img src={LockOneImg} alt="" />
            </div>
            <div className="phone-numbpart">
                <h2>Continue with your phone number</h2>
                <p>Please enter your number to phone nubmer</p>
                <div className="country-plugin">
                    <form action="#" name="POST">
                        <div id="result">
                            <PhoneInput
                                country="us"
                                // value={phoneNo}
                                enableSearch={true}
                                placeholder="Phone no."
                                onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                            />
                            <span id="valid-msg" className="hide">✓ Valid</span>
                            <span id="error-msg" className="hide"></span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="bottom-part">
                <button className="btn btn-primary continue" disabled={status1?.otpStatus === "loading"} type="button" onClick={() => { sendOtp() }}>
                    {status1?.otpStatus === "loading" && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Continue</span>
                </button>
            </div>
        </>
    )
}

export default Step1;