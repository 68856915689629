import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFaq } from "./faqApi";


const initialState = {
    faq: null,
    status: "idle",
};

export const faqAsync = createAsyncThunk("faq/profile", async (data) => {
    const response = await getUserFaq(data);
    return response.data;
});

export const faqSlice = createSlice({
    name: "faq",
    initialState,

    extraReducers: {

        [faqAsync.pending](state) {
            state.status = "loading";
        },
        [faqAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.faq = payload;
        },
        [faqAsync.rejected](state, { error }) {
            state.status = "idle";
            state.faq = [];
        }
    },
});

export const selectUserFaq = (state) => state.faq;
export default faqSlice.reducer;