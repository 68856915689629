import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { AddIcon, bagImg, bellImg, Fas9, Fasprd1, Fasprd2, Fasprd3, leftArrowImg, ProductDetails, starCircleImg } from '../../../utilities/images'
import OwlCarousel from 'react-owl-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getExtraProductsByCategoryIdAsync, getProductByIdApiAsync, selecteProductById, selectExtraProductsByCategoryId } from './dashboardSlice';
import { selectLoginAuth } from '../auth/authSlice';
import SliderWrapperFashion from "./fashionStyle";
import Slider from 'react-slick';

const HomeFashionStoreProduct = () => {
    const history = useHistory()
    const productData = useSelector(selecteProductById)
    const { id } = useParams();
    const [size, setSize] = useState('');
    const { status } = useSelector(state => state.dashboard);
    const [sizeId, setSizeId] = useState('');
    const [color, setColor] = useState('');
    const [colorId, setColorId] = useState('');
    console.log(productData, 'productData')
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()
    const extraProducts = useSelector(selectExtraProductsByCategoryId)
    console.log(extraProducts, 'extraProducts');
    let megaOffer = [1, 2, 3, 4]
    let mostSell = [1, 2]

    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };


    const sizeHandle = (size, i) => {
        setSize(size)
        setSizeId(i)
    }
    const colorHandle = (color, i) => {
        setColor(color)
        setColorId(i)
    }
    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    let extraData = {
        "postData": productData?.data?.payload?.product_details?.catagory_id,
        "tokenData": auth?.payload?.token
    }
    useEffect(async () => {
        await dispatch(getProductByIdApiAsync(data))
        await dispatch(getExtraProductsByCategoryIdAsync(extraData))


    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {status.productData === "loading" ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <>
                    <div className="order-heading">
                        <h4><Link to='#' onClick={() => history.goBack()}><img src={leftArrowImg} alt="" /></Link> </h4>
                        <div className="header-right-item">
                            <button type="button" onClick={() => history.push('/notifications')}><img src={bellImg} alt="" /></button>
                            <button type="button"><img src={bagImg} alt="" /></button>
                        </div>
                    </div>
                    <div className="main-content cart-mainpd fasmain-content">
                        <div className="fasproduct-detailsmain">
                            <div className="fasproduct-dtslider">
                                <div className="main-content1" id="fashionSlider">
                                    <SliderWrapperFashion>
                                        <Slider {...offersSettings}>
                                            {
                                                productData && productData?.data?.payload?.product_details?.product_images?.map((val, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <img src={val?.url} alt="" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </SliderWrapperFashion>
                                </div>
                                <p><img src={starCircleImg} alt="" /> <strong>{productData?.data?.payload?.average_rating}</strong> ({productData?.data?.payload?.total_review_count}+ ratings)</p>
                            </div>
                            <div className="fasproduct-dtprt">
                                <div className="fasprd-dtupper">
                                    <h2>{productData?.data?.payload?.product_details?.name}</h2>
                                    <p>{productData?.data?.payload?.product_details?.product_type?.name}</p>
                                    <h4>Price: ${productData?.data?.payload?.product_details?.price}</h4>
                                </div>

                                <div className="fasprd-dtcolor">
                                    <p>Select color: <strong>{color}</strong></p>
                                    {productData?.data?.payload?.product_details?.product_attribute?.map((val, ind) => {
                                        return (
                                            <>
                                                <div className="fashion-color" key={ind}> {(val?.attributes?.name === "Color") ? val?.attributes?.attribute_values?.map((v, i) => {
                                                    return (
                                                        <span style={{ backgroundColor: `${v?.name}` }} className={(colorId === v?.id) ? "active" : ""} onClick={() => colorHandle(v?.description, v?.id)} key={i}></span>
                                                    )
                                                }) : ""} </div>

                                            </>
                                        )
                                    })}
                                    {/* <ul>
                                <li className="active"><img src={Fasprd1} alt="" /></li>
                                <li><img src={Fasprd2} alt="" /></li>
                                <li><img src={Fasprd3} alt="" /></li>
                            </ul> */}
                                </div>

                                <div className="fasprd-size">
                                    <p>Select Size: <strong>{size}</strong> </p>

                                    <ul>
                                        {productData?.data?.payload?.product_details?.product_attribute?.map((val, ind) => {
                                            return (
                                                <>

                                                    <div key={ind}> {(val?.attributes?.name === "Size") ? val?.attributes?.attribute_values?.map((v, i) => {
                                                        return (
                                                            <li className={(sizeId === v?.id) ? "active" : ""} onClick={() => sizeHandle(v?.name, v?.id)} key={i}><strong>{v?.name}</strong></li>
                                                        )
                                                    }) : ""} </div>

                                                </>
                                            )
                                        })}
                                        {/* {productData?.data?.payload?.product_details?.product_attribute[1]?.attributes?.attribute_values?.map((val, i) => {
                                    return (<li className={(sizeId === val?.id) ? "active" : ""} onClick={() => sizeHandle(val?.name, val?.id)} key={i}>{val?.name}</li>)
                                })} */}
                                    </ul>
                                </div>

                                <div className="fasprd-cnt">
                                    <h4>Shipping charge: <strong>$$$</strong></h4>
                                    <p>Estimated arrival within 7 business days with JOBR shipping.</p>
                                </div>

                                <div className="fasprd-btn">
                                    <Link to="#"><i className="far fa-heart"></i> Add to Favorite</Link>
                                    <Link to="#" className="active"><i className="fas fa-shopping-bag"></i> Add to Bag</Link>
                                </div>
                            </div>
                            <div className="fasprd-dtcontent">
                                <h2>Product Details</h2>
                                <p>{productData?.data?.payload?.product_details?.description}</p>
                                <ul>
                                    {/* {productData?.data?.payload?.product_details?.product_attribute?.map((val, ind) => {
                                return (
                                 
                                    <div key={ind}> {(val?.attributes?.name === "Sleeve Length") ? val?.attributes?.attribute_values?.map((v, i) => {
                                        return (                                         
                                            <li>Sleeve Length: <strong>{v?.name}</strong></li>
                                        )
                                    }) : ""} </div>
                                )
                            })} */}
                                    {productData?.data?.payload?.product_details?.product_attribute?.map((val, ind) => {
                                        return (<>
                                            <div key={ind}> {(val?.attributes?.name === "Sleeve Length") ? <li>Sleeve Length: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                            <div key={ind}> {(val?.attributes?.name === "Collar") ? <li>Collar: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                            <div key={ind}> {(val?.attributes?.name === "Occasion") ? <li>Occasion: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                            <div key={ind}> {(val?.attributes?.name === 'Print or Pattern Type') ? <li>Print or Pattern Type: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                            <div key={ind}> {(val?.attributes?.name === 'Lining Fabric') ? <li>Lining Fabric: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                            <div key={ind}> {(val?.attributes?.name === 'Type') ? <li>Lining Fabric: <strong>{val?.attributes?.attribute_values[0].name}</strong></li> : <></>}</div>
                                        </>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="fasprd-likeprt">
                                <h2>You may also like</h2>
                                {
                                    (mostSell?.length > 0) ?
                                        <div className="fashion-mainprt">
                                            <div className="row">
                                                {mostSell?.map((val, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="col-6">
                                                                <div className="fashion-item">
                                                                    <div className="fashion-slider"  id="bluedotSlider">
                                                                        <SliderWrapperFashion>
                                                                            <Slider {...offersSettings}>
                                                                            {
                                                                                mostSell && mostSell?.map((val, index) => {
                                                                                    return (
                                                                                        <div className="carousel-cell" key={index}>
                                                                                            <img src={Fas9} alt="" />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </Slider>
                                                                        </SliderWrapperFashion>
                                                                    </div>
                                                                    <div className="fashion-cnt">
                                                                        <div className="fashion-color">
                                                                            <span className="red active"></span>
                                                                            <span className="info"></span>
                                                                            <span className="black"></span>
                                                                            <span className="dark"></span>
                                                                            <span className="white"></span>
                                                                        </div>
                                                                        <p><strong>Made well</strong> colored cozy short cardigan</p>
                                                                        <ul>
                                                                            <li>X</li>
                                                                            <li className="active">L</li>
                                                                            <li>XL</li>
                                                                            <li>XXL</li>
                                                                        </ul>
                                                                        <h4>$5.65</h4>
                                                                        <Link to="#">
                                                                            <img src={AddIcon} alt="" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div></>}
        </>
    )
}

export default HomeFashionStoreProduct