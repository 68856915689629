import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getStore,
} from "./productAddToCartApi";

const initialState = {
    store: null,
    status: "idle",
};

export const getStoreAsync = createAsyncThunk("product/store", async (data) => {
    const response = await getStore();
    return response;
});

export const productSlice = createSlice({
    name: "product",
    initialState,

    extraReducers: {

        [getStoreAsync.pending](state) {
            state.status = "loading";
        },
        [getStoreAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.store = payload;
        },
        [getStoreAsync.rejected](state, { error }) {
            state.status = "idle";
            state.store = [];
        }
    },
});

export const selectStoreData = (state) => state.product.store;

export default productSlice.reducer;