import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, biArrowDownLeftImg, bxsCoinStackImg } from "../../../utilities/images";

const WalletTwo = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="main-wrapper">
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => history.push('/wallet')}><img src={leftArrowImg} alt="" /></Link> Transection history</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="wallet-mainprt">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm noshadow">
                            <ul className="tab-links text-left pb-4">
                                <li className="active"><Link to="#tab1">Added</Link></li>
                                <li><Link to="#tab2">Purchases</Link></li>
                                <li><Link to="#tab2">Earn</Link></li>
                            </ul>
                        </div>

                        {/* <div className="tab-content">
                     <div id="tab1" className="tab active">
                        <div className="browse-tab1">

                        </div>
                    </div>

                    <div id="tab2" className="tab">
                        <div className="browse-tab2">

                        </div>
                    </div>
                    <div id="tab2" className="tab">
                        <div className="browse-tab2">

                        </div>
                    </div> 
                </div> */}
                    </div>

                    <div className="transection-history">
                        <ul>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownLeftImg} alt="" />
                                    <div className="transhist-cnt">
                                        <h4>JBR added</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 150.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownLeftImg} alt="" />
                                    <div className="transhist-cnt">
                                        <h4>JBR added</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 150.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownLeftImg} alt="" />
                                    <div className="transhist-cnt">
                                        <h4>JBR added</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 150.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownLeftImg} alt="" />
                                    <div className="transhist-cnt">
                                        <h4>JBR added</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 150.00</p>
                                </div>
                            </li>
                            <li>
                                <div className="transhist-left">
                                    <img src={biArrowDownLeftImg} alt="" />
                                    <div className="transhist-cnt">
                                        <h4>JBR added</h4>
                                        <p>21 Jun, 2022</p>
                                    </div>
                                </div>
                                <div className="transhist-right">
                                    <p>JBR 150.00</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletTwo