import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getOrders,
} from "./ordersApi";

const initialState = {
    orders: null,
    status: 'idle',
};

export const getOrderAsync = createAsyncThunk("orders/order", async (data) => {
    const response = await getOrders();
    return response;
});

export const ordersSlice = createSlice({
    name: "order",
    initialState,

    extraReducers: {

        [getOrderAsync.pending](state) {
            state.status = "loading";
        },
        [getOrderAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.orders = payload;
        },
        [getOrderAsync.rejected](state, { error }) {
            state.status = "idle";
            state.orders = [];
        }
    },
});

export const selectOrderData = (state) => state.order.orders;

export default ordersSlice.reducer;