import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { coinStackImg, leftArrowImg, PeopleLove1, PeopleLove2, PeopleLove3, PeopleLove4, PeopleLove5 } from '../../../utilities/images'
import { selectLoginAuth } from '../auth/authSlice'
import { getProductByIdApiAsync, getTrendingProductAsync, selecteProductById, selectTrendingProductData } from './dashboardSlice'
import { toast } from "react-toastify";
import { createCartApiAsync, getCartApiAsync, removeOneCartProductApiAsync, selectCartData } from '../cart/cartSlice'
import { unwrapResult } from '@reduxjs/toolkit'

const HomeRestaurentMenuOption = () => {
    const history = useHistory()
    const toastId = React.useRef(null)
    const { status } = useSelector(state => state.dashboard)
    const [count, setCount] = useState(1)
    const { id, sellerId } = useParams()
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const [show, setShow] = useState(true)
    const productData = useSelector(selecteProductById)
    const cartData = useSelector(selectCartData)
    // console.log(productData,'productData')
    const trendingProductData = useSelector(selectTrendingProductData)
    const [checkedState, setCheckedState] = useState(
        new Array(productData?.data?.payload?.product_details?.product_attribute[0]?.attributes?.attribute_values?.length).fill(false)
    );
    const [checkedTrendingState, setCheckedTrendingState] = useState(
        new Array(trendingProductData?.data?.payload?.length).fill(false)
    );
    const [checkedValue, setCheckedValue] = useState([])
    const [checkTrendingValue, setCheckedTrendingValue] = useState([])
    const [cartId, setCartId] = useState("")

    const handleChecklistChange = (position, id) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        let data = updatedCheckedState.map((val, index) => {
            return (
                val == true ? productData?.data?.payload?.product_details?.product_attribute[0]?.attributes?.attribute_values[index]?.id : false
            )
        })
        data.filter((arr) => arr !== false);
        const result = data?.filter(val => val !== false);
        setCheckedValue(result);
        // console.log(checkedValue,'checkedValue')
    }

    const handleTrendingChecklistChange = (position, id) => {
        const updatedTrendingCheckedState = checkedTrendingState?.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedTrendingState(updatedTrendingCheckedState);
        console.log(updatedTrendingCheckedState[position], "checked position")
        if (updatedTrendingCheckedState[position]) {
            console.log(trendingProductData?.data?.payload[position]?.id, "add id")
            let objProduct = {
                "postData": {
                    "seller_id": sellerId,
                    "product_id": trendingProductData?.data?.payload[position]?.id,
                    "qty": 1,
                    "service_id": 1
                },
                "tokenData": auth?.payload?.token
            }
            dispatch(createCartApiAsync(objProduct))
                .then(unwrapResult)
                .then(async (obj) => {
                    setCartId(obj?.data?.payload?.id)
                }
                )
                .catch((obj) => {
                })
        }
        else {
            console.log(trendingProductData?.data?.payload[position]?.id, "remove id")
            let data = {
                "postData": {
                    cart_id: cartId,
                    product_id: trendingProductData?.data?.payload[position]?.id
                },
                "tokenData": auth?.payload?.token
            }
            dispatch(removeOneCartProductApiAsync(data))
                .then(unwrapResult)
                .then(async (obj) => {

                }
                )
                .catch((obj) => {
                })
        }
    }
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    let objProduct = {
        "postData": {
            "seller_id": sellerId,
            "product_id": productData?.data?.payload?.product_details?.id,
            "qty": count,
            "attribute_value_ids": checkedValue,
            "service_id": 1
        },
        "tokenData": auth?.payload?.token
    };

    if(productData?.data?.payload?.product_details?.product_attribute[0]){
        objProduct = {
            "postData": {
                "seller_id": sellerId,
                "product_id": productData?.data?.payload?.product_details?.id,
                "qty": count,
                "attribute_value_ids": checkedValue,
                "service_id": 1
            },
            "tokenData": auth?.payload?.token
        }
    }else{
        objProduct = {
            "postData": {
                "seller_id": sellerId,
                "product_id": productData?.data?.payload?.product_details?.id,
                "qty": count,
                "service_id": 1
            },
            "tokenData": auth?.payload?.token
        }
    }

    const handleCart = async () => {
        if (cartData?.data?.payload && (cartData?.data?.payload?.seller_id !== sellerId) && (cartData?.data?.payload?.service_id !== 1)) {
                console.log("exist")
        } else {
            if (productData?.data?.payload?.product_details?.product_attribute[0] && objProduct.postData.attribute_value_ids.length < 1) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error("Please select the one spicy level options");
                }
            } else {
                dispatch(createCartApiAsync(objProduct))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        history.push(`/home-restaurent/${sellerId}`)
                    }
                    )
                    .catch((obj) => {
                    })
            }
        }
    }

    const handleIncrement = () => {
        setCount(Number(count) + 1)
    }
    const handleDecrement = () => {
        setCount(Number(count) - 1)
    }
    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    let trendingdata = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        dispatch(getProductByIdApiAsync(data))
        dispatch(getTrendingProductAsync(trendingdata))
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {

            }
            )
            .catch((obj) => {
            })
    }, [])
    return (
        <>
            {
                status.productData === "loading" || status.trendingProduct === "loading" ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        <div className="order-heading hmrestmenu-header">
                            <h4><Link to='#'><img src={leftArrowImg} alt="" onClick={() => history.goBack()} /></Link><span> {productData?.data?.payload?.product_details?.name} <br /> <strong>USD ${productData?.data?.payload?.product_details?.price}</strong></span></h4>

                            <div className="header-right-item">
                                <img src={productData?.data?.payload?.product_details?.product_images[0]?.url} alt="" />
                            </div>
                        </div>
                        <div className="main-content cart-mainpd restmenu-maincont">
                            <div className="body-massage-mainprt hmrest-menumainprt">

    { productData?.data?.payload?.product_details?.product_attribute[0] &&                           <div className="people-loveprt viewopt-box spice-box">
                                    <div className="body-msgtitle">
                                        <h2>{productData?.data?.payload?.product_details?.product_attribute[0]?.attributes?.name} <span>1 Required</span> <i className={show ? "fas fa-chevron-up" : "fas fa-chevron-down"} onClick={() => setShow(prev => !prev)}></i></h2>
                                    </div>
                                    {
                                        show ?
                                            <div className="bodymsg-part">
                                                {
                                                    productData?.data?.payload?.product_details?.product_attribute[0]?.attributes?.attribute_values?.map((val, index) => {
                                                        return (
                                                            <div className="bodymsg-item" key={index}>
                                                                <div className="bodymsg-left">
                                                                    <div className="form-group">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`custom-checkbox-${index}`}
                                                                            name={val?.id}
                                                                            value={val?.id}
                                                                            checked={checkedState[index]}
                                                                            onChange={() => handleChecklistChange(index, val?.id)}
                                                                        />
                                                                        <label htmlFor={`custom-checkbox-${index}`}></label>
                                                                    </div>
                                                                    <div className="bdymsg-cnt">
                                                                        <h6>{val?.name}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="spice-right">
                                                                    <span>Free</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : <></>
                                    }

                                </div>}

                                {/* <div className="people-loveprt viewopt-box">
                        <div className="body-msgtitle">
                            <h2>Extra topping For burger <span className="optional">Optional</span></h2>
                        </div>

                        <div className="bodymsg-part">
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check6" />
                                        <label htmlFor="check6"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>View options (9)</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                                {/* <div className="people-loveprt viewopt-box">
                        <div className="body-msgtitle">
                            <h2>Add ons htmlFor burger <span className="optional">Optional</span></h2>
                        </div>

                        <div className="bodymsg-part">
                            <div className="bodymsg-item">
                                <div className="bodymsg-left">
                                    <div className="form-group">
                                        <input type="checkbox" id="check7" />
                                        <label htmlFor="check7"></label>
                                    </div>
                                    <div className="bdymsg-cnt">
                                        <h4>View options (9)</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                                {trendingProductData?.data?.payload?.length > 0 ?
                                    <div className="people-loveprt">
                                        <div className="body-msgtitle">
                                            <h2>People love to eat <span className="optional">Optional</span></h2>
                                        </div>

                                        <div className="bodymsg-part">
                                            {trendingProductData?.data?.payload?.map((val, index) => {
                                                return (
                                                    <div className="bodymsg-item" key={index}>
                                                        <div className="bodymsg-left">
                                                            <div className="form-group2">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`custom-checkbox1-${index}`}
                                                                    name={val?.id}
                                                                    value={val?.id}
                                                                    checked={checkedTrendingState[index]}
                                                                    onChange={() => handleTrendingChecklistChange(index, val?.id)}
                                                                />
                                                                <label htmlFor={`custom-checkbox1-${index}`}></label>
                                                            </div>
                                                            <div className="bdymsg-cnt">
                                                                <h4>{val?.name}</h4>
                                                                <p>USD ${val?.price}</p>
                                                            </div>
                                                        </div>
                                                        <div className="bodymsg-right">
                                                            <img src={val?.image} alt="" />
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    </div>
                                    : <></>
                                }
                            </div>

                            <div className="product-mdlbtm mt-0">
                                <div className="prdmdl-btmleft">
                                    <div className="quantity">
                                        <form action="#" method="POST">
                                            <span className="input-number-decrement" onClick={(count > 1) ? () => handleDecrement() : void (0)}><i className="fas fa-minus"></i></span>
                                            <input className="input-number" type="text" value={count} onKeyDown={blockInvalidChar} onChange={(e) => setCount(e.target.value)} min="1" />
                                            <span className="input-number-increment" onClick={() => handleIncrement()}><i className="fas fa-plus"></i></span>
                                        </form>
                                    </div>
                                </div>
                                <div className="prdmdl-btmright" onClick={handleCart}>
                                    <Link to="#">Add to cart</Link>
                                </div>
                            </div>

                        </div>
                    </>}
        </>
    )
}

export default HomeRestaurentMenuOption;