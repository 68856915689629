import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { deliveryParcelImg, Cicle, discountOutlineImg, dotCircleImg, LeftCircle2, searchCircleImg, SportShoes, starCircleImg, AddIcon, More1 } from '../../../utilities/images'
import OwlCarousel from 'react-owl-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getProductListAsync, selectCategoryData, selectedProductData, getCategoryApiAsync } from './dashboardSlice';
import { selectLoginAuth } from '../auth/authSlice';
import Geocode from "react-geocode";
import { unwrapResult } from '@reduxjs/toolkit';
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from '../../components/shared/CustomModal';
import SliderWrapper from './offerStyle';
import SliderWrapperNew from "./SliderStyle";
import SliderWrapperFashion from "./fashionStyle";
import Slider from 'react-slick';


const HomeFashionStore = () => {
    const history = useHistory();
    const { id } = useParams();
    const [loading, isLoading] = useState(false)
    const [size, setSize] = useState('');
    const { status } = useSelector(state => state.dashboard);
    const [sizeId, setSizeId] = useState('');
    const [color, setColor] = useState('');
    const [colorId, setColorId] = useState('');
    const [address, setAddress] = useState("");
    // const [categoryId, setCategoryId] = useState("");
    const [productsData, setProductsData] = useState([]);
    console.log(productsData, 'productsData');
    const [city, setCity] = useState("");
    const productList = useSelector(selectedProductData);
    const auth = useSelector(selectLoginAuth);
    console.log(productList, 'productList');
    const categoryData = useSelector(selectCategoryData)
    // console.log(categoryData,'categoryData');
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(PaginationSetting)
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            Geocode.setApiKey("AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    //   console.log(city, state, country);
                    setAddress(address)
                    setCity(city)
                    //   console.log(address,"address");
                },
                (error) => {
                    console.error(error);
                }
            )
        })
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleOnMenuModal = (id) => {
        let newProductsData = productList?.data?.payload?.products?.filter(item => item?.catagory_id === id)
        setProductsData(newProductsData)
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const sizeHandle = (size, i) => {
        const colorHandle = (color, i) => {
            setColor(color)
            setColorId(i)
        }
        setSize(size)
        setSizeId(i)
    }
    const colorHandle = (color, i) => {
        setColor(color)
        setColorId(i)
    }

    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    const bannerSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(productList?.data?.payload?.sellerDetails?.length)}`}</div>
    };

    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };

    useEffect(() => {
        dispatch(getProductListAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                // console.log(obj, 'object');
                isLoading(true)
                setProductsData(obj?.data?.payload?.products)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
            })
        dispatch(getCategoryApiAsync(4))
        geocodeHandler()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="main-content pb-130">
            {status.productList === "loading" ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <>
                    <div className="main">
                        <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.banner_image} className="img-fluid" />
                        <div className="topMain">
                            <div className="left-circle"><div onClick={() => history.goBack()}><img src={LeftCircle2} alt="" /></div></div><div className="store-iconrit rest-menu"><Link to="#"><img src={searchCircleImg} alt="" /></Link><a className="dot-menu" href="/home-restaurent/b169ed4d-be27-44eb-9a08-74f997bc6a2a"><img src={dotCircleImg} alt="" /></a></div>
                        </div>
                    </div>
                    <div className="store-banner-part">
                        <div className="store-box">
                            <div className="store-item">
                                <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.profile_photo} alt="" />
                            </div>

                            <div className="store-cnt">
                                <h4>{productList?.data?.payload?.sellerDetails[0]?.user_profiles?.username}<Link to="#"> <i className="far fa-heart"></i></Link></h4>
                                <p><img src={starCircleImg} alt="" /> {productList?.data?.payload?.sellerRatings}({productList?.data?.payload?.totalCountOfRatings}+ ratings)</p>
                                <h6>Fashion store</h6>
                                <ul>
                                    <li>Outlate <span>{productList?.data?.payload?.outlateLocation}</span></li>
                                    <li>30 mins <Link to="#">{city} <i className="fas fa-chevron-down"></i></Link></li>
                                </ul>
                                <h3><Link to="#"><img src={deliveryParcelImg} alt="" /> ${productList?.data?.payload?.deliveryFee} Delivery fee</Link></h3>
                            </div>
                        </div>
                        <div id="offerSlider">
                            <SliderWrapper>
                                <Slider {...offersSettings}>
                                    {
                                        productList?.data?.payload?.sellerOffersList?.length > 0 && productList?.data?.payload?.sellerOffersList?.map((val, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="store-btn">
                                                        <Link to="#"><img src={discountOutlineImg} alt="" />
                                                            {val?.discount_percentage}% off upto {val?.max_discount}
                                                        </Link>
                                                        <div className="owl-dots dotstab d-block">
                                                            <span>{(index + 1)}/{productList?.data?.payload?.sellerOffersList?.length}</span>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Slider>
                            </SliderWrapper>
                        </div>
                    </div>

                    <div id="bannerSlider" className="banner-slider trslider-part">
                        <SliderWrapperNew>
                            <Slider {...bannerSettings}>
                                {
                                    productList?.data?.payload?.sellerDetails && productList?.data?.payload?.sellerDetails?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <img className="d-block w-100" src={val?.user_profiles?.banner_image} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </SliderWrapperNew>
                    </div>

                    {
                        (productsData?.length > 0) ? <>
                            <div className="fashion-mainprt">
                                <div className="row">
                                    {productsData?.map((val, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="col-6">
                                                    <div className={(index === 3) ? "fashion-item fash-margtop" : (index === 5) ? "fashion-item fash-margtop2" : (index === 7) ? "fashion-item fash-margtop3" : "fashion-item"}>
                                                        <div className="fashion-slider" >
                                                            <SliderWrapperFashion>
                                                                <Slider {...offersSettings}>
                                                                    {
                                                                        val?.product_images && val?.product_images?.map((value, i) => {
                                                                            // console.log(val?.product_images ,"immmmmmmmmmmm")
                                                                            return (
                                                                                <div key={i}>
                                                                                    <img src={value?.url} alt="" className={((index === 1) || (index === 3) || (index === 4)) ? "fashion-m":"fashion-l"}/>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Slider>
                                                            </SliderWrapperFashion>
                                                        </div>
                                                        <div className="fashion-cnt">
                                                            <div className="fashion-color">
                                                                {val?.product_attribute?.find(item => item?.attributes?.name === "Color")?.attributes?.attribute_values?.map((value, i) => {
                                                                    return (
                                                                        <span key={i} className={(color === value?.name && colorId === val?.product_attribute[0]?.product_id) ? "active" : ""} onClick={() => colorHandle(value?.name, val?.id)} style={{ backgroundColor: `${value?.name}` }}></span>
                                                                    )
                                                                })}


                                                            </div>

                                                            <p style={{ cursor: 'pointer' }} ><strong>{val?.name}</strong> <br />{val?.description}</p>

                                                            <div className="fashion-color">
                                                                {val?.product_attribute?.find(item => item?.attributes?.name === "Size")?.attributes?.attribute_values?.map((value, i) => {
                                                                    return (
                                                                        <ul key={i}>
                                                                            <li className={(size === value?.name && sizeId === val?.product_attribute[1]?.product_id) ? "active" : ""} onClick={() => sizeHandle(value?.name, val?.id)}>{value?.name}</li>
                                                                        </ul>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                            <h4>${val?.price}</h4>
                                                            <Link to="#" onClick={() => history.push(`/home-fashion-store-product/${val?.id}`)}>
                                                                <img src={AddIcon} alt="" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </> : ""
                    }
                    <div className="belllIcon" onClick={() => { setModalDetail({ show: true, flag: 'menu', }); setKey(Math.random()); }}>
                        <img src={More1} alt="more" />
                    </div></>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                isHomeFoodModal={true}
                child={
                    <div className='menuPopup'>
                        <ul>
                            {
                                categoryData?.data?.payload?.data?.map((val, index) => {
                                    return (
                                        <li key={index} className='restaurent_menu' onClick={() => handleOnMenuModal(val?.id)}><Link to="#">{val?.menues?.name} <span>{val?.name}</span></Link></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal}
            />
        </div>
    )
}

export default HomeFashionStore