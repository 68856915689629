import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";
import { couponsAsync, selectCouponsAll, selectVerifiedCoupon, verifiedCouponsApiAsync } from "./couponSlice";
import moment from "moment-timezone";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const Coupons = (props) => {
    const history = useHistory();
    const [couponCode, setCouponCode] = useState("");
    const dispatch = useDispatch();
    const toastId = React.useRef(null)
    const couponsData = useSelector(selectCouponsAll);
    const couponVerifiedData = useSelector(selectVerifiedCoupon)

    let cartPrice = localStorage.getItem("cartprice")
    console.log(couponsData,"coupons data")

    const auth = useSelector(selectLoginAuth)
    const handleChange = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "couponCode") {
            setCouponCode(value);
        }
    }

    const handleAddCoupon = async() => {
        let data = {
            "postData": couponCode,
            "tokenData": auth?.payload?.token
        }
        await dispatch(verifiedCouponsApiAsync(data))
        .then(unwrapResult)
        .then((obj) => {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(obj?.msg)
            }
            localStorage.setItem("couponsdata",JSON.stringify(couponVerifiedData))
            history.push('/cart')
        }
        )
        .catch((obj) => {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(obj?.message)
                console.log(obj)
            }
        })
    }

    const handleApply = (value) => {
        console.log(value, "data of coupons")
        localStorage.setItem("couponsdata",JSON.stringify(value))
        history.push('/cart')
    }

    useEffect(() => {
        dispatch(couponsAsync())
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading browse-addprdcard-header">
                <div className="header-left-item">
                    <h4><Link to='/cart'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Coupons</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="coupon-part">
                    <form action="#" method="POST">
                        <input type="text" placeholder="Coupon code" onChange={(e) => { handleChange(e, 'couponCode') }} value={couponCode} />
                        <button type="button" onClick={handleAddCoupon}>Add Coupon</button>
                    </form>
                </div>

                <div className="coupon-tab">
                    <div>
                        <div className="select_coupon">
                            <h3>Select a coupon</h3>
                            {couponsData?.data?.payload?.length > 0 ? couponsData?.data?.payload.map(((coupons, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div id="tab1">
                                            <div className="coupon-tab1">
                                                <div className="box_shadow">
                                                    <div className="coupon-box">
                                                        <h2>{coupons.code ? coupons.code : "Welcome coupon"}<span>{coupons?.discount_percentage}%  OFF</span></h2>

                                                        {
                                                            (cartPrice > coupons?.minimum_order_amount ?
                                                                <p>Save {Number((cartPrice * coupons?.discount_percentage) / 100).toFixed(2)} on this order</p>:
                                                                <p>Add {Number(Number(coupons?.minimum_order_amount)- Number(cartPrice)).toFixed(2)} more to avail this offer</p>   )
                                                        }
                                                        <h6 className="max_discount">Max. discount: {coupons?.max_discount}</h6>
                                                        <h4><span>Min. order: ${coupons.minimum_order_amount ? coupons?.minimum_order_amount : "0"}</span> <span><small>Valid until</small> {coupons.end_time ? moment(coupons?.end_time).format('MMM DD, YYYY') : ""}</span></h4>
                                                        <div className="line"></div>
                                                    </div>
                                                    <div className="coupon-btm">
                                                        <button type="button" style={{opacity: cartPrice < coupons?.minimum_order_amount? 0.7 : 1 }} onClick={() => handleApply(coupons)} disabled={cartPrice < coupons?.minimum_order_amount}>Apply</button>
                                                        <Link to="#">T&C</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })) : ""}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Coupons;