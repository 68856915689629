import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import Add from '../../containers/productAddToCart/add'
import {
    searchCircleImg, dotCircleImg, discountOutlineImg,
    starCircleImg, deliveryParcelImg, ProductSt8, More1, LeftCircle2, arrowBlueImg
} from "../../../utilities/images";
import getStore from "../../apis/store.json"
import OwlCarousel from 'react-owl-carousel';
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import CustomModal from "../../components/shared/CustomModal";
import { getProductListAsync, selectedProductData, selectCategoryData, getCategoryApiAsync, selectCategoryProductData, getCategoryWithProductsAsync, selectAllProductsByCategoryId, getAllProductsByCategoryIdAsync, selectCategoryBySellerIdAsync, getCategoryBySellerIdAsync } from "../dashboard/dashboardSlice";
import Geocode from "react-geocode";
import { emptyCartApiAsync, selectCartData } from "../cart/cartSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SliderWrapper from "../dashboard/offerStyle";
import Slider from "react-slick";

const Store = (props) => {
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
    const [productId, setProductId] = useState("")
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const { id } = useParams()
    const dispatch = useDispatch()
    const [address, setAddress] = useState("")
    const [productsData, setProductsData] = useState([]);
    // console.log(productsData,'productsData');
    const [city, setCity] = useState("")
    const categoryProductData = useSelector(selectCategoryProductData)
    console.log(categoryProductData, 'categoryProductData');
    const cartData = useSelector(selectCartData);
    console.log(categoryProductData, 'categoryProductData')
    const categoryProductDataBySellerId = useSelector(selectCategoryBySellerIdAsync)
    console.log(categoryProductDataBySellerId, 'categoryProductDataBySellerId')
    const auth = useSelector(selectLoginAuth)
    const { status } = useSelector(state => state.dashboard)
    const sellerData = useSelector(selectedProductData)
    console.log(sellerData, "sellerData");
    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            Geocode.setApiKey("AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    setAddress(address)
                    setCity(city)
                },
                (error) => {
                    console.error(error);
                }
            )
        })
    }
    
    const handleOnCloseModal = () => {
        // console.log("in close ")
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleProduct = (data) => {
        setProductId(data)
        setModalDetail({ show: true, flag: 'add', }); setKey(Math.random())
    }
    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    let newarray = categoryProductDataBySellerId?.data?.payload?.slice(0, 4);
    const final = newarray?.map((item) => {
        return item.id
    })
    let data2 = {
        "postData": final,
        "tokenData": auth?.payload?.token
    }
    let sellerIdData = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }

    const handleCartExist = (flag) => {
        setModalDetail({ show: true, flag: flag }); setKey(Math.random());
    }
    const removeCart = () => {
        dispatch(emptyCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                toast.success(obj?.data?.msg)
                handleOnCloseModal()
            }
            )
            .catch((obj) => {
            })
    }
    const renderRemoveCartHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            {/* <img src={cancelOrderImg} alt="veirfy2Img" /> */}
                            <h6>Items already in cart</h6>
                            <p>Your cart contains item from other services.
                                would you like to reset your cart for adding
                                items from the restaurent.
                            </p>
                        </div>
                        <div className="modal-footer" >
                            <button className="btn cancleBtn " onClick={() => handleOnCloseModal()}  >No</button>
                            <button className="btn btn-primary submitBtn" onClick={() => removeCart()}>Yes, start a fresh</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const handleOnMenuModal = (id) => {
        let newProductsData = categoryProductData?.data?.payload?.filter(item => item?.id === id)
        setProductsData(newProductsData)
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const offersSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };
    const productSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 2,
        centerMode: true,
        centerPadding: '50px',
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };

    useEffect(() => {
        dispatch(getCategoryBySellerIdAsync(sellerIdData))
        dispatch(getProductListAsync(data))
        dispatch(getCategoryWithProductsAsync(data2))
            .then(unwrapResult)
            .then(async (obj) => {
                // console.log(obj, 'object');
                setProductsData(obj?.data?.payload)
            }
            )
            .catch((obj) => {
            })
        geocodeHandler()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {status.productList === "loading" || status.sellerProducts === "loading" || status.categoryProduct === "loading" || status.cartData === "loading" ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div className="main-content">
                    <div className="main">
                        <img src={sellerData?.data?.payload?.sellerDetails[0]?.user_profiles?.banner_image} className="img-fluid" />
                        <div className="topMain">
                            <div className="left-circle"><div onClick={() => history.goBack()}><img src={LeftCircle2} alt="" /></div></div><div className="store-iconrit rest-menu"><Link to="#"><img src={searchCircleImg} alt="" /></Link><a className="dot-menu" href="/home-restaurent/b169ed4d-be27-44eb-9a08-74f997bc6a2a"><img src={dotCircleImg} alt="" /></a></div>
                        </div>
                    </div>
                    <div className="store-banner-part">
                        <div className="store-box">
                            <div className="store-item">
                                <img src={sellerData?.data?.payload?.sellerDetails[0]?.user_profiles?.profile_photo} alt="" />
                            </div>
                            <div className="store-cnt">
                                <h4>{sellerData?.data?.payload?.sellerDetails[0]?.user_profiles?.username} <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                                <p><img src={starCircleImg} alt="" /> {sellerData?.data?.payload?.sellerRatings} ({sellerData?.data?.payload?.totalCountOfRatings}+ ratings)</p>
                                <h6>Retail store</h6>
                                <ul>
                                    <li>Outlate <span>{sellerData?.data?.payload?.outlateLocation}</span></li>
                                    <li>30 mins <Link to="#">{city} <i className="fas fa-chevron-down"></i></Link></li>
                                </ul>
                                <h3><Link to="#"><img src={deliveryParcelImg} alt="" /> {sellerData?.data?.payload?.deliveryFee} Delivery fee</Link></h3>
                            </div>
                        </div>
                        {/* <div id="bannerSlider">
                            <OwlCarousel
                                items={1}
                                className="owl-theme"
                                loop={true}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={2000}
                                slidespeed={10}
                                dotsdata="false"
                                margin={1}
                            >
                                {
                                    sellerData?.data?.payload?.sellerOffersList?.length > 0 && sellerData?.data?.payload?.sellerOffersList?.map((val, index) => {
                                        return (
                                            <>
                                                <div className="store-btn" key={index}>
                                                    <Link to="#"><img src={discountOutlineImg} alt="discountOutlineImg" />
                                                        {val?.discount_percentage}% off upto {val?.max_discount}</Link>
                                                    <div className="owl-dots dotstab d-block">
                                                        {sellerData?.data?.payload?.sellerOffersList?.map((val, index1) => {
                                                            return (
                                                                <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${sellerData?.data?.payload?.sellerOffersList?.length}` : ""}</span></button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> */}

                        <div id="offerSlider">
                            <SliderWrapper>
                                <Slider {...offersSettings}>
                                    {
                                        sellerData?.data?.payload?.sellerOffersList?.length > 0 && sellerData?.data?.payload?.sellerOffersList?.map((val, index) => {
                                            return (
                                                <>
                                                    <div className="store-btn" key={index}>
                                                        <Link to="#"><img src={discountOutlineImg} alt="discountOutlineImg" />
                                                            {val?.discount_percentage}% off upto {val?.max_discount}</Link>
                                                        <div className="owl-dots dotstab d-block">
                                                            <span>{(index + 1)}/{sellerData?.data?.payload?.sellerOffersList?.length}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </Slider>
                            </SliderWrapper>

                        </div>
                    </div>

                    <div className="store-category">
                        <div className="store-categpart store-product-mainprt">
                            {categoryProductDataBySellerId?.data?.payload?.slice(0, 7).map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="store-catgitem">
                                            <Link to={(`/product-add-to-cart/${categoryProductDataBySellerId?.data?.payload[index].id}/${id}`)}>
                                                <img src={item.image} alt="topCategoryOneImg" />
                                            </Link>
                                            <p>{item?.name}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                            {categoryProductDataBySellerId?.data?.payload?.length > 7 ?
                                <div className="store-catgitem">
                                    <Link to={(`/browse-next/${id}`)}>
                                        <img src={ProductSt8} alt="" />
                                    </Link>
                                    <p>More</p>
                                </div> : null
                            }
                        </div>
                    </div>

                    <div className="store-slider">
                        {productsData?.length > 0 &&
                            <>
                                {productsData?.map((item, index) => {
                                    return (
                                        <>{categoryProductData?.data?.payload[index]?.products.length > 0 ?
                                            <>
                                                <div key={index} className="store-sldhead">
                                                    <h2>{item.name}</h2>
                                                    <Link to={(`/product-add-to-cart/${categoryProductData?.data?.payload[index]?.id}/${id}`)}>View all</Link>
                                                </div>
                                                <SliderWrapper>
                                                    <Slider {...productSettings}>
                                                    {categoryProductData?.data?.payload[index]?.products?.map((val, i) => {
                                                        return (<React.Fragment key={i}>
                                                            <div className="carousel-cell" key={i}>
                                                                <div className="paddcart-item">
                                                                    <div className="paddcart-inner">
                                                                        <img src={val?.image} alt="" />
                                                                        <h4>{val?.name}</h4>
                                                                        {/* <p>{val?.brand?.name}</p> */}
                                                                        <h6><span>{val?.rating ? val?.rating : 0}</span>${val?.price}</h6>
                                                                    </div>
                                                                    <div className="paddcart-btn">
                                                                        <Link onClick={() => handleProduct(val?.id)} to="#"><i className="fas fa-plus"></i></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>)
                                                    })}
                                                    </Slider>
                                                </SliderWrapper>
                                            </>
                                            : <></>
                                        }

                                        </>
                                    )
                                })}
                            </>
                        }
                        {/* {allStoreItem && allStoreItem.Cigar && allStoreItem.Cigar.length > 0 &&
                        <>
                            <div className="storesld-bannerbtm">
                                <OwlCarousel items={1.1}
                                    className="owl-theme main-carousel3"
                                    nav={false}
                                    dots={false}
                                    margin={0}
                                    loop={true}
                                    autoplay={true}
                                    autoplayTimeout={2000}
                                >
                                    {allStoreItem && allStoreItem.Cigar.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="carousel-cell">
                                                    <div className="banner2-item">
                                                        <img src={bannerSld2Img} alt="bannerSld2Img" />
                                                        <Link to='/cart'>Check out <img src={arrowRightSideImg} alt="arrowRightSideImg" /></Link>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </OwlCarousel>
                            </div>
                        </>} */}
                    </div>
                    {cartData?.data?.payload?.cart_products.length > 0 ?
                        <div className="cartItemsbox">
                            <div className="selected-itembox">
                                <div className="selected-leftcnt">
                                    <p>Items: <strong>{cartData?.data?.payload?.cart_products.length}</strong></p>
                                    <p>$ <strong>{(cartData?.data?.payload?.amout?.total_amount).toFixed(2)}</strong></p>
                                </div>
                                <div className="selected-rightbtn">
                                    <Link to='/cart'>
                                        <img src={arrowBlueImg} alt="arrowBlueImg" />
                                    </Link>
                                </div>
                            </div>
                        </div> : ""}
                    <div className="belllIcon" onClick={() => { setModalDetail({ show: true, flag: 'menu', }); setKey(Math.random()); }}>
                        <img src={More1} alt="more" />
                    </div>
                </div>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={modalDetail.flag === 'add' ? true : false}
                isRightSideModal={false}
                header={
                    <div className="modal-header modal-header2" >
                        <button type="button" id="close1" className="close" onClick={() => handleOnCloseModal()}>
                            <span htmlFor="close1">Close</span>
                        </button>
                    </div>
                }
                mediumWidth={modalDetail.flag === 'add' ? false : true}
                child={modalDetail.flag === 'add'
                    ? <Add closeModal={() => handleOnCloseModal()} sellerId={id} productId={productId} cartExist={(e) => handleCartExist(e)} />
                    : modalDetail.flag === 'cart_exist' ?
                        renderRemoveCartHtml() : modalDetail.flag === 'menu' ?
                            <div className='menuPopup'>
                                <ul>
                                    {
                                        categoryProductDataBySellerId?.data?.payload?.map((val, index) => {
                                            return (
                                                <li key={index} className='restaurent_menu' onClick={() => handleOnMenuModal(val?.id)}><Link to="#">{val?.menues?.name} <span>{val?.name}</span></Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            : <></>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default Store;