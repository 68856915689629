import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../public/images/brand-logo.png";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync, selectLoginProfile } from './authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const Login = (props) => {
    const history = useHistory();
    const [pin, setPin] = useState("");
    const toastId = React.useRef(null)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const { status1 } = useSelector(state => state.auth)

    let selectVerify = useSelector(selectLoginProfile)
    const onComplete = (code) => {
        setPin(code);
    }
    const phonedetails = JSON.parse(localStorage.getItem("phonedetails"))

    let data = {
        "phone_code": phonedetails?.phone_code,
        "phone_number": phonedetails?.phone_no,
        "password": pin,
        "role_slug": "Customer"
    }

    const handleOnSubmit = async () => {
        if (!pin || pin.length < 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your PIN");
            }
            return false
        }
        const invalidOtp = await localStorage.getItem("invalidotp")
        await dispatch(loginAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/dashboard')
            }
            )
            .catch((obj) => {
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                //     console.log(obj,"obj error")
                // }
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <div className="logo-login">
                <Link to="#">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <div className="login-main">
                <p>Enter your PIN for login</p>
                {/* <div className="pin-box text-center" >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div> */}
                <div className="pin-box d-flex justify-content-center" >
                    <OTPInput numInputs={4}
                        className='input_digits_'
                        value={pin}
                        isInputNum={true}
                        data-cy="pin-field"
                        onChange={onComplete}
                    // onComplete={(code) => onComplete(code)}
                    />
                </div>
                {/* <div className="pin-box text-center" >
                    <PinField className='input_digits_'
                        length={4}
                        data-cy="pin-field"
                        // onChange ={() => {}} 
                        onComplete={(code) => onComplete(code)}
                    />
                </div> */}
            <div className="forgot-pinbtn">
                    <Link to='/forgot-password'>Forgot PIN?</Link>
                </div>
            </div>

            <div className="bottom-part">
                <button className="btn btn-primary continue" type="button" disabled={status1?.loginStatus === "loading"} onClick={handleOnSubmit}>
                    {status1?.loginStatus === "loading" && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Continue</span>
                </button>
            </div>
        </>
    )
}

export default Login;