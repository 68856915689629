import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import {leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";

const HelpCenterTwo = (props) => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
        <>
         <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Get help with my orders</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>  
            </div>

            <div className="main-content">
                <div className="help-tab">
                    <div id="tab1" className="tab">
                        <div className="tab-othercnt">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                        </div>
                    </div>

                    <div id="tab2" className="tab active">
                        <div className="help-tab2prt">
                            <div className="help-tabbox">
                                <h4 onClick={()=> history.push('/help-center-three')} style={{cursor:'pointer'}}>Miami smoke </h4>
                                <p>Marlboro Red Gold, Marlboro White</p>
                                <small>14 Jun, 21:33</small>
                                <span>Delivered</span>
                            </div>
                            <div className="help-tabbox">
                                <h4  onClick={()=> history.push('/help-center-three')} style={{cursor:'pointer'}}>Miami smoke </h4>
                                <p>Marlboro Red Gold, Marlboro White</p>
                                <small>09 Jun, 21:33</small>
                                <span>Delivered</span>
                            </div>
                        </div>
                    </div>
                </div>                                       
            </div>

        </>
      );
    }  

export default HelpCenterTwo;