import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";
import { faqAsync, selectUserFaq } from "./faqSlice";

const Faq = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectFaq = useSelector(selectUserFaq);
    let Faq = selectFaq.faq;
    
    useEffect(() => {
        let params = {
            page: 1,
            limit: 10
        }
        dispatch(faqAsync(params))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> FAQ</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="help-part">
                    {Faq && Faq.data?.length > 0 ? (
                        <>
                            {Faq?.data?.map((items, index) => {

                                return (
                                    <ul>
                                        <li>
                                            <Link  onClick={() =>{history.push({pathname: '/my-account-two', state: {faqid: items.id }})}} >{items.question} <i className="fas fa-chevron-right"></i></Link>
                                        </li>
                                        {/* <li>
                                            <Link to='/faq-verified'>How to get verified? <i className="fas fa-chevron-right"></i></Link>
                                        </li>
                                        <li>
                                            <Link to="#">SMS verification not received <i className="fas fa-chevron-right"></i></Link>
                                        </li>
                                        <li>
                                            <Link to='/home-support'>Delete my account <i className="fas fa-chevron-right"></i></Link>
                                        </li> */}
                                    </ul>
                                )
                            })}
                        </>
                    ) : (
                        <p className="text-center mt-5 w-70">No Faq(s) Found</p>
                    )}

                </div>
            </div>
        </>

    );
}

export default Faq;