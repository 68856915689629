import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { bagImg, bellImg, clockImg, coinStackImg, deliveryParcelImg, leftArrowImg, starCircleImg } from '../../../utilities/images'
import { selectLoginAuth } from '../auth/authSlice'
import { getSellersByCategoryIdAsync, selectSellerByCategoryId } from './dashboardSlice'

const Sellers = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { status } = useSelector(state => state.dashboard)
    const sellerData = useSelector(selectSellerByCategoryId)
    const auth = useSelector(selectLoginAuth)
    console.log(sellerData?.data?.payload?.data, "seller data")
    const { id } = useParams()

    const handleProduct = (data) => {
        history.push({ pathname: `/home-restaurent/${data}` })
    }

    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        dispatch(getSellersByCategoryIdAsync(data))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className="header-left-item">
                    <div className="header-left">
                        <Link to="#" onClick={() => history.goBack()}>
                            <img src={leftArrowImg} alt="leftArrow" />
                        </Link>
                        <Link to="#">Sellers</Link>
                    </div>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => { history.push('/notifications') }}><img src={bellImg} alt="bellImg" /></button>
                    <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                    <Link to="#">0
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
            {status.categoryData === "loading" ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                :
                <>
                    {sellerData?.data?.payload?.data?.length > 0 ?
                        <div className="delivery-parthm pt0 mb-5 pb-5">
                            <div className="trending-title pt-5">
                                <h2>Trending</h2>
                            </div>
                            {sellerData?.data?.payload?.data?.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => handleProduct(value?.unique_uuid)}>
                                            <img className="delv-prd" src={value?.user_profiles?.profile_photo} alt="" />
                                            <h4>{value?.user_profiles?.firstname}</h4>
                                            <ul>
                                                <li><img src={starCircleImg} alt="starCircleImg" />{value?.sellerRatings}</li>
                                                <li><img src={clockImg} alt="clockImg" />{value?.distance}</li>
                                                <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value?.deliveryFee} Delivery fee</li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                        : <div className="delivery-parthm">No Seller Found</div>}</>}
        </>
    )
}

export default Sellers