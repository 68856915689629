import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { arrowRightSideImg, bxsCoinStackImg, DeliveryMap, edit2Img, Info, leftArrowImg, LocationDark, locationImg, LogoIcon, OrderBlue, plusImg, veirfy2Img, Waiting, WalletBlue } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import { allAddressAsync, selectAllAddress } from "../addresses/addressSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { createOrderApiAsync, getCartApiAsync, selectCartData, selectCartResponse, selectCartSubmitResponse } from "../cart/cartSlice";

const Promotion = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const [zoom, setZoom] = useState(15)
    const [latitude, setLatitude] = useState(30.900965)
    const [longitude, setLongitude] = useState(75.8572758)
    // const cartData = useSelector(selectCartData)
    const cartFullResponse = useSelector(selectCartSubmitResponse)
    const addressData = useSelector(selectAllAddress)
    const toastId = React.useRef(null)
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const location = useLocation()

    let locationData = location?.state ? JSON.parse(location?.state) : ""

    console.log(cartFullResponse, "cart full response")
    let discountValue = cartFullResponse?.cartOtherData?.couponData ? Number((cartFullResponse?.amout?.total_amount * cartFullResponse?.cartOtherData?.couponData?.discount_percentage) / 100).toFixed(2) : (0).toFixed(2)
    function amount(item) {
        return item.price;
    }
    function sum(prev, next) {
        return prev + next;
    }

    console.log(location?.state,"props location data")
    console.log(addressData,"save address data")

    const date = new Date();
    let deliveryData
    let couponData
    if (cartFullResponse?.cartOtherData?.delivery_type_id === 1) {
        deliveryData = {
            "delivery_type_id": cartFullResponse?.cartOtherData?.delivery_type_id
        }
    }
    else {
        deliveryData = {
            "delivery_type_id": cartFullResponse?.cartOtherData?.delivery_type_id,
            "preffered_delivery_end_time": cartFullResponse?.cartOtherData?.preffered_delivery_end_time,
            "preffered_delivery_start_time": cartFullResponse?.cartOtherData?.preffered_delivery_start_time
        }
    }

    if (cartFullResponse?.cartOtherData?.couponData === "") {
        couponData = {
            "is_favourite": false
        }
    }
    else {
        couponData = {
            "coupon_code": cartFullResponse?.cartOtherData?.couponData && cartFullResponse?.cartOtherData?.couponData?.code,
            "is_favourite": false
        }
    }

    let data = {
        "postData":
        {
            "cart_id": cartFullResponse?.cart_products[0]?.cart_id,
            "address_id": location?.state ? locationData?.id : addressData?.payload?.data[0]?.id,
            "address_type": location?.state ? locationData?.address_type : addressData?.payload?.data[0]?.address_type,
            "address": location?.state ? locationData?.custom_address : addressData?.payload?.data[0]?.custom_address,
            "address_2": location?.state ? locationData?.custom_address : addressData?.payload?.data[0]?.custom_address,
            "city": location?.state ? locationData?.city : addressData?.payload?.data[0]?.city,
            "state": location?.state ? locationData?.district : addressData?.payload?.data[0]?.state,
            "zip": location?.state ? locationData?.postal_code : addressData?.payload?.data[0]?.postal_code,
            "country": location?.state ? locationData?.country : addressData?.payload?.data[0]?.country,
            "fax": "465",
            "shipping": "Delivery",
            "coordinates": [
                location?.state ? locationData?.longitude : addressData?.payload?.data[0]?.longitude,
                location?.state ? locationData?.latitude : addressData?.payload?.data[0]?.latitude
            ],
            "date": moment(date).format('YYYY-MM-DD'),
            ...deliveryData,
            ...couponData,
            "service_id": "1"
        },
        "tokenData": auth?.payload?.token
    }

    console.log(data, "order details")

    const handleOrder = async () => {
        await dispatch(createOrderApiAsync(data))
            .then(unwrapResult)
            .then(async (obj) => {
                console.log(obj, "oreder response")
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                setModalDetail({ show: true, flag: "OrderPlaced" }); setKey(Math.random());
            }
            )
            .catch((obj) => {
                handleOnCloseModal()
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                // }
            })
    }

    const AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap
                    defaultZoom={zoom}
                    defaultCenter={{ lat: latitude, lng: longitude }}
                >
                    <Marker
                        name={''}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                        position={{ lat: latitude, lng: longitude }}
                    />
                </GoogleMap>
            )
        )
    );
    const onMarkerDragEnd = (event) => {
        //dragger event
    }
    const renderOrderPlacedHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h4>Order Placed</h4>
                            <img src={veirfy2Img} alt="veirfy2Img" />
                            <p>Your order placed successfully</p>
                            <h6>Order is proccessing</h6>
                        </div>
                        <div className="mdlverify-btn mdllogin-btn" onClick={()=> history.push('/tracking')}>
                            <Link to='#' className="active">Track order</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const renderWarningHtml = () => {
        return (
            <div className="modal-body">
                <div className="modal-mainprt warning-mdlbox">
                    <div className="mdlverify-cnt cardmdl-verify">
                        <h4>Warning</h4>
                        <img src={Waiting} alt="" />
                        <p>No showing up</p>
                        <h6>No Refund!</h6>
                    </div>
                    <div className="mdlverify-btn mdllogin-btn">
                        <Link to="#" onClick={() => handleOrder()} className="active">Confirm & Place the order</Link>
                    </div>
                </div>
            </div>
        )
    }
    const renderServiceFeesHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-header address-mdlheader">
                    <h5><strong>Whats included?</strong></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { handleOnCloseModal() }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body address-mdlbody pt-0">
                    <div className="taxes-mdlbox-cnt">
                        <h4>Service fees <span>$1.50</span></h4>
                        <p>Cras lobortis ligula sed metus tempor, nec aliquet dolor suscipit. Nullam eleifend condimentum sollicitudin.</p>
                    </div>
                    <div className="taxes-mdlbox-cnt">
                        <h4>Taxes <span>$1.50</span></h4>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        dispatch(getCartApiAsync(auth?.payload?.token))
        let params = {
            token: auth?.payload?.token,
        }
        dispatch(allAddressAsync(params))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/cart'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Review & Payment </h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="placeord-main">

                    {
                        addressData?.payload?.data?.length > 0
                            ?
                            <div className="delvaddres-boxr">
                                <h2><img src={LocationDark} alt="LocationDark" />Delivery Address </h2>
                                <div className="delv-map">
                                    <AsyncMap
                                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places`}
                                        loadingElement={
                                            <div style={{ height: `100%` }} />
                                        }
                                        containerElement={
                                            <div style={{ height: '300px' }} />
                                        }
                                        mapElement={
                                            <div style={{ height: `100%` }} />
                                        }
                                    />
                                </div>
                                <h4>{addressData?.payload?.data[0]?.address_type}</h4>
                                <p>{addressData?.payload?.data[0]?.formatted_address}</p>
                                <div onClick={() => history.push({ pathname: '/addresses', state: "placing" })} className="aprt-btn">{addressData?.payload?.data[0]?.custom_address} <i className="fas fa-chevron-right"></i></div>
                            </div>
                            :
                            <div className="delvaddres-boxr">
                                <h2><img src={locationImg} alt="locationImg" />Add Delivery Address <Link to="#" onClick={() => history.push({ pathname: '/new-address', state: "placing" })}><img src={edit2Img} alt="edit2Img" /></Link></h2>
                            </div>
                    }

                    <div className="delvpaymnt-box">
                        <h2><img src={WalletBlue} alt="" /> Balance <Link to="#"><img src={plusImg} alt="plusImg" /></Link></h2>
                        <Link className="paymnt-dlbtn" to='/payment'><img src={LogoIcon} alt="LogoIcon" /> JBR<span>JBR 0.00</span></Link>
                    </div>

                    <div className="orderdlv-detailsbox">
                        <h2><img src={OrderBlue} alt="OrderBlue" /> Order details</h2>
                        <div className="details-ordmdl border-top-0 pt-0">
                            <ul>
                                {
                                    cartFullResponse?.cart_products?.map((val, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="details-mdlleft">
                                                    <strong>{val?.qty}</strong> <small>x</small> {val?.product_details?.name} <span></span>
                                                </div>
                                                <p>${val?.product_details?.price + (val?.product_details?.selected_attribute_values.length > 0 ? val?.product_details?.selected_attribute_values?.map(amount).reduce(sum) : 0)}</p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <div className="total-part">
                            <ul>
                                <li>Sub Total {cartFullResponse?.amout?.total_amount ? <span>${(cartFullResponse?.amout?.total_amount).toFixed(2)}</span> : ""}</li>
                                <li>Coupon <span>-${discountValue}</span></li>
                                <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>$0.00</span></li>
                                <li>Delivery fees <span>${Number(cartFullResponse?.cartOtherData?.delivery_fees).toFixed(2)}</span></li>
                                <li><strong>Total</strong> <strong>${(((cartFullResponse?.amout?.total_amount) + Number(cartFullResponse?.cartOtherData?.delivery_fees)) - (discountValue)).toFixed(2)}</strong></li>
                            </ul>
                        </div>
                    </div>

                    <div className="compl-orcnt">
                        <h6>By completing this order, I agree to all <Link to="/terms-&-conditions">terms & conditions</Link></h6>
                    </div>

                </div>

                <div className="selected-product checkout-box">
                    <div className={(addressData?.payload?.data?.length > 0) ? "selected-itembox place-ordbtn" : "selected-itembox place-ordbtn place-misaddr"}>
                        <div className="selected-leftcnt">
                            <p><strong>Place Order</strong>{(addressData?.payload?.data?.length > 0) ? "" : <small>(Missing address)</small>} </p>
                        </div>
                        <div className="selected-rightbtn">
                            <a href={void(0)} onClick={() => { setModalDetail({ show: true, flag: "refund" }); setKey(Math.random()); }}>
                                <img src={arrowRightSideImg} alt="arrowRightSideImg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={false}
                mediumWidth={modalDetail.flag === 'OrderPlaced' ? false : true}
                darkBackground={modalDetail.flag === 'OrderPlaced' ? true : false}
                size="md"
                child={modalDetail.flag === 'OrderPlaced' ?
                    renderOrderPlacedHtml()
                    : (modalDetail.flag === "refund") ?
                        renderWarningHtml()
                        :
                        renderServiceFeesHtml()
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default Promotion;