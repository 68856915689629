import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import Add from './add';
import { leftArrowImg, SearchImg, bxsCoinStackImg, addCartP1Img, arrowBlueImg } from "../../../utilities/images";
import { getStoreAsync, selectStoreData } from "./productAddToCartSlice"
import { useSelector, useDispatch } from "react-redux";
import { getAllProductsByCategoryIdAsync, getProductsRetailByIdAsync, selectAllProductsByCategoryId, selectCategoryProductData, selectProductInventoryData, selectRetailProductsById } from "../dashboard/dashboardSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { getCartApiAsync, selectCartData } from "../cart/cartSlice";
import { logDOM } from "@testing-library/react";

const ProductAddToCart = (props) => {
    const history = useHistory();
    const { id, sellerId } = useParams();
    const [productId, setProductId] = useState("")
    const { status } = useSelector(state => state.dashboard)
    const categoryProductData = useSelector(selectCategoryProductData)
    const allProductsByCategory = useSelector(selectAllProductsByCategoryId)
    const auth = useSelector(selectLoginAuth)
    // console.log(allProductsByCategory, "allProductsByCategory")
    // const [Cigarettes, setCigarettes] = useState([]);
    const [key, setKey] = useState(Math.random());
    const cartData = useSelector(selectCartData);
    console.log(cartData, 'cartdata');
    const [selectedProduct, setSelectedProduct] = useState('Marlboro')
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const dispatch = useDispatch();
    const Cigarettes = useSelector(selectStoreData);

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    let data3 = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    const handleProduct = (data) => {
        setProductId(data)
        setModalDetail({ show: true, flag: 'add', }); setKey(Math.random())
    }

    useEffect(() => {
        dispatch(getStoreAsync())

        dispatch(getAllProductsByCategoryIdAsync(data3))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])
          
    return (
        <>
            {
                status.allProducts === "loading" ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        <div className="order-heading browse-addprdcard-header">
                            <div className="header-left-item">
                                <h4><span onClick={() => history.goBack()}><img src={leftArrowImg} alt="leftArrowImg" /></span> {allProductsByCategory?.data?.payload?.data[0]?.category?.name}</h4>
                            </div>
                            <div className="header-right-item">
                                <button>
                                    <img src={SearchImg} alt="SearchImg" />
                                </button>
                                <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                            </div>
                        </div>

                        <div className="main-content">
                            <div className="product-tabarea">
                                <div className="tabs">
                                    {/* <div className="tab-upper">
                            <ul className="tab-links tab-menu">
                                <li className={(selectedProduct === 'Marlboro') ? 'active' : ''} onClick={() => { setSelectedProduct('Marlboro') }}><Link to="#tab1">Marlboro</Link></li>
                                <li className={(selectedProduct === 'Dunhill') ? 'active' : ''}><Link to="#tab2" onClick={() => { setSelectedProduct('Dunhill') }}>Dunhill</Link></li>
                                <li className={(selectedProduct === 'B&H') ? 'active' : ''}><Link to="#tab3" onClick={() => { setSelectedProduct('B&H') }}>B&H</Link></li>
                                <li className={(selectedProduct === 'Black Devil') ? 'active' : ''}><Link to="#tab4" onClick={() => { setSelectedProduct('Black Devil') }}>Black Devil</Link></li>
                                <li className={(selectedProduct === 'Camel') ? 'active' : ''}><Link to="#tab5" onClick={() => { setSelectedProduct('Camel') }}>Camel</Link></li>
                                <li className={(selectedProduct === 'Davidoff') ? 'active' : ''}><Link to="#tab6" onClick={() => { setSelectedProduct('Davidoff') }}>Davidoff</Link></li>
                            </ul>
                        </div> */}

                                    <div className="tab-content">
                                        <div id="tab1" className="tab active">
                                            <div className="product-addcart-main">
                                                {allProductsByCategory?.data?.payload?.data?.length > 0 ? allProductsByCategory?.data?.payload?.data?.map((val, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="paddcart-item">
                                                                <div className="paddcart-inner">
                                                                    <img src={val.image} alt="" />
                                                                    <h4>{val.name}</h4>
                                                                    <p>{val.titleTwo}</p>
                                                                    <h6><span>{val.rating ? val.rating : 0}</span> ${val.price}</h6>
                                                                </div>
                                                                <div className="paddcart-btn">
                                                                    <Link
                                                                        to='#'
                                                                        //href="#" data-toggle="modal" data-target="#exampleModalCenter"
                                                                        onClick={() => handleProduct(val?.id)}
                                                                    ><i className="fas fa-plus"></i></Link>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                                    : <>No Data</>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {cartData?.data?.payload?.cart_products.length > 0 ?
                                <div className="cartItemsbox">
                                    <div className="selected-itembox">
                                        <div className="selected-leftcnt">
                                            <p>Items: <strong>{cartData?.data?.payload?.cart_products.length}</strong></p>
                                            <p>$ <strong>{(cartData?.data?.payload?.amout?.total_amount).toFixed(2)}</strong></p>
                                        </div>
                                        <div className="selected-rightbtn">
                                            <Link to='/cart'>
                                                <img src={arrowBlueImg} alt="arrowBlueImg" />
                                            </Link>
                                        </div>
                                    </div>
                                </div> : ""}
                        </div>
                    </>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop = "static"
                showCloseBtn={false}
                isRightSideModal={true}
                child={modalDetail.flag === 'add'
                    ? <Add closeModal={() => handleOnCloseModal()} sellerId={sellerId} productId={productId} />
                    : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>

    );
}

export default ProductAddToCart;
