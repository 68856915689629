import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getAllBrands,
    getAllProducts,
    getAllOffers,
    getDashboardData,
    getFoodData,
    getRetailData,
    getFashionStoreData,
    getAllServices,
    getHomeData,
    getCategories,
    getTrendingNearBySeller,
    getProductListBySupplier,
    createBookingApi,
    getBookingSlot,
    getTrendingNearBySellerReservation,
    getMenuApi,
    getMenuProductApi,
    getProductByIdApi,
    getSellersByCategoryId,
    getBannerByServiceType,
    getTrendingCategoryServiceType,
    getTrendingProduct,
    getCategoryWithProducts,
    getCouponByServiceId,
    getAllProductsByCategoryId,
    getProductsRetailById,
    getCategoryBySellerId,
    getAllOrderByUser,
    getReorderByUser,
    cancelOrderByUser,
    ratingFeedbackByUser,
    getExtraProductsByCategoryId
  
} from "./dashboardApi";

const initialState = {
    brand: [],
    product: [],
    offer: [],
    dashboardDetails: {},
    foodData: [],
    retailData: [],
    fashionStore: [],
    servicesData: [],
    homeData: [],
    categoryData: [],
    trendingNearby: [],
    trendingReservationNearby: [],
    productList: [],
    bookingSlot: [],
    createBooking: [],
    menuList: [],
    inventoryProduct: [],
    productData: {},
    sellerByCategory: [],
    bannerData: [],
    trendingCategoryData: [],
    trendingProduct: [],
    categoryProduct: [],
    couponSeller: [],
    coupenService:[],
    allProducts: [],
    retailProduct:[],
    sellerProducts:[],
    allOrderByUser:[],
    reorderProducts:[],
    cancelProducts:[],
    ratingFeedback:[],
    extraProducts:[],
     status: {
        brand: 'idle',
        product: 'idle',
        offer: 'idle',
        dashboardDetails: 'idle',
        foodData: "idle",
        retailData: "idle",
        fashionStore: "idle",
        servicesData: "idle",
        homeData: "idle",
        categoryData: "idle",
        trendingNearby: "idle",
        productList: "idle",
        bookingSlot: "idle",
        createBookingApi: "idle",
        trendingReservationNearby: "idle",
        menuList: 'idle',
        inventoryProduct: 'idle',
        productData: 'idle',
        sellerByCategory: "idle",
        bannerData: "idle",
        trendingCategoryData: "idle",
        trendingProduct: "idle",
        categoryProduct: "idle",
        couponSeller: "idle",
        coupenService:"idle",
        allProducts: "idle",
        retailProduct:"idle",
        sellerProducts:"idle",
        allOrderByUser:"idle",
        reorderProducts:"idle",
        cancelProducts:"idle",
        ratingFeedback:"idle",
        extraProducts:"idle"  

    },
};

export const getBrandAsync = createAsyncThunk("dashboard/brand", async (data) => {
    const response = await getAllBrands();
    return response;
});
export const getProductAsync = createAsyncThunk("dashboard/product", async (data) => {
    const response = await getAllProducts();
    return response;
});
export const getOfferAsync = createAsyncThunk("dashboard/offer", async (data) => {
    const response = await getAllOffers();
    return response;
});
export const getDashboardAsync = createAsyncThunk("dashboard/dash", async (data) => {
    const response = await getDashboardData();
    return response;
});
export const getFoodDataAsync = createAsyncThunk("dashboard/food", async (data) => {
    const response = await getFoodData();
    return response;
});
export const getRetailDataAsync = createAsyncThunk("dashboard/retail", async (data) => {
    const response = await getRetailData();
    return response;
});
export const getFashionStoreDataAsync = createAsyncThunk("dashboard/fashionstore", async (data) => {
    const response = await getFashionStoreData();
    return response;
});
export const getServicesDataAsync = createAsyncThunk("dashboard/servicesdata", async () => {
    const response = await getAllServices();
    return response;
});
export const getHomeDataApiAsync = createAsyncThunk("dashboard/homedata1", async () => {
    const response = await getHomeData();
    return response;
});
export const getCategoryApiAsync = createAsyncThunk("dashboard/categorydata", async (data) => {
    const response = await getCategories(data);
    return response;
});
export const getTrendingNearBySellerAsync = createAsyncThunk("dashboard/trendingnearby", async (data) => {
    const response = await getTrendingNearBySeller(data);
    return response;
});
export const getTrendingNearBySellerReservationAsync = createAsyncThunk("dashboard/trendingnearreservationby", async (data) => {
    const response = await getTrendingNearBySellerReservation(data);
    return response;
});
export const getProductListAsync = createAsyncThunk("dashboard/productasync", async (data) => {
    const response = await getProductListBySupplier(data.postData, data.tokenData);
    return response;
});
export const getBookingSlotAsync = createAsyncThunk("dashboard/slotlist", async (data) => {
    const response = await getBookingSlot(data.postData, data.tokenData);
    return response;
});
export const createBookingApiAsync = createAsyncThunk("dashboard/createbooking", async (data) => {
    const response = await createBookingApi(data.postData, data.tokenData);
    return response;
});
export const getMenuApiAsync = createAsyncThunk("dashboard/getMenuApi", async (data) => {
    const response = await getMenuApi(data);
    return response;
});
export const getMenuProductApiAsync = createAsyncThunk("dashboard/getMenuproductapi", async (data) => {
    const response = await getMenuProductApi(data);
    return response;
});
export const getProductByIdApiAsync = createAsyncThunk("dashboard/getProductByIdApi", async (data) => {
    const response = await getProductByIdApi(data.postData, data.tokenData);
    return response;
});
export const getSellersByCategoryIdAsync = createAsyncThunk("dashboard/getSellersByCategoryId", async (data) => {
    const response = await getSellersByCategoryId(data.postData, data.tokenData);
    return response;
});
export const getBannerByServiceTypeAsync = createAsyncThunk("dashboard/getBannerByServiceType", async (data) => {
    const response = await getBannerByServiceType(data.postData, data.tokenData);
    return response;
});
export const getTrendingCategoryServiceTypeAsync = createAsyncThunk("dashboard/getTrendingCategoryServiceType", async (data) => {
    const response = await getTrendingCategoryServiceType(data);
    return response;
});
// export const getProductListBySupplierAsync = createAsyncThunk("dashboard/productlisting", async (data) => {
//     console.log("function in slice")
//     const response = await getProductListBySupplier(data);
//     return response;
// });
export const getTrendingProductAsync = createAsyncThunk("dashboard/getTrendingProduct", async (data) => {
    const response = await getTrendingProduct(data.postData,data.tokenData);
    return response;
});
export const getCategoryWithProductsAsync = createAsyncThunk("dashboard/getCategoryWithProducts", async (data2) => {
    const response = await getCategoryWithProducts(data2.postData, data2.tokenData);
    return response;
});
export const getCouponByServiceIdAsync = createAsyncThunk("dashboard/getCouponByServiceId", async (data) => {
    const response = await getCouponByServiceId(data);
    return response;
});
export const getAllProductsByCategoryIdAsync=createAsyncThunk("dashboard/getAllProductsByCategoryId",async(data3)=>{
    const response=await getAllProductsByCategoryId(data3.postData,data3.tokenData);
    return response;
});
export const getProductsRetailByIdAsync=createAsyncThunk("dashboard/getProductsRetailById", async(data4)=>{
    const response=await getProductsRetailById(data4.postData,data4.tokenData);
    return response;
})
export const getCategoryBySellerIdAsync=createAsyncThunk("dashboard/getCategoryBySellerId", async(data)=>{
    const response=await getCategoryBySellerId(data.postData,data.tokenData);
    return response;
});
export const getAllOrderByUserAsync=createAsyncThunk("dashboard/getAllOrderByUser", async(data)=>{
    const response=await getAllOrderByUser(data.tokenData);
    return response;
});
export const getReorderByUserAsync=createAsyncThunk("dashboard/getReorderByUser", async(data)=>{
    const response=await getReorderByUser(data.postData,data.tokenData);
    return response;
});
export const cancelOrderByUserAsync=createAsyncThunk("dashboard/cancelOrderByUser", async(data)=>{
    const response=await cancelOrderByUser(data.postData,data.tokenData);
    return response;
});
export const ratingFeedbackByUserAsync=createAsyncThunk("dashboard/ratingFeedbackByUser", async(data)=>{
    const response=await ratingFeedbackByUser(data.postData,data.tokenData);
    return response;
});
export const getExtraProductsByCategoryIdAsync=createAsyncThunk("dashboard/getExtraProductsByCategoryId", async(data)=>{
    const response=await getExtraProductsByCategoryId(data.postData,data.tokenData);
    return response;
});

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,

    extraReducers: {

        [getBrandAsync.pending](state) {
            state.status.brand = "loading";
        },
        [getBrandAsync.fulfilled](state, { payload }) {
            state.status.brand = "idle";
            state.brand = payload;
        },
        [getBrandAsync.rejected](state, { error }) {
            state.status.brand = "idle";
            state.brand = [];
        },
        [getProductAsync.pending](state) {
            state.status.product = "loading";
        },
        [getProductAsync.fulfilled](state, { payload }) {
            state.status.product = "idle";
            state.product = payload;
        },
        [getProductAsync.rejected](state, { error }) {
            state.status.product = "idle";
            state.product = [];
        },
        [getOfferAsync.pending](state) {
            state.status.offer = "loading";
        },
        [getOfferAsync.fulfilled](state, { payload }) {
            state.status.offer = "idle";
            state.offer = payload;
        },
        [getOfferAsync.rejected](state, { error }) {
            state.status.offer = "idle";
            state.offer = [];
        },
        [getDashboardAsync.pending](state) {
            state.status.dashboardDetails = "loading";
        },
        [getDashboardAsync.fulfilled](state, { payload }) {
            state.status.dashboardDetails = "idle";
            state.dashboardDetails = payload;
        },
        [getDashboardAsync.rejected](state, { error }) {
            state.status.dashboardDetails = "idle";
            state.dashboardDetails = [];
        },
        [getFoodDataAsync.pending](state) {
            state.status.foodData = "loading";
        },
        [getFoodDataAsync.fulfilled](state, { payload }) {
            state.status.foodData = "idle";
            state.foodData = payload;
        },
        [getFoodDataAsync.rejected](state, { error }) {
            state.status.foodData = "idle";
            state.foodData = [];
        },
        [getRetailDataAsync.pending](state) {
            state.status.retailData = "loading";
        },
        [getRetailDataAsync.fulfilled](state, { payload }) {
            state.status.retailData = "idle";
            state.retailData = payload;
        },
        [getRetailDataAsync.rejected](state, { error }) {
            state.status.retailData = "idle";
            state.retailData = [];
        },
        [getFashionStoreDataAsync.pending](state) {
            state.status.fashionStore = "loading";
        },
        [getFashionStoreDataAsync.fulfilled](state, { payload }) {
            state.status.fashionStore = "idle";
            state.fashionStore = payload;
        },
        [getFashionStoreDataAsync.rejected](state, { error }) {
            state.status.fashionStore = "idle";
            state.fashionStore = [];
        },
        [getServicesDataAsync.pending](state) {
            state.status.servicesData = "loading";
        },
        [getServicesDataAsync.fulfilled](state, { payload }) {
            state.status.servicesData = "idle";
            state.servicesData = payload;
        },
        [getServicesDataAsync.rejected](state, { error }) {
            state.status.servicesData = "idle";
            state.servicesData = [];
        },
        [getHomeDataApiAsync.pending](state) {
            state.status.homeData = "loading";
        },
        [getHomeDataApiAsync.fulfilled](state, { payload }) {
            state.status.homeData = "idle";
            state.homeData = payload;
        },
        [getHomeDataApiAsync.rejected](state, { error }) {
            state.status.homeData = "idle";
            state.homeData = [];
        },
        [getCategoryApiAsync.pending](state) {
            state.status.categoryData = "loading";
        },
        [getCategoryApiAsync.fulfilled](state, { payload }) {
            state.status.categoryData = "idle";
            state.categoryData = payload;
        },
        [getCategoryApiAsync.rejected](state, { error }) {
            state.status.categoryData = "idle";
            state.categoryData = [];
        },
        [getTrendingNearBySellerAsync.pending](state) {
            state.status.trendingNearby = "loading";
        },
        [getTrendingNearBySellerAsync.fulfilled](state, { payload }) {
            state.status.trendingNearby = "idle";
            state.trendingNearby = payload;
        },
        [getTrendingNearBySellerAsync.rejected](state, { error }) {
            state.status.trendingNearby = "idle";
            state.trendingNearby = [];
        },
        [getTrendingNearBySellerReservationAsync.pending](state) {
            state.status.trendingReservationNearby = "loading";
        },
        [getTrendingNearBySellerReservationAsync.fulfilled](state, { payload }) {
            state.status.trendingReservationNearby = "idle";
            state.trendingReservationNearby = payload;
        },
        [getTrendingNearBySellerReservationAsync.rejected](state, { error }) {
            state.status.trendingReservationNearby = "idle";
            state.trendingReservationNearby = [];
        },
        [getProductListAsync.pending](state) {
            state.status.productList = "loading";
        },
        [getProductListAsync.fulfilled](state, { payload }) {
            state.status.productList = "idle";
            state.productList = payload;
            state.inventoryProduct = payload?.data?.payload?.products;
        },
        [getProductListAsync.rejected](state, { error }) {
            state.status.productList = "idle";
            state.productList = [];
        },
        [getBookingSlotAsync.pending](state) {
            state.status.bookingSlot = "loading";
        },
        [getBookingSlotAsync.fulfilled](state, { payload }) {
            state.status.bookingSlot = "idle";
            state.bookingSlot = payload;
        },
        [getBookingSlotAsync.rejected](state, { error }) {
            state.status.bookingSlot = "idle";
            state.bookingSlot = [];
        },
        [createBookingApiAsync.pending](state) {
            state.status.createBooking = "loading";
        },
        [createBookingApiAsync.fulfilled](state, { payload }) {
            state.status.createBooking = "idle";
            state.createBooking = payload;
        },
        [createBookingApiAsync.rejected](state, { error }) {
            state.status.createBooking = "idle";
            state.createBooking = [];
        },
        [getMenuApiAsync.pending](state) {
            state.status.menuList = "loading";
        },
        [getMenuApiAsync.fulfilled](state, { payload }) {
            state.status.menuList = "idle";
            state.menuList = payload;
        },
        [getMenuApiAsync.rejected](state, { error }) {
            state.status.menuList = "idle";
            state.menuList = [];
        },
        [getMenuProductApiAsync.pending](state) {
            state.status.inventoryProduct = "loading";
        },
        [getMenuProductApiAsync.fulfilled](state, { payload }) {
            state.status.inventoryProduct = "idle";
            state.inventoryProduct = payload?.data?.payload?.data;
        },
        [getMenuProductApiAsync.rejected](state, { error }) {
            state.status.inventoryProduct = "idle";
            state.inventoryProduct = [];
        },
        [getProductByIdApiAsync.pending](state) {
            state.status.productData = "loading";
        },
        [getProductByIdApiAsync.fulfilled](state, { payload }) {
            state.status.productData = "idle";
            state.productData = payload;
        },
        [getProductByIdApiAsync.rejected](state, { error }) {
            state.status.productData = "idle";
            state.productData = [];
        },
        [getSellersByCategoryIdAsync.pending](state) {
            state.status.sellerByCategory = "loading";
        },
        [getSellersByCategoryIdAsync.fulfilled](state, { payload }) {
            state.status.sellerByCategory = "idle";
            state.sellerByCategory = payload;
        },
        [getSellersByCategoryIdAsync.rejected](state, { error }) {
            state.status.sellerByCategory = "idle";
            state.sellerByCategory = [];
        },
        [getBannerByServiceTypeAsync.pending](state) {
            state.status.bannerData = "loading";
        },
        [getBannerByServiceTypeAsync.fulfilled](state, { payload }) {
            state.status.bannerData = "idle";
            state.bannerData = payload;
        },
        [getBannerByServiceTypeAsync.rejected](state, { error }) {
            state.status.bannerData = "idle";
            state.bannerData = [];
        },
        [getTrendingCategoryServiceTypeAsync.pending](state) {
            state.status.trendingCategoryData = "loading";
        },
        [getTrendingCategoryServiceTypeAsync.fulfilled](state, { payload }) {
            state.status.trendingCategoryData = "idle";
            state.trendingCategoryData = payload;
        },
        [getTrendingCategoryServiceTypeAsync.rejected](state, { error }) {
            state.status.trendingCategoryData = "idle";
            state.trendingCategoryData = [];
        },
        [getTrendingProductAsync.pending](state) {
            state.status.trendingProduct = "loading";
        },
        [getTrendingProductAsync.fulfilled](state, { payload }) {
            state.status.trendingProduct = "idle";
            state.trendingProduct = payload;
        },
        [getTrendingProductAsync.rejected](state, { error }) {
            state.status.trendingProduct = "idle";
            state.trendingProduct = [];
        },
        [getCategoryWithProductsAsync.pending](state) {
            state.status.categoryProduct = "loading";
        },
        [getCategoryWithProductsAsync.fulfilled](state, { payload }) {
            state.status.categoryProduct = "idle";
            state.categoryProduct = payload;
        },
        [getCategoryWithProductsAsync.rejected](state, { error }) {
            state.status.categoryProduct = "idle";
            state.categoryProduct = [];
        },
        [getAllProductsByCategoryIdAsync.pending](state){
            state.status.allProducts="loading"
        },
        [getAllProductsByCategoryIdAsync.fulfilled](state,{payload}){
            state.status.allProducts="idle";
            state.allProducts=payload;
        },
        [getAllProductsByCategoryIdAsync.rejected](state){
            state.status.allProducts="idle";
            state.allProducts=[];
        },
        [getProductsRetailByIdAsync.pending](state){
            state.status.retailProduct="loading"
        },
        [getProductsRetailByIdAsync.fulfilled](state,{payload}){
            state.status.retailProduct="idle";
            state.retailProduct=payload;
        },
        [getProductsRetailByIdAsync.rejected](state){
            state.status.retailProduct="idle";
            state.retailProduct=[];
        },
        [getCategoryBySellerIdAsync.pending](state){
            state.status.sellerProducts="loading"
        },
        [getCategoryBySellerIdAsync.fulfilled](state,{payload}){
            state.status.sellerProducts="idle";
            state.sellerProducts=payload;
        },
        [getCategoryBySellerIdAsync.rejected](state){
            state.status.sellerProducts="idle";
            state.sellerProducts=[];
        },
        [getAllOrderByUserAsync.pending](state){
            state.status.allOrderByUser="loading"
        },
        [getAllOrderByUserAsync.fulfilled](state,{payload}){
            state.status.allOrderByUser="idle";
            state.allOrderByUser=payload;
        },
        [getAllOrderByUserAsync.rejected](state){
            state.status.allOrderByUser="idle";
            state.allOrderByUser=[];
        },
        [getReorderByUserAsync.pending](state){
            state.status.reorderProducts="loading"
        },
        [getReorderByUserAsync.fulfilled](state,{payload}){
            state.status.reorderProducts="idle";
            state.reorderProducts=payload;
        },
        [getReorderByUserAsync.rejected](state){
            state.status.reorderProducts="idle";
            state.reorderProducts=[];
        },
        [cancelOrderByUserAsync.pending](state){
            state.status.cancelProducts="loading"
        },
        [cancelOrderByUserAsync.fulfilled](state,{payload}){
            state.status.cancelProducts="idle";
            state.cancelProducts=payload;
        },
        [cancelOrderByUserAsync.rejected](state){
            state.status.cancelProducts="idle";
            state.cancelProducts=[];
        },
        [ratingFeedbackByUserAsync.pending](state){
            state.status.ratingFeedback="loading"
        },
        [ratingFeedbackByUserAsync.fulfilled](state,{payload}){
            state.status.ratingFeedback="idle";
            state.ratingFeedback=payload;
        },
        [ratingFeedbackByUserAsync.rejected](state){
            state.status.ratingFeedback="idle";
            state.ratingFeedback=[];
        },
        [getCouponByServiceIdAsync.pending](state){
            state.status.coupenService="loading"
        },
        [getCouponByServiceIdAsync.fulfilled](state,{payload}){
            state.status.coupenService="idle";
            state.coupenService=payload;
        },
        [getCouponByServiceIdAsync.rejected](state){
            state.status.coupenService="idle";
            state.coupenService=[];
        },
        [getExtraProductsByCategoryIdAsync.pending](state){
            state.status.extraProducts="loading"
        },
        [getExtraProductsByCategoryIdAsync.fulfilled](state,{payload}){
            state.status.extraProducts="idle";
            state.extraProducts=payload;
        },
        [getExtraProductsByCategoryIdAsync.rejected](state){
            state.status.extraProducts="idle";
            state.extraProducts=[];
        }
    },
});

export const selectBrandData = (state) => state.dashboard.brand;
export const selectProductData = (state) => state.dashboard.product;
export const selectOfferData = (state) => state.dashboard.offer;
export const selectDashboardData = (state) => state.dashboard.dashboardDetails;
export const selectFoodData = (state) => state.dashboard.foodData;
export const selectRetailData = (state) => state.dashboard.retailData;
export const selectFashionStoreData = (state) => state.dashboard.fashionStore;
export const selectServiceData = (state) => state.dashboard.servicesData;
export const selectHomeData = (state) => state.dashboard.homeData;
export const selectCategoryData = (state) => state.dashboard.categoryData;
export const selectTrendingNearbyData = (state) => state.dashboard.trendingNearby;
export const selectTrendingReservationNearbyData = (state) => state.dashboard.trendingReservationNearby;
export const selectedProductData = (state) => state.dashboard.productList;
export const selectProductInventoryData = (state) => state.dashboard.inventoryProduct;
export const selectedSlotData = (state) => state.dashboard.bookingSlot;
export const selectedMenuList = (state) => state.dashboard.menuList;
export const selecteProductById = (state) => state.dashboard.productData;
export const selectSellerByCategoryId = (state) => state.dashboard.sellerByCategory
export const selectBannerByServiceId = (state) => state.dashboard.bannerData
export const selectCategoryByServiceId = (state) => state.dashboard.trendingCategoryData
export const selectTrendingProductData = (state) => state.dashboard.trendingProduct
export const selectCategoryProductData = (state) => state.dashboard.categoryProduct
export const selectCouponBySeller = (state) => state.dashboard.couponSeller
export const selectCouponByServiceId = (state) => state.dashboard.coupenService
export const selectAllProductsByCategoryId=(state)=>state.dashboard.allProducts
export const selectRetailProductsById=(state)=>state.dashboard.retailProduct
export const selectCategoryBySellerIdAsync=(state)=>state.dashboard.sellerProducts
export const selectAllOrderByUser=(state)=>state.dashboard.allOrderByUser
export const selectReorderByUser=(state)=>state.dashboard.reorderProducts
export const selectExtraProductsByCategoryId=(state)=>state.dashboard.extraProducts



export default dashboardSlice.reducer;