import React, { useState } from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { getAddressFromCoordinates } from './AddressMethods';

const ChangeAddress = (props) => {
    const [address, setAddress] = useState("")

    const handleChangeAddress = async (address) => {
        setAddress(address)
        // setZipCode("")
        // setCountryValue("")
        // setCityValue("")
        // setStateValue("")
        // setApt("")
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng =>
                getAddressFromCoordinates(latLng.lat, latLng.lng)
                    .then(data => {
                        console.log(data, "data of address")
                        for (var i = 0; i < data.address_components.length; i++) {
                            //   if (data.address_components[i].types[0] == 'postal_code') {
                            //     setZipCode(data?.address_components?.[i]?.long_name);
                            //   }
                            //   if (data.address_components[i].types[0] == 'country') {
                            //     setCountryValue(data?.address_components?.[i]?.long_name);
                            //   }
                            //   if (data.address_components[i].types[0] == 'administrative_area_level_2' ||
                            //     data.address_components[i].types[0] == 'administrative_area_level_3' ||
                            //     data.address_components[i].types[0] == 'locality'
                            //   ) {
                            //     setCityValue(data?.address_components?.[i]?.long_name);
                            //   }
                            //   if (data.address_components[i].types[0] == 'administrative_area_level_1') {
                            //     setStateValue(data?.address_components?.[i]?.long_name);
                            //   }
                            //   if (data.address_components[i].types[0] == 'street_number') {
                            //     setApt(data.address_components?.[i]?.long_name);
                            //   }
                        }
                    })
                    .catch(err => console.log('error getting address', err))
            )
            .catch(error => console.error('Error', error));
    };

    const handleSelect = async value => {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        // console.log(ll, "lat long by change")
        setAddress(value)
        props.details(ll)
        props.closeModal()
    };
    return (
        <div className="modal-content">
            <div className="modal-header address-mdlheader">
                <h5>Change Address</h5>
                <button type="button" className="close" aria-label="Close" onClick={() => { props.closeModal() }}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body address-mdlbody">
                <div className="personal-infomain">
                    <label htmlFor="p2">Current Addreess</label>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChangeAddress}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Street Address',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>
            </div>
        </div>
    )
}

export default ChangeAddress