import React, { useEffect, useState } from 'react'
import {
    Food1, Food2, Food3, Food4, Food5, Food6, Food7, FoodItem1, FoodItem2, FoodItem3, FoodItem4, FoodSlider, ProductSt8,
    starCircleImg, clockImg, deliveryParcelImg, TrendingFood1
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { getFoodDataAsync, selectFoodData, getCategoryApiAsync, selectCategoryData, getTrendingNearBySellerAsync, selectTrendingNearbyData, getBannerByServiceTypeAsync, getTrendingCategoryServiceTypeAsync, selectBannerByServiceId, selectCategoryByServiceId } from "./dashboardSlice"
import { Link, useHistory } from 'react-router-dom';
import { selectLoginAuth } from '../auth/authSlice';
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { unwrapResult } from '@reduxjs/toolkit';
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle";

const FoodDeliveryTab = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const { status } = useSelector(state => state.dashboard)
    const [pagination, setPagination] = useState(PaginationSetting)
    const [page, setPage] = useState(1);
    const foodData = useSelector(selectFoodData);
    const auth = useSelector(selectLoginAuth)
    const categoryData = useSelector(selectCategoryData)
    const bannerData = useSelector(selectBannerByServiceId)
    const trendingCategoryData = useSelector(selectCategoryByServiceId)

    const trendingSellerData = useSelector(selectTrendingNearbyData)
    console.log(trendingSellerData?.data?.payload?.data, "trending data")
    let megaOffer = [1, 2, 3, 4]
    const handleProduct = (data) => {
        history.push({ pathname: `/home-restaurent/${data}`, })
    }
    const bannerSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(bannerData?.data?.payload?.data?.length)}`}</div>
    };

    useEffect(() => {
        let data = {
            "postData": 1,
            "tokenData": auth?.payload?.token
        }
        dispatch(getFoodDataAsync())
        dispatch(getCategoryApiAsync(1))
        dispatch(getBannerByServiceTypeAsync(data))
        dispatch(getTrendingCategoryServiceTypeAsync(1))
    }, [dispatch])
    useEffect(() => {
        let params = {
            page: page,
            limit: pagination.recordsPerPage,
            service_id: 1,
            lat: 30.7355,
            serviceType: 1,
            long: 76.7121
        }
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                console.log(obj, "obj")
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
            })
    }, [page])

    return (
        <>
            <div id="tab1" className="active">
                {status.categoryData === "loading" || status.bannerData === "loading" || status.trendingCategoryData === "loading" || status.trendingNearby === "loading" ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className="home-tab1prt">
                        <div className="search-barhm">
                            <form action="#" method="POST">
                                <input type="search" placeholder="Search here" onFocus={() => history.push('/browse/1')} />
                            </form>
                        </div>

                        <div className="retail-part retail-part2">
                            {
                                categoryData?.data?.payload?.data?.map((val, index) => {
                                    return (
                                        <div className="retail-item" key={index} onClick={() => history.push(`/sellers/${val?.id}`)}>
                                            <span>
                                                <img src={val?.image} alt="" />
                                            </span>
                                            <p>{val?.name}</p>
                                        </div>
                                    )
                                })
                            }
                            {categoryData?.data?.payload?.data?.length > 8 ?
                                <div className="retail-item">
                                    <Link to="/category-list/2">
                                        <img src={ProductSt8} alt="" />
                                    </Link>
                                    <p>More</p>
                                </div> : null
                            }
                        </div>

                        <div className="banner-slider trslider-part" id="bannerSlider">
                        <SliderWrapper>
                            <Slider {...bannerSettings}>
                                {
                                    bannerData?.data?.payload?.data && bannerData?.data?.payload?.data?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <img src={val?.path} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </SliderWrapper>
                        </div>
                        <div className="retail-mainprt">
                            <div className="row">
                                {
                                    trendingCategoryData?.data?.payload?.map((val, index) => {
                                        return (
                                            <div className="col-6" key={index} onClick={() => history.push(`/sellers/${val?.id}`)}>
                                                <div className="retail-product">
                                                    <img src={val?.image} alt="" />
                                                    <span>{val?.description}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {trendingSellerData?.data?.payload?.data?.length > 0 &&
                            <div className="delivery-parthm">
                                <div className="trending-title pt-5">
                                    <h2>Trending</h2>
                                </div>
                                {trendingSellerData?.data?.payload?.data?.map((value, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => handleProduct(value?.unique_uuid)}>
                                                <img className="delv-prd" src={value?.user_profiles?.profile_photo} alt="" />
                                                <h4>{value?.user_profiles?.firstname}</h4>
                                                <ul>
                                                    <li><img src={starCircleImg} alt="starCircleImg" />{value?.sellerRatings}</li>
                                                    <li><img src={clockImg} alt="clockImg" />{value?.distance}</li>
                                                    <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value?.deliveryFee} Delivery fee</li>
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>
                        }
                        {pagination.totalRecords > pagination.recordsPerPage &&
                            <Box py={3} display="flex" justifyContent="center">
                                <Pagination
                                    key={page}
                                    count={pagination.totalPages || 0}
                                    color="secondary"
                                    variant="outlined"
                                    onChange={(e, value) => setPage(value)}
                                    defaultPage={page}
                                />
                            </Box>
                        }
                    </div>}
            </div>
        </>
    )
}

export default FoodDeliveryTab