import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import { leftArrowImg, review1Img, attach1Img, attach2Img, attach3Img, attach4Img, ratingImg, logoImg } from "../../../utilities/images";
import ReactStars from 'react-stars'

const ProductReviewEdit = (props) => {
    const history = useHistory();
    const [note, setNote] = useState("Thank you for your help and quick delivery which enable our customer to meet his deadline – excellent service.");
    // const [key, setKey] = useState(Math.random());
    // const [modalDetail, setModalDetail] = useState({
    //     show: false,
    //     title: "",
    //     flag: ""
    // });
    // const handleOnCloseModal = () => {
    //     setModalDetail({
    //         show: false,
    //         title: "",
    //         flag: ""
    //     });
    //     setKey(Math.random());
    // }

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };
    const ratingChanged = (newRating) => {
        console.log(newRating)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <h4><Link to='/product-review-available-two'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Product review</h4>
            </div>

            <div className="main-content">
                <div className="product-review-area">
                    <div className="rate-upper">
                        <h2>Rate your experience</h2>
                        <p>Are you seticfy with product?</p>
                    </div>

                    <div className="rating-partex">
                        <div className="rating-leftex">
                            <img src={review1Img} alt="review1Img" />
                        </div>
                        <div className="rating-rightex">
                            <h4>Marlboro Touch</h4>
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    value={3}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Product Quality</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    value={4}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Velue of Money</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    value={4}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="additional-box">
                        <h4>Write your additional feedback</h4>
                        <form action="#" method="POST"  >
                            <textarea name="" id="" cols="30" rows="5" value={note} onChange={(e) => { onChangeInput(e, 'note') }}>Thank you for your help and quick delivery which enable our customer to meet his deadline – excellent service.</textarea>
                        </form>
                    </div>

                    <div className="attach-part">
                        <h4>Attach image</h4>
                        <div className="attach-img">
                            <img src={attach1Img} alt="attach1Img" />
                            <img src={attach2Img} alt="attach2Img" />
                            <img src={attach3Img} alt="attach3Img" />
                            <img src={attach4Img} alt="attach4Img" />
                        </div>
                    </div>

                    <div className="submit-btnrating">
                        <Link to='/product-review-available'>Submit</Link>
                    </div>
                </div>
            </div>
        </>
    );
}


export default ProductReviewEdit;
