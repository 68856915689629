import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const CustomModal = (props) => {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
    if (props.onCloseModal) {
      props.onCloseModal();
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={props?.backdrop}
      keyboard={false}
      centered
      size={props.size}
      dialogClassName={props.isRightSideModal ? "modal-dialog-slideout" : ""}
      className={props?.isRightSideModal ? 'pe-0' : props?.isHomeFoodModal ? 'main_menu' : props?.mediumWidth ? 'mediumwidth_modal' : props?.darkBackground ? 'darkmediumwidth_modal' : props?.walletModal ? 'wallet_modal' : ""}
      animation={true}
    >
      {(props.title || props.showCloseBtn) ?
        <>{
          props.showCloseBtn ?
            <Modal.Header>
              {props?.header}
              {props.title ?
                <Modal.Title>{props.title}</Modal.Title>
                :
                ""
              }
            </Modal.Header> :
            <Modal.Header showCloseBtn>
              {props?.header}
              {props.title ?
                <Modal.Title>{props.title}</Modal.Title>
                :
                ""
              }
            </Modal.Header>
        }

        </>

        :
        ""
      }
      <Modal.Body className={props.isRightSideModal ? "p-0" : ""}>
        {props.child}
      </Modal.Body>

      {props.footerContent ?
        <Modal.Footer className={props.footerClasses}>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close Modal
          </Button> */}
          {props.footerContent}
        </Modal.Footer>
        :
        ""
      }
    </Modal>
  );
};

export default CustomModal;