import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { arrowBlueImg, favorableLinebarImg, prdMdl1Img, sldRolling3Img } from "../../../utilities/images";
import getCart from "../../apis/store.json"
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getExtraProductsByCategoryIdAsync, getProductsRetailByIdAsync, selectExtraProductsByCategoryId, selectRetailProductsById } from "../dashboard/dashboardSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { createCartApiAsync, getCartApiAsync, selectCartData } from '../cart/cartSlice'
import { unwrapResult } from "@reduxjs/toolkit";

const Add = (props) => {
    const history = useHistory();
    const retailProductData = useSelector(selectRetailProductsById)
    const [productId, setProductId] = useState("")
    const { id, sellerId } = useParams();
    const toastId = React.useRef(null)
    const { status } = useSelector(state => state.dashboard)
    const cartData = useSelector(selectCartData)
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const [cartId, setCartId] = useState("")
    const [Cigarettes, setCigarettes] = useState([]);
    const [count, setCount] = useState(1)
    const recommendedProducts = useSelector(selectExtraProductsByCategoryId)

    console.log(retailProductData, "retail product data")

    console.log(cartData?.data?.payload, "cart data response")
    const incrementCount = (data) => {
        setCount(count + 1)
    }
    const decrementCount = (data) => {
        setCount(count - 1)
    }
    const handleChange = (e) => {
        setCount(e.target.value)
    }
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    useEffect(() => {
        setCigarettes(getCart.Cigarettes)
    }, [])

    let objProduct = {
        "postData": {
            "seller_id": props.sellerId,
            "product_id": retailProductData?.data?.payload?.data?.id,
            "qty": count,
            "service_id": 2
        },
        "tokenData": auth?.payload?.token
    }

    const handleCart = async () => {
        if (cartData?.data?.payload) {
            if ((cartData?.data?.payload?.seller_id !== props.sellerId) && (cartData?.data?.payload?.service_id !== 2)) {
                props.cartExist("cart_exist")
            }
        } else {
            dispatch(createCartApiAsync(objProduct))
                .then(unwrapResult)
                .then(async (obj) => {
                    // if (!toast.isActive(toastId.current)){
                    // toastId.current = toast.success(obj?.data?.msg);
                    // }
                    dispatch(getCartApiAsync(auth?.payload?.token))
                    setCartId(obj?.data?.payload?.id)
                    props.closeModal()
                }
                )
                .catch((obj) => {
                })
        }

    }

    let data4 = {
        "postData": props.productId,
        "tokenData": auth?.payload?.token
    }
    let recommendData = {
        "postData": {
            category_id: id,
            seller_id: sellerId,
            service_id: 2,
        },
        "tokenData": auth?.payload?.token
    }

    useEffect(() => {
        dispatch(getProductsRetailByIdAsync(data4))
        dispatch(getExtraProductsByCategoryIdAsync(recommendData))
        dispatch(getCartApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {

            }
            )
            .catch((obj) => {
            })
    }, [])

    const closeModal = () => {
        props.closeModal()
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {
                status.retailProduct === "loading" ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className="modal-dialog modal-dialog-centered" role="document">
                       
                        <div className="modal-content prdmdl-content">
                            <div className="modal-body">
                                <div className="product-mdlitem">
                                    <div className="productImgae">
                                        <img src={retailProductData?.data?.payload?.data?.image} alt="prdMdl1Img" />
                                    </div>
                                    <h4>{retailProductData?.data?.payload?.data?.name}</h4>
                                    {/* <p>Pack</p> */}
                                    <h6>Price: ${retailProductData?.data?.payload?.data?.price}</h6>
                                </div>

                                <div className="product-mdlcnt">
                                    <p>{retailProductData?.data?.payload?.data?.description}</p>
                                    <ul>
                                        {retailProductData?.data?.payload?.data?.brand ? <li>Brand - <strong>{retailProductData?.data?.payload?.data?.brand?.name}</strong></li> : <></>}
                                        {retailProductData?.data?.payload?.data?.product_type ? <li>Type - <strong>{retailProductData?.data?.payload?.data?.product_type?.name}</strong></li> : <></>}
                                        {retailProductData?.data?.payload?.data?.blend ? <li>Blend - <strong>{retailProductData?.data?.payload?.data?.blend?.name}</strong></li> : <></>}
                                        {retailProductData?.data?.payload?.data?.size ? <li>Size - <strong>{retailProductData?.data?.payload?.data?.size?.name}</strong></li> : <></>}
                                        {retailProductData?.data?.payload?.data?.qty ? <li>Pack Quantity - <strong>{retailProductData?.data?.payload?.data?.qty?.name}</strong></li> : <></>}

                                    </ul>
                                </div>

                                {retailProductData?.data?.payload?.data?.product_ingredient.length > 0 &&
                                    <div className="scan-accordian-area">
                                        <div className="scan-accordian-upper">
                                            {retailProductData?.data?.payload?.totalHealthRating && <h2>{retailProductData?.data?.payload?.totalHealthRating} <small>score</small></h2>}
                                            {retailProductData?.data?.payload?.data?.name && <h4>{retailProductData?.data?.payload?.data?.name}</h4>}
                                        </div>
                                        <div className="data-avlfr">
                                            <p>Data availability</p>
                                            <h4>Fair</h4>
                                        </div>

                                        {retailProductData?.data?.payload?.data?.product_ingredient?.length > 0 ?
                                            <div className="scan-accortitl">
                                                <h6>Ingredient information</h6>
                                            </div>
                                            : <></>
                                        }

                                        <div className="scan-accordian-part">
                                            <div className="ziehharmonika">
                                                {retailProductData?.data?.payload?.data?.product_ingredient?.map((val, i) => {
                                                    return (
                                                        <>
                                                            <h3 onClick={() => setProductId(productId === val?.product_id ? "" : val?.product_id)}><span>{val?.ingredient?.health_rating}</span>{val?.ingredient?.name}</h3>
                                                            {
                                                                (val?.product_id === productId) ?
                                                                    <div>
                                                                        <p>{val?.ingredient?.description}</p>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                        <div className="scan-accorbtm">
                                            <div className="scan-number">
                                                <span>1</span>
                                                <span className="numb-sbg2">2</span>
                                                <span className="numb-sbg3">3</span>
                                                <span className="numb-sbg4">4</span>
                                                <span className="numb-sbg5">5</span>
                                                <span className="numb-sbg6">6</span>
                                                <span className="numb-sbg7">7</span>
                                                <span className="numb-sbg8">8</span>
                                                <span className="numb-sbg9">9</span>
                                                <span className="numb-sbg10">10</span>
                                            </div>

                                            <div className="favroble-bar">
                                                <span>Favorable</span>
                                                <img src={favorableLinebarImg} alt="favorableLinebarImg" />
                                                <span>Unfavorable</span>
                                            </div>
                                        </div>
                                    </div>}
                                {/* {Cigarettes && Cigarettes.length > 0 &&
                                    <div className="recomm-alternative">
                                        <div className="recomm-altrtitle">
                                            <h2>Recommended Alternatives</h2>
                                        </div>

                                        <OwlCarousel items={2.5}
                                            className="owl-theme main-carousel2"
                                            nav={false}
                                            loop={true}
                                            dots={false}
                                            autoplay={true}
                                            autoplayTimeout={2000}
                                            margin={10}
                                        >

                                            {Cigarettes && Cigarettes.map((Cigarettes, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="carousel-cell" key={index}>
                                                            <div className="paddcart-item">
                                                                <div className="paddcart-inner">
                                                                    <img src={sldRolling3Img} alt="" />
                                                                    <h4>{Cigarettes.title}</h4>
                                                                    <p>{Cigarettes.titleTwo}</p>
                                                                    <h6><span>{Cigarettes.listed}</span>{Cigarettes.price}</h6>
                                                                </div>
                                                                <div className="paddcart-btn">
                                                                    <Link to="#"><i className="fas fa-plus"></i></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </OwlCarousel>
                                    </div>
                                } */}
                                <div className="product-mdlbtm modalcartbtn">
                                    <div className="prdmdl-btmleft">
                                        <div className="quantity">
                                            <form action="#" method="POST">
                                                <span className="input-number-decrement" onClick={(count > 0) ? () => decrementCount() : void (0)}><i className="fas fa-minus"></i></span>
                                                <input className="input-number" type="text" value={count} onKeyDown={blockInvalidChar} onChange={e => handleChange(e)} />
                                                <span className="input-number-increment" onClick={() => { incrementCount() }}><i className="fas fa-plus"></i></span>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="prdmdl-btmright" onClick={handleCart}>
                                        <Link to='#'>Add to cart</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
        </>
    );
}

export default Add;