import React, { useEffect, useState } from 'react'
import { RetailItem1, RetailItem2, offerCrsImg12, starCircleImg, clockImg, RetailItem3, RetailItem4, RetailProduct1, RetailProduct2, RetailProduct3, RetailProduct4, sliderOneImg, Trending1, ProductSt8 } from '../../../utilities/images'
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { selectRetailData, getRetailDataAsync, selectCategoryData, getCategoryApiAsync, getTrendingNearBySellerAsync, selectTrendingNearbyData, getBannerByServiceTypeAsync, selectBannerByServiceId, getTrendingCategoryServiceTypeAsync, selectCategoryByServiceId, getCouponByServiceIdAsync, selectCouponBySeller, selectCouponByServiceId } from "./dashboardSlice"
import { Link, useHistory } from 'react-router-dom';
import { selectLoginAuth } from '../auth/authSlice';
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { unwrapResult } from '@reduxjs/toolkit';
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle";

const RetailDelivery = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectLoginAuth);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    // const retailData = useSelector(selectRetailData);
    const categoryData = useSelector(selectCategoryData);
    const { status } = useSelector(state => state.dashboard);
    const bannerData = useSelector(selectBannerByServiceId);
    const trendingSellerData = useSelector(selectTrendingNearbyData);
    const trendingCategoryData = useSelector(selectCategoryByServiceId);
    const couponData = useSelector(selectCouponByServiceId);

    const bannerSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(bannerData?.data?.payload?.data?.length)}`}</div>
    };
    const offersSettings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true
    };
    useEffect(() => {
        let data = {
            "postData": 2,
            "tokenData": auth?.payload?.token
        }
        dispatch(getRetailDataAsync())
        dispatch(getCategoryApiAsync(1))
        dispatch(getBannerByServiceTypeAsync(data))
        dispatch(getTrendingCategoryServiceTypeAsync(2))
        dispatch(getCouponByServiceIdAsync(2))
    }, [])
    useEffect(() => {
        let params = {
            page: page,
            limit: pagination.recordsPerPage,
            service_id: 2,
            lat: 30.7355,
            serviceType: 1,
            long: 76.7121
        }
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
            })
    }, [page])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="tab1" className="tab active">
            {status.coupenService === "loading" || status.categoryData === "loading" || status.bannerData === "loading" || status.trendingCategoryData === "loading" || status.trendingNearby === "loading" ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div className="home-tab1prt">
                    <div className="search-barhm">
                        <form action="#" method="POST">
                            <input type="search" placeholder="Search here" onFocus={() => history.push('/browse/2')} />
                        </form>
                    </div>
                    {/* categories */}

                    <div className="retail-part retail-part2">
                        {
                            categoryData?.data?.payload?.data?.slice(0, 7).map((val, index) => {
                                return (
                                    <div className="retail-item" key={index} onClick={() => history.push(`/sellers/${val?.id}`)}>
                                        <span>
                                            <img src={val?.image} alt="" />
                                        </span>
                                        <p>{val?.name}</p>
                                    </div>
                                )
                            })
                        }
                        {categoryData?.data?.payload?.data?.length > 8 ?
                            <div className="retail-item">
                                <Link to="/category-list/2">
                                    <img src={ProductSt8} alt="" />
                                </Link>
                                <p>More</p>
                            </div> : null
                        }
                    </div>

                    {/* mega offer slider */}
                    <div id="bannerSlider" className="banner-slider trslider-part">
                        <SliderWrapper>
                            <Slider {...bannerSettings}>
                                {
                                    bannerData?.data?.payload?.data && bannerData?.data?.payload?.data?.map((val, index) => {
                                        return (
                                            <div className="carousel-cell" key={index}>
                                                <img src={val?.path} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </SliderWrapper>
                    </div>

                    {/* products */}
                    <div className="retail-mainprt">
                        <div className="row">
                            {
                                trendingCategoryData?.data?.payload?.map((val, index) => {
                                    return (
                                        <div className="col-6" key={index}>
                                            <div className="retail-product">
                                                <img src={val?.image} alt="" />
                                                <span>{val?.description}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* offer */}
                    {couponData?.data?.payload?.data?.length > 0 ?
                        <div className="delivery-parthm">
                            <div className="trending-title pt-5">
                                <h2>Offers</h2>
                            </div>
                            <div className='container-fluid' >
                                <SliderWrapper>
                                    <Slider {...offersSettings}>
                                    {couponData?.data?.payload?.data?.map((val, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="carousel-cell w-94">
                                                    <div className="offer-crslpart">
                                                        <div className="offer-crslitem">
                                                            <img src={val?.Image} alt="offerCrsImg1" />
                                                            <div className="offer-crslinner">
                                                                <h2>{val?.discount_percentage}% off</h2>
                                                                <p>Upto ${val?.discount_amount} <span>AD</span></p>
                                                            </div>
                                                        </div>
                                                        <i>{val?.title}</i>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                    </Slider>
                                </SliderWrapper>
                            </div>
                        </div>
                        : ""}
                    {/* retail shop */}

                    {
                        trendingSellerData?.data?.payload?.data?.length > 0 &&
                        <div className="delivery-parthm">
                            <div className="trending-title pt-5">
                                <h2>Trending</h2>
                            </div>
                            {trendingSellerData?.data?.payload?.data?.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => history.push(`/store/${value.unique_uuid}`)}>
                                            <img className="delv-prd" src={value?.user_profiles?.profile_photo} alt="" />
                                            <h4>{value?.user_profiles?.firstname}</h4>
                                            <ul>
                                                <li><img src={starCircleImg} alt="starCircleImg" />{value?.sellerRatings}</li>
                                                <li><img src={clockImg} alt="clockImg" />{value?.distance}</li>
                                                <li><img src={RetailItem2} alt="category" />{value?.category ? value?.category : "Other"}</li>
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    }
                </div>}
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </div>
    )
}

export default RetailDelivery