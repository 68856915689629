import axios from "axios";
import { toast } from "react-toastify";

export function getAllCoupons() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/coupons`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // console.error(error);
      })
  )
}
export function verifiedCouponsApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES_INVENTORY_URL}api/v1/coupons/verify-coupon?code=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
        // toast.success(response?.data?.msg)
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
        // console.error(error);
      })
  )
}