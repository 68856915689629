import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, supportProfImg, bxsCoinStackImg, galleryImg, uploadImg } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { getSubjectApiAsync, getSupportApiAsync, selectSubjectData, selectSupportData, selectUploadedUrlData, supportApiUrlAsync, supportCreateApiAsync } from "../../containers/supportRequest/SupportRequestSlice"
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone'

const SupportRequest = (props) => {
    const history = useHistory();
    const [tabData, setTabData] = useState(1)
    const auth = useSelector(selectLoginAuth)
    const [document, setDocument] = useState("")
    const [subjectValue, setSubjectValue] = useState("")
    const [description, setDescription] = useState("")
    const supportData = useSelector(selectSupportData);
    const subjectData = useSelector(selectSubjectData);
    const dispatch = useDispatch()
    const uploadedUrlData = useSelector(selectUploadedUrlData);
    let fullname = `${auth?.payload?.user_profiles?.firstname} ${auth?.payload?.user_profiles?.lastname}`

    const handleChange = (e) => {
        const imageFile = e.target.files[0];
        let params = {
            document: imageFile
        }
        setDocument(URL.createObjectURL(imageFile));
        dispatch(supportApiUrlAsync(params))
    }

    let data = {
        "postData": {
            "subject_id": Number(subjectValue),
            "email": auth?.payload?.user_profiles?.email ? auth?.payload?.user_profiles?.email : "durgadp16@gmail.com",
            "name": fullname,
            "document_url": [
                {
                    "url": uploadedUrlData?.data?.payload?.document
                }
            ],
            "notes": description
        },
        "tokenData": auth?.payload?.token
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const invalidData = await localStorage.getItem("invaliddata")
        await dispatch(supportCreateApiAsync(data))
        if (invalidData) {
            history.push('/my-support-request')
        }
        setTabData(1)
        setDocument("")
        history.push("/my-support-request");
    }

    useEffect(() => {
        dispatch(getSubjectApiAsync())
        dispatch(getSupportApiAsync(auth?.payload?.token))
    }, [tabData]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/help-center'><img src={leftArrowImg} alt="leftArrowImg" /></Link> My support request</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="support-reqtab">
                    <div className="tabs">
                        <div className="tab-upper">
                            <ul className="tab-links">
                                <li className={(tabData === 1) ? 'active' : ''} onClick={() => setTabData(1)}><Link to="#">My support ({supportData?.data?.payload?.total})</Link></li>
                                <li className={(tabData === 2) ? 'active' : ''} onClick={() => setTabData(2)}><Link to="#">Support</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {(tabData === 1) && (
                                <>
                                    <div id="tab1" className="tab active">
                                        <>
                                            {supportData?.data?.payload?.data?.length ?
                                                <>
                                                    {supportData?.data?.payload?.data?.map(
                                                        (value, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div>
                                                                        <div className="support-rewtab1">
                                                                            <h2 style={{ cursor: "pointer" }} onClick={() => { history.push({ pathname: 'my-support-request-details', state: value?.id }) }}>
                                                                                #{value?.track_number} <span>{value?.status?.name}</span>
                                                                            </h2>
                                                                            <div className="support-reqinner">
                                                                                {/* <span>{value?.subject?.name}</span> */}
                                                                                <h4>
                                                                                    <strong>{value?.subject?.name}</strong>
                                                                                </h4>
                                                                                <p>{value?.notes}</p>
                                                                            </div>
                                                                            {
                                                                                value?.support_comments?.length > 0 ?
                                                                                    value?.support_comments?.map((value, index) => {
                                                                                        return (
                                                                                            <div className="support-reqbtm" key={index}>
                                                                                                <span>Last Respond</span>
                                                                                                <div className="support-reqbtmitem">
                                                                                                    <img src={value?.user_id_details?.profile_photo ? value?.user_id_details?.profile_photo : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                                                                                                    <div className="support-reqbtmcnt">
                                                                                                        <h4>{value?.user_id_details?.firstname}{" "}{value?.user_id_details?.lastname}</h4>
                                                                                                        <p>
                                                                                                            {moment(value?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(value?.created_at).format('hh:mm A')}
                                                                                                            {value?.lastResponseData?.time}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    : ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                                </> : ""}
                                        </>
                                    </div>
                                </>
                            )}

                            {(tabData === 2) && (
                                <>
                                    <div>
                                        <div className="support-rewtab2">
                                            <div className="more-help-part">
                                                <div className="more-help-upper">
                                                    <h4>Open new ticket</h4>
                                                    <p>Our gateway is routed directly to Telstra, with Optus and Vodafone networks service.</p>
                                                </div>

                                                <div className="more-help-form">
                                                    <form action="#" method="POST" >
                                                        <input
                                                            className="user"
                                                            type="text"
                                                            value={fullname}
                                                            disabled
                                                        />
                                                        <input
                                                            className="email"
                                                            type="text"
                                                            value={auth?.payload?.user_profiles?.email ? auth?.payload?.user_profiles?.email : "abc@gmail.com"}
                                                            disabled
                                                        />
                                                        <select name="subject" defaultValue={"Select Subject"} onChange={(e) => setSubjectValue(e.target.value)}>
                                                            <option value="Select Subject" disabled>Select Subject</option>
                                                            {subjectData?.data?.payload?.data?.map((value, index) => {
                                                                return (
                                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                                )
                                                            })}
                                                        </select>

                                                        <textarea
                                                            name="description"
                                                            cols="30"
                                                            rows="6"
                                                            placeholder="Write here"
                                                            value={description}
                                                            onChange={e => setDescription(e.target.value)}
                                                        ></textarea>
                                                        <div className="upload-btn-wrapper upload-btn2 upload-btn-wrapper2">
                                                            <button className="filebtn">
                                                                <span className="upload-cnt">
                                                                </span>
                                                                <span className="upload-item">
                                                                    <img src={document ? document : galleryImg} alt="" className={document ? "support_img" : ""} />
                                                                    <img src={document ? "" : uploadImg} alt="" className={document ? "support_img" : ""} />
                                                                    <span>{document ? "" : "Upload your files here"}</span>
                                                                </span>
                                                            </button>
                                                            <input type="file" name="myfile" onChange={e => handleChange(e)} />
                                                        </div>
                                                        <button type="button" className="submit-btn" onClick={e => handleSubmit(e)}>Submit</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SupportRequest;