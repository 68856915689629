import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { bagImg, bxsCoinStackImg, cancelOrderImg, leftArrowImg, orderOneImg, ratingImg, veirfy2Img } from "../../../utilities/images";
import { getOrderAsync, selectOrderData } from "./ordersSlice";
import { useDispatch, useSelector } from "react-redux";
import { cancelOrderByUserAsync, getAllOrderByUserAsync, ratingFeedbackByUserAsync, selectAllOrderByUser, selectCancelOrderByUser } from "../dashboard/dashboardSlice";
import { selectLoginAuth } from "../auth/authSlice";
import moment from "moment-timezone";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import ReactStars from 'react-stars'

const Orders = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const allorders = useSelector(selectOrderData)
    const selectAllOrder = useSelector(selectAllOrderByUser)
    console.log(selectAllOrder, 'selectAllOrder');
    const toastId = React.useRef(null)
    const [rating, setRating] = useState("")
    const [description, setDescription] = useState("")
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    let data = {
        "tokenData": auth?.payload?.token
    }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setRating("")
        setDescription("")
        setKey(Math.random());
    }

    const cancelOrderHandle = async (id) => {
        const cancelData = {
            "postData": id,
            "tokenData": auth?.payload?.token
        }
        dispatch(cancelOrderByUserAsync(cancelData))
            .then(unwrapResult)
            .then(async (obj) => {
                toast.success(obj?.data?.msg)
                dispatch(getAllOrderByUserAsync(data))
                handleOnCloseModal()
            }
            )
            .catch((obj) => {
            })

    }

    const submitRatingHandler = (id) => {
        if (!rating) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select the rating")
            }
        }
        else if (!description) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please write the feedback")
            }
        }
        else {
            const ratingData = {
                "postData": {
                    "order_id": id,
                    "feedback": description,
                    "rating": rating
                },
                "tokenData": auth?.payload?.token
            }
            dispatch(ratingFeedbackByUserAsync(ratingData))
                .then(unwrapResult)
                .then(async (obj) => {
                    console.log(obj, "oreder response")
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                    setRating("")
                    setDescription("")
                    setModalDetail({
                        show: false,
                        title: "",
                        flag: ""
                    });
                    setKey(Math.random());
                }
                )
                .catch((obj) => {
                })
        }
    }

    useEffect(() => {
        // dispatch(getOrderAsync())
        dispatch(getAllOrderByUserAsync(data))
    }, [dispatch])

    const renderRatingModelHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-header address-mdlheader">
                    <h5><strong>Rate Your Order</strong></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { handleOnCloseModal() }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <br />
                </div>
                <div className="modal-body address-mdlbody pt-0">
                    <div className="taxes-mdlbox-cnt">
                        <p className="ratingStar">Give rating and review your order.</p>
                        <div className="giveStar">
                            <ReactStars
                                count={5}
                                onChange={(e) => setRating(e)}
                                size={45}
                                value={rating}
                                color2={'#FCBA30'}
                                activeColor={"#ffd700"}
                            />
                        </div>

                    </div>
                    <div className="taxes-mdlbox-cnt">
                        <h4>Give your review</h4>
                        <textarea className="form-control" rows="4" cols="50" value={description} onChange={(e) => setDescription(e.target.value)} style={{ height: "200px", width: "320px" }} placeholder="Write" />
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-light cancleBtn" onClick={() => handleOnCloseModal()}  >Cancel</button>
                    <button className="btn btn-primary submitBtn" onClick={() => submitRatingHandler(selectAllOrder?.data?.payload?.data[0]?.order_details[0]?.order_id)}>Submit</button>
                </div>
            </div>
        )
    }
    const renderCancelOrderHtml = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <img src={cancelOrderImg} alt="veirfy2Img" />
                            <h6>Cancel Order</h6>
                            <p>Are you sure,you want to cancel your order?</p>
                        </div>
                        <div className="modal-footer" >
                            <button className="btn cancleBtn " onClick={() => handleOnCloseModal()}  >No</button>
                            <button className="btn btn-primary submitBtn" onClick={() => cancelOrderHandle(selectAllOrder?.data?.payload?.data[0]?.order_details[0]?.order_id)}>Yes,I'm sure</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link>Orders & Reorders</h4>
                </div>
                <div className="header-right-item">
                    <button type="button"><img src={bagImg} alt="bagImg" /></button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="order-mainprt">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm noshadow">
                            <ul className="tab-links text-left pb-4">
                                <li className="active"><Link to="#tab1">Delivery</Link></li>
                                <li><Link to="#tab2">Pickup</Link></li>
                                <li><Link to="#tab2">Shipping</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {/* <!--  <div id="tab1" className="tab active">
                                <div className="browse-tab1">

                                </div>
                            </div>

                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div>
                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div> --> */}
                        </div>
                    </div>

                    {/* <div className="order-title">
                        <h2>Past orders</h2>
                    </div> */}

                    {selectAllOrder && selectAllOrder?.data?.payload?.data?.length > 0 &&
                        <div>
                            {selectAllOrder && selectAllOrder?.data?.payload?.data?.map((value, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="order-part">
                                            <div className="order-item">
                                                {/* <img style={{ width: "60px" }} src={value?.seller_details?.banner_image} alt="orderOneImg" /> */}
                                                <div className="order-cnt">
                                                    <h4>{value?.seller_details?.firstname}</h4>
                                                    <p>{value?.seller_details?.description}</p>
                                                    {/* <img src={value?.rating} alt="ratingImg" /> */}
                                                    <p>Total Bill: ${value?.order_details[0]?.price}</p>
                                                </div>
                                                <div className="activestatus">
                                                    <span className="pendingstatus"></span><p>{
                                                        value?.status === 0 ? "REVIEWING" :
                                                            value?.status === 1 ? "ACCEPTEDBYSELLER" :
                                                                value?.status === 2 ? "PREPAIRING" :
                                                                    value?.status === 3 ? "READYFORPICKUP" :
                                                                        value?.status === 4 ? "ASSIGNDRIVER" :
                                                                            value?.status === 5 ? "PICKUP" :
                                                                                value?.status === 6 ? "DELIVERED" :
                                                                                    value?.status === 7 ? "CANCELED" :
                                                                                        value?.status === 8 ? "REJECTEDBYSELLER" :
                                                                                            <></>
                                                    }</p>
                                                </div>
                                            </div>

                                            <div className="orderStatus">
                                                <h3>{value?.order_details[0]?.product_name}</h3>
                                                <i className="fa-regular fa-calendar-days"></i>
                                                <span>{moment(value?.created_at).format('DD MMM, YYYY,')}</span>{" "}
                                                <span>{moment(value?.created_at).format('hh:mm A')}</span>
                                            </div>
                                            <div className="order-btm">
                                                {(value?.status === 0 || value.status === 1) ? <Link onClick={() => { setModalDetail({ show: true, flag: "cancelOrder" }); setKey(Math.random()); }} to='#' className="cancleBtn">Cancel Order</Link> : ""}
                                                <Link to={(`/past-order-view/${value?.id}`)} className="rateBtn">Reorder</Link>
                                                <Link to="#" onClick={() => { setModalDetail({ show: true, flag: "rating" }); setKey(Math.random()); }} className="rateBtn">Rate Order</Link>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    }
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={modalDetail.flag === 'rating' ? false : true}
                darkBackground={modalDetail.flag === 'rating' ? true : false}
                size="lg"
                child={modalDetail.flag === 'rating' ?
                    renderRatingModelHtml() : modalDetail.flag === 'cancelOrder' ? renderCancelOrderHtml() : <></>
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    );
}

export default Orders;