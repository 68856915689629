import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import { topCategoryOneImg, breadImg, trackImg, beverageOneImg, tobaccoOneImg } from "../../../utilities/images";
import { getBrowseAsync, selectBrowseData } from "./browserSlice"
import { useSelector, useDispatch } from "react-redux";
import LeftSvg from "../../public/images/left_light.svg"
import { useDebouncedCallback } from "use-debounce";
import { selectLoginAuth } from "../auth/authSlice";
import { searchProductListAsync, selectedsearchProductListData } from "../search/SearchSlice";

const Browse = (props) => {
    const history = useHistory();
    const [tabView, setTabView] = useState(1);
    const dispatch = useDispatch();
    const {id}=useParams();
    const allCategories = useSelector(selectBrowseData);
    const [value, setValue] = useState("")
    const auth = useSelector(selectLoginAuth)

    const searchResult = useSelector(selectedsearchProductListData)

    console.log(searchResult, "search result")
    // Object.keys(props.files).length>0

    const debounced = useDebouncedCallback(
        // to memoize debouncedFunction we use useCallback hook.
        // In this case all linters work correctly
        useCallback((value) => {
            setValue(value);
        }, []),
        700,
        // The maximum time func is allowed to be delayed before it's invoked:
        { maxWait: 2000 }
    );

    console.log(value, "value data")
    let params={}    

    if(id) {params = {
        "postData":{
            "search": value,
            "service_id":id         

        },
        "token": auth?.payload?.token,
    }} else{
        params = {
            "postData":{
                "search": value    
            },
            "token": auth?.payload?.token,
        }
    }
console.log(params,'params');
    useEffect(() => {
        dispatch(searchProductListAsync(params))
    }, [])

    useEffect(() => {
        dispatch(searchProductListAsync(params))
    }, [value])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="header-searchbar">
                <div className="search-type">
                    <form action="#" method="POST">
                        <span className="backBtn_" onClick={() => history.push('/dashboard')}><img src={LeftSvg} alt="Cross" /></span>
                        <input
                            type="text"
                            placeholder="Search here"
                            onChange={(e) => debounced(e.target.value)}
                        />
                    </form>
                </div>
            </div>

            <div className="main-content">
                <div className="container">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm noshadow">
                            <ul className="tab-links text-left pt-4">
                                <li className={(tabView == 1) ? 'active' : ''} onClick={() => { setTabView(1) }}><Link to="#tab1">Delivery</Link></li>
                                <li className={(tabView == 2) ? 'active' : ''}><Link to="#tab2" onClick={() => { setTabView(2) }}>Pickup</Link></li>
                                <li className={(tabView == 3) ? 'active' : ''}><Link to="#tab3" onClick={() => { setTabView(3) }}>Shipping</Link></li>
                            </ul>
                        </div>
                    </div>

                    {searchResult?.data?.payload && Object.keys(searchResult?.data?.payload).length > 0
                        ? <>
                            {
                                searchResult?.data?.payload?.products?.data?.length > 0 ?
                                    <div className="top-category">
                                        <h4>Top Products</h4>
                                        <div className="category-part">
                                            {searchResult?.data?.payload?.products?.data?.map((value, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="browse-item">
                                                            <div className="browse-left">
                                                                <div className="category-item">
                                                                    <img src={value?.product_images[0]?.url} alt="topCategoryOneImg" />
                                                                    <p>{value?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="browse-right text-center">
                                                                <Link to={`/home-restaurent/${value?.product_sellers[0]?.unique_uuid}`}>View</Link>
                                                                {/* <p><img src={trackImg} alt="trackImg" /> 4 Shops</p> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    : <></>
                            }
                            {
                                searchResult?.data?.payload?.categories?.data?.length > 0 ?
                                    <div className="top-category">
                                        <h4>Top Categories</h4>
                                        <div className="category-part">
                                            {searchResult?.data?.payload?.categories?.data?.map((value, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="browse-item">
                                                            <div className="browse-left">
                                                                <div className="category-item">
                                                                    <img src={value?.image} alt="topCategoryOneImg" />
                                                                    <p>{value?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className="browse-right text-center">
                                                                <Link to={`/sellers/${value?.id}`}>View</Link>
                                                                {/* <p><img src={trackImg} alt="trackImg" /> 4 Shops</p> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    : <></>
                            }
                            {
                                searchResult?.data?.payload?.sellers?.data?.length > 0 ?
                                    <div className="top-category">
                                        <h4>Top Sellers</h4>
                                        <div className="category-part">
                                            {searchResult?.data?.payload?.sellers?.data?.map((value, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="browse-item">
                                                            <div className="browse-left">
                                                                <div className="category-item">
                                                                    <img src={value?.user_profiles?.profile_photo} alt="topCategoryOneImg" />
                                                                    <p>{value?.user_profiles?.firstname}</p>
                                                                </div>
                                                            </div>
                                                            <div className="browse-right text-center">
                                                                <Link to={`/home-restaurent/${value?.user?.unique_uuid}`}>View</Link>
                                                                {/* <p><img src={trackImg} alt="trackImg" /> 4 Shops</p> */}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </>
                        : "No Data Found"
                    }
                </div>
            </div>
        </>
    );
}

export default Browse;
