import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getCartApi,
    createCartApi,
    removeOneCartProductApi,
    createOrderApi,
    handleCartResponseApi,
    handleCartSubmitResponseApi,
    emptyCartApi
} from "./cartApi";

const initialState = {
    cartData: [],
    createCart: null,
    removeCart: null,
    createOrder: null,
    cartResponse: {},
    emptyCartResponse: null,
    cartSubmitResponse: {},
    status: {
        cartData: 'idle',
        createCart: 'idle',
        removeCart: 'idle',
        createOrder: 'idle',
        cartResponse: 'idle',
        cartSubmitResponse: 'idle',
        emptyCartResponse: 'idle'
    },
};
export const getCartApiAsync = createAsyncThunk("cart/getcart", async (data) => {
    const response = await getCartApi(data);
    return response;
});
export const createCartApiAsync = createAsyncThunk("cart/createcart", async (data) => {
    const response = await createCartApi(data.postData, data.tokenData);
    return response;
});
export const removeOneCartProductApiAsync = createAsyncThunk("cart/removeOneCartProductApi", async (data) => {
    const response = await removeOneCartProductApi(data.postData, data.tokenData);
    return response;
});
export const createOrderApiAsync = createAsyncThunk("cart/createOrderApi", async (data) => {
    const response = await createOrderApi(data.postData, data.tokenData);
    return response;
});
export const handleCartResponseApiAsync = createAsyncThunk("cart/handleCartResponseApi", async (data) => {
    const response = await handleCartResponseApi(data);
    return response;
});
export const handleCartSubmitResponseApiAsync = createAsyncThunk("cart/handleCartSubmitResponseApi", async (data) => {
    const response = await handleCartSubmitResponseApi(data);
    return response;
});

export const emptyCartApiAsync = createAsyncThunk("cart/emptyCartApi", async (data) => {
    const response = await emptyCartApi(data);
    return response;
});

export const cartSlice = createSlice({
    name: "cart",
    initialState,

    extraReducers: {
        [getCartApiAsync.pending](state) {
            state.status.cartData = "loading";
        },
        [getCartApiAsync.fulfilled](state, { payload }) {
            state.status.cartData = "idle";
            state.cartData = payload;
        },
        [getCartApiAsync.rejected](state, { error }) {
            state.status.cartData = "idle";
            state.cartData = [];
        },
        [createCartApiAsync.pending](state) {
            state.status.createCart = "loading";
        },
        [createCartApiAsync.fulfilled](state, { payload }) {
            state.status.createCart = "idle";
            state.createCart = payload;
        },
        [createCartApiAsync.rejected](state, { error }) {
            state.status.createCart = "idle";
            state.createCart = [];
        },
        [removeOneCartProductApiAsync.pending](state) {
            state.status.removeCart = "loading";
        },
        [removeOneCartProductApiAsync.fulfilled](state, { payload }) {
            state.status.removeCart = "idle";
            state.removeCart = payload;
        },
        [removeOneCartProductApiAsync.rejected](state, { error }) {
            state.status.removeCart = "idle";
            state.removeCart = [];
        },
        [createOrderApiAsync.pending](state) {
            state.status.createOrder = "loading";
        },
        [createOrderApiAsync.fulfilled](state, { payload }) {
            state.status.createOrder = "idle";
            state.createOrder = payload;
        },
        [createOrderApiAsync.rejected](state, { error }) {
            state.status.createOrder = "idle";
            state.createOrder = [];
        },
        [handleCartResponseApiAsync.pending](state) {
            state.status.cartResponse = "loading";
        },
        [handleCartResponseApiAsync.fulfilled](state, { payload }) {
            state.status.cartResponse = "idle";
            state.cartResponse = payload;
        },
        [handleCartResponseApiAsync.rejected](state, { error }) {
            state.status.cartResponse = "idle";
            state.cartResponse = {};
        },
        [handleCartSubmitResponseApiAsync.pending](state) {
            state.status.cartSubmitResponse = "loading";
        },
        [handleCartSubmitResponseApiAsync.fulfilled](state, { payload }) {
            state.status.cartSubmitResponse = "idle";
            state.cartSubmitResponse = payload;
        },
        [handleCartSubmitResponseApiAsync.rejected](state, { error }) {
            state.status.cartSubmitResponse = "idle";
            state.cartSubmitResponse = null;
        },
        [emptyCartApiAsync.pending](state) {
            state.status.emptyCartResponse = "loading";
        },
        [emptyCartApiAsync.fulfilled](state, { payload }) {
            state.status.emptyCartResponse = "idle";
            state.emptyCartResponse = payload;
        },
        [emptyCartApiAsync.rejected](state, { error }) {
            state.status.emptyCartResponse = "idle";
            state.emptyCartResponse = null;
        }
    },
});

export const selectCartData = (state) => state.cart.cartData;
export const selectCartResponse = (state) => state.cart.cartResponse;
export const selectCartSubmitResponse = (state) => state.cart.cartSubmitResponse;

export default cartSlice.reducer;