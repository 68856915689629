import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBookingList, cancelBookingByUser,updateBookingByUser } from './bookingApi'

const initialState = {
    bookingList: [],
    cancelBooking: [],
    updateBooking:[],
    status: {
        bookingList: 'idle',
        cancelBooking: "idle",
        updateBooking:"idle"
    }
}
export const getBookingListAsync = createAsyncThunk("booking/getBookingList", async (data) => {
    const response = await getBookingList(data.postData, data.tokenData);
    return response;
});
export const cancelBookingByUserAsync = createAsyncThunk("booking/cancelBookingByUser", async (data) => {
    const response = await cancelBookingByUser(data.postData, data.tokenData);
    return response;
});
export const updateBookingByUserAsync = createAsyncThunk("booking/updateBookingByUser", async (data) => {
    const response = await updateBookingByUser(data.idData, data.postData, data.tokenData);
    return response;
});


export const bookingSlice = createSlice({
    name: "booking",
    initialState,
    extraReducers: {
        [getBookingListAsync.pending](state) {
            state.status.bookingList = "loading"
        },
        [getBookingListAsync.fulfilled](state, { payload }) {
            state.status.bookingList = "idle";
            state.bookingList = payload;
        },
        [getBookingListAsync.rejected](state) {
            state.status.bookingList = "idle";
            state.bookingList = [];
        },
        [cancelBookingByUserAsync.pending](state) {
            state.status.cancelBooking = "loading"
        },
        [cancelBookingByUserAsync.fulfilled](state, { payload }) {
            state.status.cancelBooking = "idle";
            state.cancelBooking = payload;
        },
        [cancelBookingByUserAsync.rejected](state) {
            state.status.cancelBooking = "idle";
            state.cancelBooking = [];
        },
        [updateBookingByUserAsync.pending](state) {
            state.status.updateBooking = "loading"
        },
        [updateBookingByUserAsync.fulfilled](state, { payload }) {
            state.status.updateBooking = "idle";
            state.updateBooking = payload;
        },
        [updateBookingByUserAsync.rejected](state) {
            state.status.updateBooking = "idle";
            state.updateBooking = [];
        }



    }
});

export const selectBookingList = (state) => state.booking.bookingList

export default bookingSlice.reducer;

