import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { bxsCoinStackImg, favorableLinebarImg,leftArrowImg,prdMdl1Img, sldRolling3Img } from "../../../utilities/images";

const ScanResult = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className="main-wrapper">
    <div className="order-heading">
        <div className="header-left-item">
            <h4><Link to='/scan'><img src={leftArrowImg}/></Link> Scan result</h4>
        </div>
        <div className="header-right-item">
            <Link to="#">0 <img src={bxsCoinStackImg} alt=""/></Link>
        </div>               
    </div>

    <div className="main-content">
        <div className="scan-result-mainprt pt-3">
            <div className="product-mdlitem">
                <img src={prdMdl1Img} alt=""/>
                <h4>Marlboro Red King Size</h4>
                <p>Pack</p>
                <h6>Price: $10.50</h6>
            </div>

            <div className="product-mdlcnt">
                <p>A popular type of cigarette from the Marlboro brand due to its smooth flavour and it being mid-priced. </p>
                <ul>
                    <li>Cigarette Brand - <strong>Marlboro</strong></li>
                    <li>Cigarette Type - <strong>Red</strong></li>
                    <li>Tobacco Blend - <strong>American Blend</strong></li>
                    <li>Size - <strong>King Size Cigarettes</strong></li>
                    <li>Pack Quantity - <strong>20 Cigarettes</strong></li>
                </ul>
            </div>

            <div className="scan-accordian-area">
                <div className="scan-accordian-upper">
                    <h2>8 <small>score</small></h2>
                    <h4>Marlboro Red King <br/> Size</h4>
                </div>
                <div className="scan-accortitl">
                    <h6>Ingredient information</h6>
                </div>

                <div className="scan-accordian-part">
                    <div className="ziehharmonika">
                        <h3><span>10</span> Tobacco</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg1">1</span> Water</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg2">3</span> Sugars (Sucrose and/or Invert Sugar)</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg3">6</span> Propylene Glycol</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg4">5</span> Licorice Extract</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg5">2</span> Diammonium Phoshate</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg6">6</span> Ammonium Hydroxide</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg7">7</span> Cocoa and Cocoa Products</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg8">9</span> Carob Bean and Extract</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                        <h3><span className="bg9">2</span>Natural and Artificial Flavor</h3>
                        <div>
                            <p>More than 16 million Americans are living with a disease caused by smoking. For every person who dies because of smoking, at least 30 people live with a serious smoking-related illness. Smoking causes cancer, heart disease, stroke, lung diseases, diabetes, and chronic obstructive pulmonary disease (COPD), which includes emphysema and chronic bronchitis. Smoking also increases risk for tuberculosis, certain eye diseases, and problems of the immune system, including rheumatoid arthritis.</p>
                        </div>
                    </div> 
                </div>

                <div className="scan-accorbtm">
                    <p>The quality inspection of tobacco leaves consists of two main aspects: internal and external examinations. The internal quality inspection is usually achieved by human sensory, smoking test or chemical analysis, while the external quality inspection is mainly achieved through human vision. It is costly and yet time-consuming to inspect internal quality since tobacco leaves contain too many ingredients to be handled. As an alternative, external quality examination is often used instead in the examination of internal quality of tobacco leaves, since external features are closely related to internal quality. The external quality inspection of tobacco leaves includes judgment of color, maturity, surface texture, size and shape. Human vision, which is inevitably limited by personal, physical and environmental factors, has been the predominant means of inspection.</p>

                    <div className="scan-number">
                        <span>1</span>
                        <span className="numb-sbg2">2</span>
                        <span className="numb-sbg3">3</span>
                        <span className="numb-sbg4">4</span>
                        <span className="numb-sbg5">5</span>
                        <span className="numb-sbg6">6</span>
                        <span className="numb-sbg7">7</span>
                        <span className="numb-sbg8">8</span>
                        <span className="numb-sbg9">9</span>
                        <span className="numb-sbg10">10</span>
                    </div>

                    <div className="favroble-bar">
                        <span>Favorable</span>
                        <img src={favorableLinebarImg} alt=""/>
                        <span>Unfavorable</span>
                    </div>
                </div>
            </div>
            <div className="scan-buybtn">
                <Link to='/search-result'>Buy now!</Link>
            </div>
        </div>
    </div>
</div>
  )
}

export default ScanResult