import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from 'react-router-dom';
import { leftArrowImg, SearchImg, bxsCoinStackImg, tob1Img, tob2Img, tob3Img, tob4Img, tob5Img, tob6Img, tob7Img, tob8Img, tob9Img, tob10Img, tob11Img, tob12Img, tob13Img, tob14Img } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import {  getCategoryBySellerIdAsync, selectCategoryBySellerIdAsync } from "../dashboard/dashboardSlice";

const BrowseNext = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const categoryProductDataBySellerId=useSelector(selectCategoryBySellerIdAsync)
    console.log(categoryProductDataBySellerId,'categoryProductDataBySellerId');
   
    let sellerIdData = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        dispatch(getCategoryBySellerIdAsync(sellerIdData))
  
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Categories</h4>
                </div>
                <div className="header-right-item">
                    <button>
                        <img src={SearchImg} alt="SearchImg" />
                    </button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>
            <div className="main-content">
                <div className="tobacco-mainitem">
                    {categoryProductDataBySellerId?.data?.payload?.map((val, index) => {
                        return (
                            <>
                                <ul key={index}>
                                    <li><Link to={(`/product-add-to-cart/${val.id}`)}><img src={val?.image} alt="tob1Img" /> {val?.name} <i className="fas fa-chevron-right"></i></Link></li>
                                </ul>


                            </>)
                    })}                  
                </div>
            </div>
        </>
    );
}

export default BrowseNext;