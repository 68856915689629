import React from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ArrowWhite, bagImg, bellImg, leftArrowImg, Msg1, Msg2, Msg3, Msg4, Msg5 } from '../../../utilities/images'

const SelectService = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="order-heading" >
                <h4 onClick={()=> history.goBack()}><img src={leftArrowImg} alt="" /> Full Body Massage </h4>
                <div className="header-right-item">
                    <button type="button" onClick={()=>history.push('/notifications')}><img src={bellImg} alt="" /></button>
                    <button type="button"><img src={bagImg} alt="" /></button>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="body-massage-mainprt">
                    <div className="body-msgtitle">
                        <h2>Select Service <span>Required</span></h2>
                    </div>

                    <div className="bodymsg-part">
                        <div className="bodymsg-item">
                            <div className="bodymsg-left">
                                <div className="form-group">
                                    <input type="checkbox" id="check1"/>
                                    <label htmlFor="check1"></label>
                                </div>
                                <div className="bdymsg-cnt">
                                    <h4>Swedish massage</h4>
                                    <p>USD $5.50</p>
                                </div>
                            </div>
                            <div className="bodymsg-right">
                                <img src={Msg1} alt=""/>
                            </div>
                        </div>

                        <div className="bodymsg-item">
                            <div className="bodymsg-left">
                                <div className="form-group">
                                    <input type="checkbox" id="check2"/>
                                    <label htmlFor="check2"></label>
                                </div>
                                <div className="bdymsg-cnt">
                                    <h4>Hot stone massage</h4>
                                    <p>USD $5.50</p>
                                </div>
                            </div>
                            <div className="bodymsg-right">
                                <img src={Msg2} alt=""/>
                            </div>
                        </div>

                        <div className="bodymsg-item">
                            <div className="bodymsg-left">
                                <div className="form-group">
                                    <input type="checkbox" id="check3"/>
                                    <label htmlFor="check3"></label>
                                </div>
                                <div className="bdymsg-cnt">
                                    <h4>Aromatherapy massage</h4>
                                    <p>USD $5.50</p>
                                </div>
                            </div>
                            <div className="bodymsg-right">
                                <img src={Msg3} alt=""/>
                            </div>
                        </div>

                        <div className="bodymsg-item">
                            <div className="bodymsg-left">
                                <div className="form-group">
                                    <input type="checkbox" id="check4"/>
                                    <label htmlFor="check4"></label>
                                </div>
                                <div className="bdymsg-cnt">
                                    <h4>Deep tissue massage</h4>
                                    <p>USD $5.50</p>
                                </div>
                            </div>
                            <div className="bodymsg-right">
                                <img src={Msg4} alt=""/>
                            </div>
                        </div>

                        <div className="bodymsg-item">
                            <div className="bodymsg-left">
                                <div className="form-group">
                                    <input type="checkbox" id="check5"/>
                                    <label htmlFor="check5"></label>
                                </div>
                                <div className="bdymsg-cnt">
                                    <h4>Sports massage</h4>
                                    <p>USD $5.50</p>
                                </div>
                            </div>
                            <div className="bodymsg-right">
                                <img src={Msg5} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="selected-product checkout-box">
                    <div className="selected-itembox appoint-box">
                        <div className="selected-leftcnt">
                            <p>Services: <strong>2</strong></p>
                            <p>$ <strong>58.50</strong></p>
                        </div>
                        <div className="selected-rightbtn">
                            <Link to='/appointment-time'>
                                <img src={ArrowWhite} alt=""/>
                            </Link>
                        </div>
                    </div>
                </div>                                                  
            </div>
        </>
    )
}

export default SelectService