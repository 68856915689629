import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getIdCardPhoto } from "./ageVerificationApi";

const initialState = {
  uploadIdCardPhoto1: null,
  uploadIdCardPhoto2: null,
  status: "idle",
};

export const uploadIdCardAsync = createAsyncThunk("idCard/photo", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});
export const uploadIdCardAsync1 = createAsyncThunk("idCard/photo1", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});

export const profilePhotoSlice = createSlice({
  name: "uploadIdCardPhoto",
  initialState,
  extraReducers: {

    [uploadIdCardAsync.pending](state) {
      state.status = "loading";
    },
    [uploadIdCardAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.uploadIdCardPhoto1 = payload;
    },
    [uploadIdCardAsync.rejected](state, { error }) {
      state.status = "idle";
      state.uploadIdCardPhoto1 = [];
    },
      [uploadIdCardAsync1.pending](state) {
        state.status = "loading";
      },
      [uploadIdCardAsync1.fulfilled](state, { payload }) {
        state.status = "idle";
        state.uploadIdCardPhoto2 = payload;
      },
      [uploadIdCardAsync1.rejected](state, { error }) {
        state.status = "idle";
        state.uploadIdCardPhoto2 = [];
      }
  },
});

export const selectIdCardPhoto1 = (state) => state.idCardPhoto.uploadIdCardPhoto1;
export const selectIdCardPhoto2 = (state) => state.idCardPhoto.uploadIdCardPhoto2;
export default profilePhotoSlice.reducer;