import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    leftArrowImg, bellImg, bagImg, bxsCoinStackImg, ProductSt8, RetailItem1, RetailItem2, RetailItem3, RetailItem4, RetailItem5, RetailItem6, RetailItem7, HomeServiceBanner, HmServ1, HmServ2, HmServ3, HmServ4, starCircleImg, clockImg, Trending3
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import MobileHeader from '../../components/shared/MobileHeader';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { unwrapResult } from '@reduxjs/toolkit';
import PaginationSetting from '../../constants/PaginationSetting';
import { selectTrendingNearbyData, getCategoryApiAsync, selectBannerByServiceId, selectCategoryByServiceId, selectCategoryData, getTrendingCategoryServiceTypeAsync, getBannerByServiceTypeAsync, getTrendingNearBySellerAsync } from '../dashboard/dashboardSlice';
import { selectLoginAuth } from '../auth/authSlice';
import Slider from "react-slick";
import SliderWrapper from "../../containers/dashboard/SliderStyle";



const HomeClassesShop = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { status } = useSelector(state => state.dashboard)
    const bannerData = useSelector(selectBannerByServiceId);
    const trendingCategoryData = useSelector(selectCategoryByServiceId);
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const auth = useSelector(selectLoginAuth)
    const categoryData = useSelector(selectCategoryData);
    console.log(categoryData, 'categoryData');
    const trendingSellerData = useSelector(selectTrendingNearbyData)

    const bannerSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(bannerData?.data?.payload?.data?.length)}`}</div>
    };

    useEffect(() => {
        let bannerData = {
            "postData": 5,
            "tokenData": auth?.payload?.token
        }
        dispatch(getCategoryApiAsync(5))
        dispatch(getBannerByServiceTypeAsync(bannerData))
        dispatch(getTrendingCategoryServiceTypeAsync(5))
    }, [dispatch])

    useEffect(() => {
        let params = {
            page: page,
            limit: pagination.recordsPerPage,
            service_id: 5,
            lat: 30.7355,
            serviceType: 1,
            long: 76.7121
        }
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
            })
    }, [page])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div id="tab1" className="active">
                {status.categoryData === "loading" || status.bannerData === "loading" || status.trendingCategoryData === "loading" || status.trendingNearby === "loading" ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className="main-content">
                        <div className="home-tab">
                            <div className="search-barhm">
                                <form action="#" method="POST">
                                    <input type="search" placeholder="Search here" onFocus={() => history.push('/browse/5')} />
                                </form>
                            </div>
                            {/* categories */}
                            <div className="retail-part retail-part2">
                                {
                                    categoryData?.data?.payload?.data?.map((val, index) => {
                                        return (
                                            <div className="retail-item" key={index} onClick={() => history.push(`/sellers/${val?.id}`)}>
                                                <span>
                                                    <img src={val?.image} alt="" />
                                                </span>
                                                <p>{val?.name}</p>
                                            </div>
                                        )
                                    })
                                }
                                {categoryData?.data?.payload?.data?.length > 8 ?
                                    <div className="retail-item">
                                        <Link to="#">
                                            <img src={ProductSt8} alt="" />
                                        </Link>
                                        <p>More</p>
                                    </div> : null}
                            </div>
                            {/* mega slider  */}
                            <div id="bannerSlider">
                                <SliderWrapper>
                                    <Slider {...bannerSettings}>
                                        {
                                            bannerData?.data?.payload?.data && bannerData?.data?.payload?.data?.map((val, index) => {
                                                return (
                                                    <div className="carousel-cell" key={index}>
                                                        <img src={val?.path} alt="" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </SliderWrapper>                                
                            </div>

                            <div className="retail-mainprt">
                                <div className="row">
                                    {
                                        trendingCategoryData?.data?.payload?.map((val, index) => {
                                            return (
                                                <div className="col-6" key={index} onClick={() => history.push(`/sellers/${val?.id}`)}>
                                                    <div className="retail-product">
                                                        <img src={val?.image} alt="" />
                                                        <span>{val?.description}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {trendingSellerData?.data?.payload?.data?.length > 0 &&
                                <div className="delivery-parthm">
                                    <div className="trending-title pt-5">
                                        <h2>Trending</h2>
                                    </div>
                                    {trendingSellerData?.data?.payload?.data?.map((value, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => history.push(`/home-classes-store/${value?.unique_uuid}`)}>
                                                    <img className="delv-prd" src={value?.user_profiles?.profile_photo} alt="" />
                                                    <h4>{value?.user_profiles?.firstname}</h4>
                                                    <ul>
                                                        <li><img src={starCircleImg} alt="starCircleImg" />{value?.sellerRatings}</li>
                                                        <li><img src={clockImg} alt="clockImg" />{value?.distance}</li>
                                                        <li><img src={RetailItem2} alt="category" />{value?.category ? value?.category : "Other"}</li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </div>
                            }
                            {pagination.totalRecords > pagination.recordsPerPage &&
                                <Box py={3} display="flex" justifyContent="center">
                                    <Pagination
                                        key={page}
                                        count={pagination.totalPages || 0}
                                        color="secondary"
                                        variant="outlined"
                                        onChange={(e, value) => setPage(value)}
                                        defaultPage={page}
                                    />
                                </Box>
                            }
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default HomeClassesShop
