import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, shopImg, starCircleImg, clockImg, deliveryParcelImg } from "../../../utilities/images";
import getFavouriteData from "../../apis/favorite.json";

const FavoriteList = (props) => {
    const [allFavoriteList, setAllFavoriteList] = useState("");


    useEffect(() => {
        setAllFavoriteList(getFavouriteData)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to='/more'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Favorite list</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>
            <div className="main-content">
                <div className="search-tab">
                    <div className="delivery-parthm">
                        <div className="tabs">
                            <div className="tab-upper tab-upperhm noshadow">
                                <ul className="tab-links text-left p-0">
                                    <li className="active"><Link to="#tab1">Delivery</Link></li>
                                    <li><Link to="#tab2">Pickup</Link></li>
                                    <li><Link to="#tab2">Shipping</Link></li>
                                </ul>
                            </div>
                        </div>
                        {allFavoriteList && allFavoriteList.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="result-sec">
                                        <div className="result-secleft">
                                            <img src={shopImg} alt="shopImg" />
                                        </div>
                                        <div className="result-secright">
                                            <h4><Link to='/store'>{item.title}</Link><Link to="#" className="fav-active"><i className="far fa-heart"></i></Link></h4>
                                            <p><img src={starCircleImg} alt="starCircleImg" />{item.rating}</p>
                                            <p><img src={clockImg} alt="clockImg" /> {item.time}</p>
                                            <p><img src={deliveryParcelImg} alt="deliveryParcelImg" /> {item.upto}</p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}


export default FavoriteList;