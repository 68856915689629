import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    leftArrowImg, coinStackImg, bellImg, bagImg
} from "../../../utilities/images";
import DashboardDelivery from "./DashboardDelivery";
import DashboardPickup from "./DashboardPickup";
import DashboardShipping from "./DashboardShipping";
import MobileHeader from "../../components/shared/MobileHeader";

const OldIndex = (props) => {
    const history = useHistory();

    const [tabView, setTabView] = useState(1);
    
    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <MobileHeader/>

            <div className="main-content">
                <div className="home-tab">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links">
                                <li className={(tabView == 1) ? `active` : ``}><Link to="#" onClick={() => { setTabView(1) }}>Delivery</Link></li>
                                <li className={(tabView == 2) ? `active` : ``}><Link to="#" onClick={() => { setTabView(2) }}>Pickup</Link></li>
                                <li className={(tabView == 3) ? `active` : ``}><Link to="#" onClick={() => { setTabView(3) }}>Shipping</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            {(tabView == 1) &&
                                <div id="tab1" className={(tabView == 1) ? `tab active` : `tab`}>
                                    <DashboardDelivery />
                                </div>}
                            {(tabView == 2) &&
                                <div id="tab2" className={(tabView == 2) ? `tab active` : `tab`}>
                                    <DashboardPickup />
                                </div>
                            }
                            {tabView == 3 &&
                                <div id="tab3" className={(tabView == 3) ? `tab active` : `tab`}>
                                    <DashboardShipping/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OldIndex