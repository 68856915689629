import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CustomModal from "../../components/shared/CustomModal";
import { leftArrowImg } from "../../../utilities/images";
import Verify2 from "../../public/images/veirfy-2.png";
// import PinField from "react-pin-field";
import { useDispatch, useSelector } from 'react-redux';
import { changePinAsync, selectOtpId, selectVerifyResponse } from './authSlice';
import OTPInput from 'react-otp-input';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const SetNewPin = (props) => {
    const pathName = window.location.pathname;
    const history = useHistory();
    const toastId = React.useRef(null)
    const [step, setStep] = useState(1);
    const [randomNumber, setRandomNumber] = useState(Math.random());
    const verifyOtp = useSelector(selectVerifyResponse)
    const [modalKey, setModalKey] = useState(Math.random());
    const [pin, setPin] = useState("");
    const [reenteredPin, setRenteredPin] = useState("");
    const dispatch = useDispatch()
    const [modalDetail, setModalDetail] = useState({
        show: false
    });
    const otpData = useSelector(selectVerifyResponse)
    console.log(otpData,"otp data")

    // console.log(verifyOtp,"verify otp data")
    let phonedetails = JSON.parse(localStorage.getItem("phonedetails"))
    let data = {
        "phone_code": phonedetails?.phone_code,
        "phone_number": phonedetails?.phone_no,
        "pin": pin,
        "otp": `${otpData?.payload?.otp}`
    }

    const onComplete = (code, flag) => {
        if (flag == 'step_1') {
            setPin(code);
        }

        if (flag == 'step_2') {
            setRenteredPin(code);
        }
    }
    const handleNext = async (flag) => {
        if (flag == 'step_1') {
            setStep(2);
            setRandomNumber(Math.random());
        }
        if (flag == 'step_2') {
            if (props?.location?.state == "setuppin") {
                history.push('/face-id-and-Pin')
            }
            if (!props?.location?.state) {
                await dispatch(changePinAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.success(obj?.msg)
                        }
                    }
                    )
                    .catch((obj) => {
                        // if (!toast.isActive(toastId.current)) {
                        //     toastId.current = toast.error(obj?.message)
                        //     console.log(obj)
                        // }
                    })

                await setTimeout(() => {
                    setModalDetail({ show: true });
                    setModalKey(Math.random());
                }, 1000);
            }
        }
    }


    const handleOnCloseModal = () => {
        setModalDetail({ show: false });
        setModalKey(Math.random());
    }
    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])
    return (
        <>
            {!modalDetail.show ?
                <div className="age-upper pin-upper">
                    <Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /> Set up PIN</Link>
                    <Link className="cancel-btn" to="/">Cancel</Link>
                </div>
                : ""}
            <div className="pin-main">
                {step == 1 ?
                    <>
                        <p>Enter your PIN</p>
                        <div className="pin-box d-flex justify-content-center" >
                            <OTPInput numInputs={4}
                                className='input_digits_'
                                value={pin}
                                data-cy="pin-field"
                                isInputNum={true}
                                onChange={(code) => onComplete(code, 'step_1')}
                            // onComplete={(code) => onComplete(code)}
                            />
                        </div>
                    </>
                    :
                    <>
                        <p>Reenter your PIN</p>
                        <div className="pin-box d-flex justify-content-center" >
                            <OTPInput numInputs={4}
                                className='input_digits_'
                                value={reenteredPin}
                                data-cy="pin-field"
                                onChange={(code) => onComplete(code, 'step_2')}
                            // onComplete={(code) => onComplete(code)}
                            />
                        </div>
                    </>
                }

            </div>
            <div className="bottom-part">
                <Link to="#" className="bottom-btn" onClick={() => handleNext(step == 1 ? 'step_1' : 'step_2')}>Next</Link>
            </div>

            <CustomModal
                key={modalKey}
                show={modalDetail.show}
                backdrop="static"
                title={modalDetail.title}
                mediumWidth={true}
                child={
                    <div className="modal-mainprt">
                        <div className="mdlverify-cnt cardmdl-verify">
                            <h4>Done!</h4>
                            <img src={Verify2} alt=""/>
                            <p>You are verified!</p>
                            <h6>Your new pin is set now</h6>
                        </div>
                        <div className="mdlverify-btn mdllogin-btn">
                            <Link to='/login' className="active">Log in</Link>
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    );
}

export default SetNewPin;