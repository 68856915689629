import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { selectLoginAuth } from '../auth/authSlice'
import { getBookingSlotAsync, selectedSlotData } from './dashboardSlice'
import { toast } from "react-toastify";

const ReservationTimeModal = (props) => {
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const { id } = useParams()
    const [slotStartTime, setSlotStartTime] = useState("")
    const [slotEndTime, setSlotEndTime] = useState("")
    const [slotId, setSlotId] = useState("")
    const auth = useSelector(selectLoginAuth)
    let data = {
        "tokenData": auth?.payload?.token,
        "postData": {
            "id": id? id : props?.sellerId,
            "date": props?.date
        }
    }
    const slotData = useSelector(selectedSlotData)

    const handleClick = (val) => {

        setSlotStartTime(val?.start_time)
        setSlotEndTime(val?.end_time)
        setSlotId(val?.id)
    }
    const handleTime = () => {
        if(slotId === ""){
            if(!toast.isActive(toastId.current)){
                toastId.current = toast.error("Please Select Slot time")
            }
        }else{
            props.details('reservetable')
            let data = {
                slotId: slotId,
                startTime: slotStartTime,
                endTime: slotEndTime
            }
            props.handleSlot(data)
        }
    }
    console.log(slotData?.data?.payload,"slotData")
    useEffect(() => {
        dispatch(getBookingSlotAsync(data))
    }, [])
    return (
        <div className="modal-content">
            <div className="modal-header modal-headerbok">
                <button type="button" className="close" onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="time-mdlmain">
                    <h2>Please chose a Time below:</h2>

                    {
                        slotData?.data?.payload?.map((val,index)=>{
                            return(
                                <div className="time-mdlprt" key={index}>
                                <h4>{val?.name} </h4>
                                <div className="delivery-time">
                                    {
                                        val?.slots?.map((val, index) => {
                                            return (
                                                <button type='button' key="index" className={val?.availabilty === false ? 'time_button inactive' : (slotStartTime === val?.start_time && slotId === val?.id) ? 'time_button active' : "time_button"} disabled={val?.availabilty === false} onClick={() => handleClick(val)}>{val?.start_time}-{val?.end_time}</button>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            )
                        })
                    }
                    
                    {/* <div className="time-mdlprt">
                        <h4>Outdoore </h4>
                        <div className="delivery-time">
                            {
                                slotData?.data?.payload?.map((val, index) => {
                                    return (
                                        <button type='button' className={val?.type === "1" ? 'time_button inactive' : slotStartTime === val?.start_time ? 'time_button active' : "time_button"} disabled={val?.type === "1"} onClick={() => handleClick(val)}>{val?.start_time}-{val?.end_time}</button>
                                    )
                                })
                            }
                        </div>
                    </div> */}

                    <div className="reserve-btn mt-auto" onClick={handleTime}>
                        <Link to="#">Set Time</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReservationTimeModal