import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { leftArrowImg, review1Img, cameraImg, logoImg, ratingImg } from "../../../utilities/images";
import ReactStars from 'react-stars'
import CustomModal from "../../components/shared/CustomModal";

const ProductReviewTwo = (props) => {
    const history = useHistory();
    const [note, setNote] = useState("");
    const [document, setDocument] = useState("")
    const [imageData, setImageData] = useState("")
    const [fileData, setFileData] = useState([])
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "note") {
            setNote(value.trimStart());
        }
    };

    let imageFile;
    let fileObj = [];
    let fileArray = [];
    let fileDetails = []

    const handleChange = (e) => {
        // imageFile = e.target.files[0];
        // // console.log(imageFile, "imagefile")
        // setImageData(imageFile)
        // setDocument(URL.createObjectURL(imageFile));
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push({url:URL.createObjectURL(fileObj[0][i]), name: fileObj[0][i]?.name, size: fileObj[0][i]?.size})
        }
        setFileData(fileArray)
    }

    console.log(fileData, "documnet")

    const ratingChanged = (newRating) => {
        console.log(newRating)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <h4><Link to='/product-review-available'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Product review</h4>
            </div>

            <div className="main-content">
                <div className="product-review-area">
                    <div className="rate-upper">
                        <h2>Rate your experience</h2>
                        <p>Are you seticfy with product?</p>
                    </div>

                    <div className="rating-partex">
                        <div className="rating-leftex">
                            <img src={review1Img} alt="review1Img" />
                        </div>
                        <div className="rating-rightex">
                            <h4>Marlboro Touch</h4>
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Product Quality</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rating-innerex">
                        <div className="rating-inrleftex">
                            <p>Velue of Money</p>
                        </div>
                        <div className="rating-inrrightex">
                            <div className="rateYo">
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={30}
                                    color2={'#FCBA30'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="additional-box">
                        <h4>Write your additional feedback</h4>
                        <form action="#" method="POST" >
                            <textarea name="" id="" cols="30" rows="5" value={note} onChange={(e) => { onChangeInput(e, 'note') }}></textarea>
                        </form>
                    </div>

                    <div className="attach-part">
                        <h4>Attach images</h4>
                        <div className="upload-btn-wrapper">
                            <button className="filebtn"><img src={cameraImg} alt="cameraImg" /></button>
                            <input type="file" name="myfile" onChange={(e) => handleChange(e)} multiple />
                        </div>
                    </div>

                    {(fileData) ?
                        <>
                            {
                                (fileData || []).map((data, index) => (
                                    <div className="attach-item" key={index}>
                                        <img src={data.url} alt="attachImg" />
                                        <div className="attach-cnt">
                                            <h4>{data?.name}</h4>
                                            <p>{data?.size} kb</p>
                                            <span></span>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                        : ""
                    }

                    <div className="submit-btnrating">
                        <Link to="#" onClick={() => { setModalDetail({ show: true }); setKey(Math.random()); }}>Submit</Link>
                    </div>
                </div>

                <CustomModal
                key = {key}
                show = {modalDetail.show}
                backdrop = "static"
                showCloseBtn= {false}
                isRightSideModal={false}
                darkModal={true}
                child = {
                        <div className="modal-body">
                            {/* <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() =>{props.closeModal()}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div className="modal-body">
                                <div className="modal-mainprt review-mdlprt">
                                    <div className="mdlverify-cnt">
                                        <h4>Congratulation</h4>
                                        <img src={logoImg} alt="logoImg" />
                                        <p>Review submitted successfully</p>
                                        <h6>You have earn JBR 1.00</h6>
                                    </div>
                                    <div className="mdlverify-btn mdllogin-btn">
                                        <Link to="#" onClick={() => { history.push({pathname:'/product-review-available', state:"review"})}} className="active" data-dismiss="modal">Back</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                // size="lg"
                //title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            /> 

            </div>
        </>

    );
}

export default ProductReviewTwo;