import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { req1Img, tellImg, emailImg, locationImg, inf1Img, inf2Img, supportProfImg, req2Img } from "../../../utilities/images";
import { useSelector, useDispatch } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import moment from 'moment-timezone'
import { getSupportApiAsync, selectSupportData } from "./SupportRequestSlice";

const SupportRequestDetails = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const supportData = useSelector(selectSupportData);
    const result = supportData?.data?.payload?.data?.filter(word => word.id == props?.history?.location?.state);
    console.log(auth?.payload?.user_profiles, "support data")

    useEffect(() => {
        dispatch(getSupportApiAsync(auth?.payload?.token))
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => { history.push('/my-support-request') }}><i className="fas fa-chevron-left"></i> #{result[0]?.track_number}</Link></h4>
                <div className="info-reqall">
                    <Link to="#"><i className="fas fa-info-circle"></i></Link>
                    <div className="reqdetails-info">
                        <div className="reqdet-infobox">
                            <div className="reqdetails-upper">
                                <img src={auth?.payload?.user_profiles?.profile_photo ? auth?.payload?.user_profiles?.profile_photo : req1Img} alt="req1Img" />
                                <div className="reqdetails-uprcnt">
                                    <h4>{auth?.payload?.user_profiles?.firstname}</h4>
                                    <p>Me</p>
                                </div>
                            </div>

                            <ul>
                                <li><img src={tellImg} alt="tellImg" /> {auth?.payload?.user_profiles?.phone_no}</li>
                                <li><Link to="#"><img src={emailImg} alt="emailImg" /> {auth?.payload?.user_profiles?.email ? auth?.payload?.user_profiles?.email : "xyz@gmail.com"}</Link></li>
                                <li><img src={locationImg} alt="locationImg" />4318 Daffodil Lane, Savage, <br /> Virginia(VA), 20763</li>
                            </ul>

                            <div className="reqdet-infobtm">
                                <h4>Total Tickets: <strong>16</strong></h4>
                                <ul>
                                    <li>Solved: <strong>9</strong></li>
                                    <li>Cancelled: <strong>1</strong></li>
                                    <li>Open: <strong>1</strong></li>
                                    <li>Unrespond: <strong>1</strong></li>
                                </ul>
                            </div>
                        </div>

                        <div className="reqdet-infobox2">
                            <h4>Assigned agent:</h4>
                            <div className="reqinfo-item">
                                <img src={supportProfImg} alt="supportProfImg" />
                                <div className="reqinfo-cnt2">
                                    <h6>Satomi D.</h6>
                                    <p>13 Jun, 2022  |   12:25p</p>
                                </div>
                            </div>
                            <h4>Also on this ticket:</h4>
                            <ul>
                                <li><img src={inf1Img} alt="inf1Img" />Satomi D.</li>
                                <li><img src={inf2Img} alt="inf2Img" />Satomi D.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div className="reqdetails-area">
                    <div className="reqdetails-part">
                        <div className="reqdetails-upper">
                            <img src={auth?.payload?.user_profiles?.profile_photo ? auth?.payload?.user_profiles?.profile_photo : req1Img} alt="req1Img" />
                            <div className="reqdetails-uprcnt">
                                <h4>{auth?.payload?.user_profiles?.firstname}</h4>
                                <p>Me</p>
                            </div>
                        </div>
                        <div className="reqdetails-content">
                            <p>{result[0]?.notes}</p>
                            <h6>
                                {moment(result[0]?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(result[0]?.created_at).format('hh:mm A')}
                            </h6>
                        </div>
                    </div>

                    {
                        result[0]?.support_comments?.map((val, index) => {
                            return (
                                <div className="reqdetails-part border-0" key={index}>
                                    <div className="reqdetails-upper">
                                        <img src={val?.user_id_details?.profile_photo} alt="" />
                                        <div className="reqdetails-uprcnt">
                                            <h4>{val?.user_id_details?.firstname}{" "}{val?.user_id_details?.lastname}</h4>
                                            <p>JOBR Agent</p>
                                        </div>
                                    </div>
                                    <div className="reqdetails-content">
                                        <p>{val?.comment}</p>
                                        <p>--------------</p>
                                        <p>
                                            Regards, <br />
                                            {val?.user_id_details?.firstname}{" "}{val?.user_id_details?.lastname} <br />
                                            Jobr.com Support Team
                                        </p>
                                        <h6>
                                            {moment(val?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(val?.created_at).format('hh:mm A')}
                                        </h6>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </>
    );
}

export default SupportRequestDetails;