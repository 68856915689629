import React, { useState,useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom';
import HealthAndFitnessShop from '.';
import {
    leftArrowImg, bellImg, bagImg, bxsCoinStackImg,
} from "../../../utilities/images";
import MobileHeader from '../../components/shared/MobileHeader';
import HealthAndFitnessBooking from './HealthAndFitnessBooking';

const HomeHealthFitness = () => {
    const history = useHistory()
    const [tabData, setTabData] = useState("tab1")

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <MobileHeader />
            <div className="main-content">
                <div className="home-tab">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links">
                                <li className={(tabData === "tab1") ? "active" : ""} onClick={() => setTabData("tab1")}><Link to="#tab1">Shop</Link></li>
                                <li className={(tabData === "tab2") ? "active" : ""} onClick={() => setTabData("tab2")}><Link to="#tab2">Booking</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">
                        {
                            (tabData === "tab1") ?
                                <HealthAndFitnessShop />
                                : <HealthAndFitnessBooking />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeHealthFitness