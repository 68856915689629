import axios from "axios";

export function getAddressDetail(data) {
  // const id = data.loginId;
  // delete data.loginId;
  const token = data.token;
  delete data.token;
  return new Promise((resolve, reject) =>
    axios.post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}
export function getAllAddress(data) {
  const token = data.token;
  delete data.token;
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
        localStorage.removeItem("invalidData")
      })
      .catch(function (error) {
        console.error(error);
        localStorage.setItem("invalidData", error)
      })
  )
}
export function changeCurrentAddressApi(id, data, token) {
  return new Promise((resolve, reject) =>
    axios.put(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        console.error(error);
      })
  )
}