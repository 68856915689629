import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { bxsCoinStackImg, leftArrowImg, SearchImg, addCartP1Img, addCardD3Img, arrowBlueImg } from "../../../utilities/images";
import getCart from "../../apis/store.json"

const ProductAddToCartTwo = (props) => {
    // const location = useLocation();
    const [count, setCount] = useState({
        product1: 1,
        product2: 1,
        product3: 1,
        product4: 1
    })

    const incrementCount = (data) => {
        const name = data;
        setCount({
            ...count,
            [name]: Number(count[data]) + 1
        })
    }
    const decrementCount = (data) => {
        const name = data;
        setCount({
            ...count,
            [name]: Number(count[data]) - 1
        })
    }
    const handleChange = (e) => {
        const name = e.target.name;
        setCount({
            ...count,
            [name]: e.target.value
        })
    }
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const [Cigarettes, setCigarettes] = useState([]);

    useEffect(() => {
        setCigarettes(getCart.Cigarettes)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="order-heading browse-addprdcard-header">
                <div className="header-left-item">
                    <h4><Link to='/store'><img src={leftArrowImg} alt="leftArrowImg" /></Link> Miami smoke </h4>
                </div>
                <div className="header-right-item">
                    <button>
                        <img src={SearchImg} alt="SearchImg" />
                    </button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="product-tabarea">
                    <div className="tabs">
                        <div className="tab-upper">
                            <ul className="tab-links tab-menu">
                                <li className="active"><Link to="#tab1">Marlboro</Link></li>
                                <li><Link to="#tab2">Dunhill</Link></li>
                                <li><Link to="#tab3">B&H</Link></li>
                                <li><Link to="#tab4">Black Devil</Link></li>
                                <li><Link to="#tab5">Camel</Link></li>
                                <li><Link to="#tab6">Davidoff</Link></li>
                            </ul>
                        </div>

                        <div className="tab-content addproduct-tabcntent">
                            <div id="tab1" className="tab active">
                                <div className="product-addcart-main">
                                    {Cigarettes && Cigarettes.map((Cigarettes, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="paddcart-item">
                                                    <div className="paddcart-inner">
                                                        <img src={addCartP1Img} alt=""/>
                                                        <h4>{Cigarettes.title}</h4>
                                                        <p>{Cigarettes.titleTwo}</p>
                                                        <h6><span>{Cigarettes.listed}</span> {Cigarettes.price}</h6>
                                                    </div>
                                                    <div className="paddcart-btn">
                                                        <Link to="#" data-toggle="modal" data-target="#exampleModalCenter"><i className="fas fa-plus"></i></Link>
                                                    </div>
                                                    {
                                                        (index === 1 || index === 4) ?
                                                            <div className="paddcart-btn">
                                                                <div className="quantity">
                                                                    <form action="#" method="POST">
                                                                        <span className="input-number-decrement"><i className="fas fa-minus" onClick={() => decrementCount(`product${index}`)}></i></span>
                                                                        <input className="input-number3" type="text" name={`product${index}`} onKeyDown={blockInvalidChar} value={index === 1 ? count.product1 : count.product4} onChange={e => handleChange(e)} />
                                                                        <span className="input-number-increment"><i className="fas fa-plus" onClick={() => incrementCount(`product${index}`)}></i></span>
                                                                    </form>
                                                                </div>
                                                            </div> : ""
                                                    }
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </div>

                            </div>

                            <div id="tab2" className="tab">
                                <div className="product-addcart-main">
                                    {Cigarettes && Cigarettes.map((Cigarettes, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="paddcart-item">
                                                    <div className="paddcart-inner">
                                                        <img src={addCardD3Img} alt=""/>
                                                        <h4>{Cigarettes.title}</h4>
                                                        <p>{Cigarettes.titleTwo}</p>
                                                        <h6><span>{Cigarettes.listed}</span> {Cigarettes.price}</h6>
                                                    </div>
                                                    <div className="paddcart-btn">
                                                        <Link to="#" data-toggle="modal" data-target="#exampleModalCenter"><i className="fas fa-plus"></i></Link>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                            <div id="tab3" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>
                            <div id="tab4" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>
                            <div id="tab5" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>
                            <div id="tab6" className="tab">
                                <div className="tab-othercnt">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Qui molestiae iusto magnam sint et vel, nesciunt error illum debitis dignissimos blanditiis veniam aperiam, odit consequuntur. Doloremque, culpa.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductAddToCartTwo;
