import axios from "axios";
import RestClient from '../../../utilities/RestClient'
import { toast } from "react-toastify";

export function loginApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/login/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        // console.error(error);
      })
  )
}

export function registerSendOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        // console.error(error?.response,"response");
      })
  )
}
export function verifyOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/verify-phone/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function registerApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function changePinApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/change-pin/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function verifyAgeDocumentApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/verify-document`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function getIdCardPhoto(data) {
  var formData = new FormData();
  formData.append('document', data.document);
  return new Promise((resolve, reject) =>
    axios.post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/verification-document`, formData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}