import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom'
import { bagImg, bxsCoinStackImg, CalenderDark2, cancelOrderImg, ClockDark2, leftArrowImg, Person, UserDark2 } from '../../../utilities/images'
import { selectLoginAuth } from '../auth/authSlice';
import { cancelBookingByUserAsync, getBookingListAsync, selectBookingList, updateBookingByUserAsync } from './bookingSlice';
import moment from "moment-timezone";
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import ReserveTimeModal from '../dashboard/ReservationTimeModal';
import ReserveDateModal from '../dashboard/ReservationDateModal';


const BookingList = () => {
  const history = useHistory();
  const [count, setCount] = useState(1)
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth)
  const bookingListData = useSelector(selectBookingList)
  console.log(bookingListData, 'bookingListData');
  const [key, setKey] = useState(Math.random());
  const [slotId, setSlotId] = useState("")
  const [value,setValue]=useState('')
  const [dateValue, setDateValue] = useState("")
  const [slotStartTime, setSlotStartTime] = useState("")
  const [slotEndTime, setSlotEndTime] = useState("")
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    id:""
  });

  const handleIncrement = () => {
    setCount(Number(count) + 1)
  }
  const handleDecrement = () => {
    setCount(Number(count) - 1)
  }
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      id:""
    });
    setKey(Math.random());
  }
  const handleModalDetails = (data) => { 
    if (data === 'time' || data === 'reservetable' || data === "date") {
      setModalDetail({ show: true, flag: data }); setKey(Math.random());
    }
  }
  const handleSlotDetails = (data) => {
    setSlotId(data.slotId)
    setSlotStartTime(data.startTime)
    setSlotEndTime(data.endTime)
  }

  let data = {
    "tokenData": auth?.payload?.token
  }
  const handleUpdateBooking = (id) => {
    const updateData = {
      "postData": {
        "slot_id": slotId,
        "booking_date": dateValue,
        "no_of_members": count
      },
      "tokenData": auth?.payload?.token,
      "idData": id
    }
    dispatch(updateBookingByUserAsync(updateData))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.data?.msg)
        }
        dispatch(getBookingListAsync(data))
        handleOnCloseModal();
      }
      )
      .catch((obj) => {
      })
  }
  const handleDateAsync = (data) => {
    setDateValue(moment(data).format('MM-DD-YYYY'))
  }
  const cancelOrderHandle = async (id) => {
    const cancelData = {
      "postData": id,
      "tokenData": auth?.payload?.token
    }
    dispatch(cancelBookingByUserAsync(cancelData))
      .then(unwrapResult)
      .then(async (obj) => {
        toast.success(obj?.data?.msg)
        dispatch(getBookingListAsync(data))
        handleOnCloseModal()
      }
      )
      .catch((obj) => {
      })

  }

  const renderUpdateBookingModelHtml = () => {    
    return (
      <div className="modal-content">
        <div className="modal-header modal-headerbok">
          <button type="button" className="close" onClick={handleOnCloseModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="coinplan-modal">
            <div className="booktbl-modalcnt appoint-timemain">
              <h2>Book A Table</h2>
              <p>When do you want to book table?</p>
              <ul>
                <li onClick={() => { setModalDetail({ show: true, flag: 'date' }); setKey(Math.random()); }}>
                  <Link to="#">
                    <div className="appont-tmleft">
                      <h4><img src={CalenderDark2} alt="" /> Reservation </h4>
                    </div>
                    <div className="appont-tmright">
                      <span>{dateValue ? moment(dateValue).format('dddd, MMM Do YYYY') : moment(value?.booking_date).format('dddd, MMM Do YYYY')} <i className="fas fa-chevron-right"></i></span>
                    </div>
                  </Link>
                </li>


                {
                  value?.booking_date &&
                  <li onClick={() => { setModalDetail({ show: true, flag: 'time' }); setKey(Math.random()); }}>
                    <Link to="#">
                      <div className="appont-tmleft">
                        <h4><img src={ClockDark2} alt="" />Time</h4>
                      </div>
                      <div className="appont-tmright">
                        <span>{slotStartTime ? `${slotStartTime} - ${slotEndTime}`  :`${value?.slot?.start_time} - ${value?.slot?.end_time}` } <i className="fas fa-chevron-right"></i></span>
                      </div>
                    </Link>
                  </li>
                }
                <li>
                  <div className="appont-tmleft">
                    <h4><img src={UserDark2} alt="" />How many people?</h4>
                  </div>
                </li>
              </ul>
              <div className="people-number">
                <div className="quantity">
                  <form action="#" method="POST">
                    <span className="input-number-decrement" onClick={(count > 1) ? () => handleDecrement() : void (0)}><i className="fas fa-minus"></i></span>
                    <input className="input-number" type="text" value={count} onKeyDown={blockInvalidChar} onChange={(e) => setCount(e.target.value)} min="1" />
                    <span className="input-number-increment" onClick={() => handleIncrement()}><i className="fas fa-plus"></i></span>
                  </form>
                </div>
              </div>

              <div className="person-item">
                <img src={Person} alt="" />
              </div>
              <div className="reserve-btn" onClick={()=>handleUpdateBooking(value?.id)}>
                <Link to="#">Reserve</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reserveTable = (data) => {
    setValue(data)
    setCount(data?.no_of_members)
    setSlotId(data.slot_id)
    setDateValue(data.booking_date)
    setModalDetail({ show: true, flag: "reservetable",id:data?.id });
     setKey(Math.random());
  }

  const renderCancelOrderHtml = () => {
    return (
      <div className="modal-content">
        <div className="modal-body">
          <div className="modal-mainprt">
            <div className="mdlverify-cnt cardmdl-verify">
              <img src={cancelOrderImg} alt="veirfy2Img" />
              <h6>Cancel Booking</h6>
              <p>Are you sure,you want to cancel your booking?</p>
            </div>
            <div className="modal-footer" >
              <button className="btn cancleBtn " onClick={() => handleOnCloseModal()}  >No</button>
              <button className="btn btn-primary submitBtn" onClick={() => cancelOrderHandle(bookingListData?.data?.payload?.data[0]?.id)}>Yes,I'm sure</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(getBookingListAsync(data))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="order-heading">
        <div className="header-left-item">
          <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link>Table Booking List</h4>
        </div>
        <div className="header-right-item">
          <button type="button"><img src={bagImg} alt="bagImg" /></button>
          <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
        </div>
      </div>
      <div className="main-content">
        <div className="order-mainprt">
          <div className="tabs">
            <div className="tab-upper tab-upperhm noshadow">
              <ul className="tab-links text-left pb-4">
                <li className="active"><Link to="#tab1">Delivery</Link></li>
                <li><Link to="#tab2">Pickup</Link></li>
                <li><Link to="#tab2">Shipping</Link></li>
              </ul>
            </div>

            <div className="tab-content">
              {/* <!--  <div id="tab1" className="tab active">
                                <div className="browse-tab1">

                                </div>
                            </div>

                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div>
                            <div id="tab2" className="tab">
                                <div className="browse-tab2">

                                </div>
                            </div> --> */}
              {bookingListData && bookingListData?.data?.payload?.data?.length > 0 &&
                <div>
                  {bookingListData && bookingListData?.data?.payload?.data?.map((value, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="order-part">
                          <div className="order-item">
                            <div className="order-cnt">
                              <img style={{ width: "50px" }} src={value?.seller_data?.user_profiles?.profile_photo} alt="profileImg" />
                            </div>
                            <div className="order-cnt">
                              <h4>{value?.seller_data?.user_profiles?.username}</h4>
                              <p>{value?.seller_location?.outlate_location}</p>
                            </div>
                            <div className="activestatus">
                              <button disabled={value?.status == 1} onClick={() => { reserveTable(value) }} className="btn btn-primary">Edit</button>
                            </div>
                          </div>

                          <div className="orderStatus">
                            <h6>Date:
                              <br />
                              <i className="fa-regular fa-calendar-days"></i>
                              <span>{moment(value?.created_at).format('YYYY-MM-DD')}</span>
                              <span> at </span>
                              <span>{moment(value?.created_at).format('hh:mm A')}</span>
                            </h6>
                            <h6>Guests:
                              <br />
                              <span>{value?.no_of_members}</span>
                            </h6>
                            <h6>Name:
                              <br />
                              <span>{value?.booking_info}</span>
                            </h6>
                          </div>
                          <div className="order-btm">
                            <div><span></span><p>{value?.status == 0 ? "Booked" : value?.status == 1 ? "Cancelled" : <></>}</p></div>
                            <div >{value?.status == 0 && <Link className="btn btn-primary" onClick={() => { setModalDetail({ show: true, flag: "cancelOrder" }); setKey(Math.random()) }} to='#'>Cancel Booking</Link>}</div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })
                  }
                </div>
              }
            </div>
          </div>


        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={modalDetail.flag === 'cancelOrder' ? false : true}
        darkBackground={modalDetail.flag === 'cancelOrder' ? true : false}
        size="lg"
        child={modalDetail.flag === 'reservetable' ?
          renderUpdateBookingModelHtml()
          : (modalDetail.flag === 'time') ?
            <ReserveTimeModal sellerId={value?.seller_id} close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} handleSlot={(e) => handleSlotDetails(e)} date={dateValue} />
            : (modalDetail.flag === 'date') ? <ReserveDateModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} getDate={(e) => handleDateAsync(e)} /> : modalDetail.flag === 'cancelOrder' ? renderCancelOrderHtml() : <></>

        }
        onCloseModal={() => handleOnCloseModal()}
      />

    </>
  )
}

export default BookingList
