import React, { useEffect } from 'react'
import { ClockBlue, discountOutlineImg, dotCircleImg, emailImg, LeftCircle2, leftCircleImg, More1, Salon, searchCircleImg, Smserv1, Smserv2, Smserv3, starCircleImg, tellImg } from '../../../utilities/images'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { getProductListAsync, selectedProductData } from '../dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import OwlCarousel from 'react-owl-carousel';


const HealthFitnessStore = () => {
    const history = useHistory();
    const { status } = useSelector(state => state.dashboard);
    const { id } = useParams()
    const dispatch = useDispatch()
    const productList = useSelector(selectedProductData)
    const auth = useSelector(selectLoginAuth)


    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        dispatch(getProductListAsync(data))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="main-content">
            {status.productList === "loading" ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <>
                <div className="main">
                    <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.banner_image} className="img-fluid" />
                    <div className="topMain">
                        <div className="left-circle"><div onClick={() => history.goBack()}><img src={LeftCircle2} alt="" /></div></div><div className="store-iconrit rest-menu"><Link to="#"><img src={searchCircleImg} alt="" /></Link><a className="dot-menu" href="/home-restaurent/b169ed4d-be27-44eb-9a08-74f997bc6a2a"><img src={dotCircleImg} alt="" /></a></div>
                    </div>
                </div>
                <div className="store-banner-part">
                    <div className="store-box">
                        <div className="store-item">
                            <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.profile_photo} alt="" />
                        </div>

                        <div className="store-cnt hmserv-cnt">
                            <h4>{productList?.data?.payload?.sellerDetails[0]?.user_profiles?.username} <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                            <p><img src={starCircleImg} alt="" /> {productList?.data?.payload?.sellerRatings} ({productList?.data?.payload?.totalCountOfRatings}+ ratings)</p>
                            {/* <h6>Health & Fitness</h6> */}
                            <ul>
                                <li><img src={emailImg} alt="" /> {productList?.data?.payload?.sellerDetails[0]?.email}</li>
                                <li><img src={tellImg} alt="" /> {productList?.data?.payload?.sellerDetails[0]?.user_profiles?.full_phone_number}</li>
                                <li><img src={ClockBlue} alt="" /> OPEN till {productList?.data?.payload?.sellerDetails[0]?.user_profiles?.closing_time}</li>
                            </ul>
                        </div>
                    </div>
                    <div id="bannerSlider">
                        <OwlCarousel
                            items={1}
                            className="owl-theme"
                            loop={true}
                            nav={false}
                            dots={false}
                            autoplay={true}
                            autoplayTimeout={5000}
                            slidespeed={10}
                            dotsdata="false"
                            margin={1}
                        >
                            {
                                productList?.data?.payload?.sellerOffersList?.length > 0 && productList?.data?.payload?.sellerOffersList?.map((val, index) => {
                                    return (
                                        <>
                                            <div className="store-btn" key={index}>
                                                <Link to="#"><img src={discountOutlineImg} alt="" />
                                                    {val?.discount_percentage}% off upto {val?.max_discount}
                                                </Link>
                                                <div className="owl-dots dotstab d-block">
                                                    {productList?.data?.payload?.sellerOffersList?.map((val, index1) => {
                                                        return (
                                                            <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${productList?.data?.payload?.sellerOffersList?.length}` : ""}</span></button>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>

                <div className="hmserv-mainprt">
                {
                    productList?.data?.payload?.products?.map((val, i) => {
                        return (
                            <div key={i} className="hmserv-item" onClick={() => history.push('/home-health-fitness-menu')} style={{ cursor: 'pointer' }}>
                                <div className="hmserv-left">
                                    <img src={val?.image} alt="" />
                                </div>
                                <div className="hmserv-right">
                                    <h4>{val?.name}</h4>
                                    <h6>Starts from <strong>USD${val?.price}</strong></h6>
                                    <p>{val?.description}</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                <div className="belllIcon">
                    <img src={More1} alt="more" />
                </div>
                </>}
            </div>
        </>
    )
}

export default HealthFitnessStore
