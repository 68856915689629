import axios from "axios";

export function getSearchProduct(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_PRODUCT_URL}api/v1/global_search?page=1&limit=10&search=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}