import React from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ArrowWhite, BodyMassage1, BodyMassage2, CalendarClock, Info, leftArrowImg } from '../../../utilities/images'

const BookAppointment = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={() => history.goBack()}><img src={leftArrowImg} alt="" /></Link> Checkout </h4>
            </div>

            <div className="main-content cart-mainpd">
                <div className="product-delivery-area">
                    <div className="product-delvbox">
                        <img src={CalendarClock} alt="" />
                        <div className="rider-cnt">
                            <h6>Appointment time</h6>
                            <h2>Today, Sep 6, 2022</h2>
                            <p>2:00-4:00 pm <Link to='/appointment-time'>Change</Link></p>
                        </div>
                    </div>

                    <div className="select-card">
                        <img src={BodyMassage1} alt="" />
                        <div className="select-cardcnt">
                            <h4>Swedish massage</h4>
                            <p>Full body massage</p>
                        </div>
                        <div className="select-cardrit">
                            <span><i className="fas fa-times"></i></span>
                            <h6>$6.56</h6>
                        </div>
                    </div>
                    <div className="select-card">
                        <img src={BodyMassage2} alt="" />
                        <div className="select-cardcnt">
                            <h4>Aromatherapy massage</h4>
                            <p>Full body massage</p>
                        </div>
                        <div className="select-cardrit">
                            <span><i className="fas fa-times"></i></span>
                            <h6>$6.56</h6>
                        </div>
                    </div>

                    <div className="apply-couponbox">
                        <Link to='/coupons'>
                            <h4>Apply Coupon <small>Add your coupon here</small></h4>
                            <span><i className="fas fa-chevron-right"></i></span>
                        </Link>
                    </div>

                    <div className="cart-totalbox">
                        <ul>
                            <li>Sub Total <span>$20.65</span></li>
                            <li>Coupon <span>-$5.00</span></li>
                            <li><small>Taxes & Other fees <img src={Info} alt="" /></small> <span>$1.00</span></li>
                            <li>Delivery fees <span>$5.00</span></li>
                            <li><strong>Total</strong> <strong>$19.65</strong></li>
                        </ul>
                    </div>
                </div>
                <div className="selected-product checkout-box">
                    <div className="selected-itembox appoint-box">
                        <div className="selected-leftcnt">
                            <p><strong>Book an appointment</strong></p>
                        </div>
                        <div className="selected-rightbtn">
                            <Link to='/home-service'>
                                <img src={ArrowWhite} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookAppointment