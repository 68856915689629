import React, { useEffect } from 'react'
import {
    Food1, Food2, Food3, Food4, Food5, Food6, Food7, FoodSlider, ProductSt8, TrendingFood5, starCircleImg, clockImg, deliveryParcelImg
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getFoodDataAsync, selectFoodData, getCategoryApiAsync, selectCategoryData, selectTrendingReservationNearbyData, getTrendingNearBySellerReservationAsync } from "./dashboardSlice"

const FoodReservation = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    let megaOffer = [1, 2, 3, 4]
    const categoryData = useSelector(selectCategoryData)
    const trendingSellerData = useSelector(selectTrendingReservationNearbyData)

    useEffect(() => {
        dispatch(getCategoryApiAsync(1))
        dispatch(getTrendingNearBySellerReservationAsync(1))
    }, [dispatch])

    return (
        <div id="tab1" className="active">
            <div className="home-tab1prt">
                <div className="search-barhm">
                    <form action="#" method="POST">
                        <input type="search" placeholder="Search here" />
                    </form>
                </div>

                <div className="retail-part retail-part2">
                    {
                        categoryData?.data?.payload?.data?.map((val, index) => {
                            return (
                                <div className="retail-item" key={index}>
                                    <span>
                                        <img src={val?.image} alt="" />
                                    </span>
                                    <p>{val?.name}</p>
                                </div>
                            )
                        })
                    }
                    {categoryData?.data?.payload?.data?.length>8?
                    <div className="retail-item">
                        <Link to="#">
                            <img src={ProductSt8} alt="" />
                        </Link>
                        <p>More</p>
                    </div>: null}
                </div>

                <div id="bannerSlider">
                    <OwlCarousel
                        items={1}
                        className="owl-theme"
                        loop={true}
                        nav={false}
                        dots={false}
                        autoplay={true}
                        autoplayTimeout={1500}
                        slidespeed={100}
                        dotsdata="false"
                        margin={1}
                    >
                        {
                            megaOffer && megaOffer?.map((val, index) => {
                                return (
                                    <div className="carousel-cell" key={index}>
                                        <img src={FoodSlider} alt="" />
                                        <div className="owl-dots dotstab d-block">
                                            {megaOffer?.map((val, index1) => {
                                                return (
                                                    <button className={index === index1 ? "owl-dot active" : "owl-dot"} key={index1}><span>{index === index1 ? `${(index + 1)}/${megaOffer?.length}` : ""}</span></button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>

                {/* <div className="delivery-parthm" onClick={() => history.push('/restaurent-reservation')} style={{ cursor: 'pointer' }}>
                    <div className="delivery-sechm">
                        <img className="delv-prd" src={TrendingFood5} alt="" />
                        <h4>Harfy</h4>
                        <ul>
                            <li><img src={starCircleImg} alt="" /> 4.5</li>
                            <li><img src={clockImg} alt="" /> 26-50 min</li>
                            <li><img src={deliveryParcelImg} alt="" /> $6.49 Delivery fee</li>
                        </ul>
                    </div>
                </div> */}
                {trendingSellerData?.data?.payload?.data?.length > 0 &&
                    <div className="delivery-parthm">
                        {trendingSellerData?.data?.payload?.data?.map((value, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => history.push(`/restaurent-reservation/${value.unique_uuid}`)}>
                                        <img className="delv-prd" src={value?.user_profiles?.profile_photo} alt="" />
                                        <h4>{value?.user_profiles?.firstname}</h4>
                                        <ul>
                                            <li><img src={starCircleImg} alt="starCircleImg" />{value?.sellerRatings}</li>
                                            <li><img src={clockImg} alt="clockImg" />{value?.distance}</li>
                                            <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />{value?.deliveryFee} Delivery fee</li>
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                }


            </div>
        </div>
    )
}

export default FoodReservation