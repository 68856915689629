import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bagImg, bellImg, coinStackImg, leftArrowImg } from "../../../utilities/images";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import { selectUserProfile } from "../../containers/profile/profileSlice";

const MobileHeader = (props) => {
    const history = useHistory()
    const pathName = window.location.pathname;
    const auth = useSelector(selectLoginAuth)
    const selectProfile = useSelector(selectUserProfile);
    console.log(selectProfile,"select profile")
    return (
        <>
            <div className="header-part browse-addprdcard-header">
                <div className="header-left-item">
                    <div className="header-left">
                        {(pathName === '/dashboard') ? "" :
                            <Link to="/dashboard">
                                <img src={leftArrowImg} alt="leftArrow" />
                            </Link>
                        }
                        <Link to="#">{selectProfile?.payload?.user_profiles?.firstname}</Link>
                        <p className="location_select" onClick={()=> history.push('location-select')}>{selectProfile?.payload?.current_location?.formatted_address ? `${(selectProfile?.payload?.current_location?.formatted_address).substring(0,40)}...`:"Add Address"}</p>
                    </div>
                </div>
                <div className="header-right-item">
                    <button type="button" onClick={() => { history.push('/notifications') }}><img src={bellImg} alt="bellImg" /></button>
                    <button type="button" onClick={() => history.push('/cart')}><img src={bagImg} alt="bagImg" /></button>
                    <Link to="#">0
                        <img src={coinStackImg} alt="coinStack" />
                    </Link>
                </div>
            </div>
        </>
    );
};

export default MobileHeader;
