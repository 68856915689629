import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCoupons, verifiedCouponsApi } from "./couponApi";

const initialState = {
  couponsAll: null,
  verifiedCoupon: null,
  status: "idle",
};

export const couponsAsync = createAsyncThunk("all/coupons", async () => {
  const response = await getAllCoupons();
  return response;
});
export const verifiedCouponsApiAsync = createAsyncThunk("all/verifiedCouponsApi", async (data) => {
  const response = await verifiedCouponsApi(data.postData, data.tokenData);
  return response;
});

export const couponsSlice = createSlice({
  name: "coupons",
  initialState,
  extraReducers: {

    [couponsAsync.pending](state) {
      state.status = "loading";
    },
    [couponsAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.couponsAll = payload;
    },
    [couponsAsync.rejected](state, { error }) {
      state.status = "idle";
      state.couponsAll = [];
    },
    [verifiedCouponsApiAsync.pending](state) {
      state.status = "loading";
    },
    [verifiedCouponsApiAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.verifiedCoupon = payload;
    },
    [verifiedCouponsApiAsync.rejected](state, { error }) {
      state.status = "idle";
      state.verifiedCoupon = [];
    }
  },
});

export const selectCouponsAll = (state) => state.coupons.couponsAll;
export const selectVerifiedCoupon = (state) => state.coupons.verifiedCoupon;
export default couponsSlice.reducer;