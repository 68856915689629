import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    delv1Img, tobaccosImg, vapeImg, cigarImg, cigarettesImg,
    mapImg,
    Food1,
    Food2,
    Food3,
    Food4,
    TrendingFood2
} from "../../../utilities/images";
import { Link } from 'react-router-dom';

const DashboardPickup = (props) => {
    const [tabView, setTabView] = useState(1)
    return (
        <div className="home-tab2prt">
            <div className="map-hmprt">
                <img src={mapImg} alt="mapImg" />
                <form action="#" method="POST">
                    <div className="maphm-searchbar">
                        <input type="search" placeholder="Search here" />
                    </div>
                </form>
            </div>

            {(props?.value == "food") ?
                <div className="maphm-btmprt">
                    <div className="maphm-btmlinks">
                        <ul>
                            <li onClick={() => { setTabView(1) }}><Link to="#" className={(tabView == 1) ? `active` : ''}><img src={Food1} alt="Food1" /> Fast Food</Link></li>
                            <li onClick={() => { setTabView(2) }}><Link to="#" className={(tabView == 2) ? `active` : ''}><img src={Food2} alt="Food2" /> Pizza</Link></li>
                            <li onClick={() => { setTabView(3) }}><Link to="#" className={(tabView == 3) ? `active` : ''}><img src={Food3} alt="Food3" /> Coffee</Link></li>
                            <li onClick={() => { setTabView(4) }}><Link to="#" className={(tabView == 4) ? `active` : ''}><img src={Food4} alt="Food4" /> Indian Food</Link></li>
                        </ul>
                    </div>
                    <div className="maphm-btmitem">
                        <img src={TrendingFood2} className="d-block w-100" alt="delv1Img" />
                    </div>
                </div>
                :
                <div className="maphm-btmprt">
                    <div className="maphm-btmlinks">
                        <ul>
                            <li onClick={() => { setTabView(1) }}><Link to="#" className={(tabView == 1) ? `active` : ''}><img src={tobaccosImg} alt="tobaccosImg" /> Tobaccos</Link></li>
                            <li onClick={() => { setTabView(2) }}><Link to="#" className={(tabView == 2) ? `active` : ''}><img src={vapeImg} alt="vapeImg" /> Vape</Link></li>
                            <li onClick={() => { setTabView(3) }}><Link to="#" className={(tabView == 3) ? `active` : ''}><img src={cigarImg} alt="cigarImg" /> Cigar</Link></li>
                            <li onClick={() => { setTabView(4) }}><Link to="#" className={(tabView == 4) ? `active` : ''}><img src={cigarettesImg} alt="cigarettesImg" /> Cigarettes</Link></li>
                        </ul>
                    </div>
                    <div className="maphm-btmitem">
                        <img src={delv1Img} className="d-block w-100" alt="delv1Img" />
                    </div>
                </div>
            }
        </div>
    )
}

export default DashboardPickup