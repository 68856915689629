import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
    leftArrowImg, bellImg, bagImg, bxsCoinStackImg, ProductSt8, Fashion1, Fashion2, Fashion3, Fashion4, Fashion5, Fashion6, Fashion7, FashionBanner, FashionItem1, FashionItem2, FashionItem3, FashionItem4, Story1, starCircleImg, clockImg, deliveryParcelImg,
} from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
import { useSelector, useDispatch } from "react-redux";
import { getBannerByServiceTypeAsync, getCategoryApiAsync, getFoodDataAsync, getTrendingCategoryServiceTypeAsync, getTrendingNearBySellerAsync, selectBannerByServiceId, selectCategoryByServiceId, selectCategoryData, selectFoodData, selectTrendingNearbyData } from "./dashboardSlice"
import MobileHeader from '../../components/shared/MobileHeader';
import { selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/PaginationSetting';
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle";

const HomeFashion = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const auth = useSelector(selectLoginAuth)
    const [pagination, setPagination] = useState(PaginationSetting)
    const [page, setPage] = useState(1);
    const trendingCategoryData = useSelector(selectCategoryByServiceId)
    console.log(trendingCategoryData, 'trendingCategoryData');
    const foodData = useSelector(selectFoodData);
    const { status } = useSelector(state => state.dashboard)
    const bannerData = useSelector(selectBannerByServiceId)
    console.log(bannerData, 'bannerData');
    const categoryData = useSelector(selectCategoryData)
    const trendingSellerData = useSelector(selectTrendingNearbyData)
    console.log(categoryData, "categoryData")

    const bannerSettings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="slideContent" >{`${i + 1}/${(bannerData?.data?.payload?.data?.length)}`}</div>
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let bannerData = {
            "postData": 4,
            "tokenData": auth?.payload?.token
        }
        dispatch(getFoodDataAsync())
        dispatch(getCategoryApiAsync(4))
        dispatch(getTrendingCategoryServiceTypeAsync(4))
        dispatch(getBannerByServiceTypeAsync(bannerData))
    }, [dispatch])

    useEffect(() => {
        let params = {
            page: page,
            limit: pagination.recordsPerPage,
            service_id: 4,
            lat: 30.7355,
            serviceType: 1,
            long: 76.7121
        }
        dispatch(getTrendingNearBySellerAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
            }
            )
            .catch((obj) => {
            })
    }, [page])
    return (

        <>
            <MobileHeader />

            <div className="search-barhm">
                <form action="#" method="POST">
                    <input type="search" placeholder="Search here" onFocus={() => history.push('/browse/4')} />
                </form>
            </div>
            {
                status.categoryData === "loading" || status.bannerData === "loading" || status.trendingCategoryData === "loading" || status.trendingNearby === "loading" ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        {/* category  */}

                        <div className="retail-part retail-part2">
                            {
                                categoryData?.data?.payload?.data?.map((val, index) => {
                                    return (
                                        <div className="retail-item" key={index} onClick={() => history.push(`/sellers/${val?.id}`)}>
                                            <span>
                                                <img src={val?.image} alt="" />
                                            </span>
                                            <p>{val?.name}</p>
                                        </div>
                                    )
                                })
                            }

                            {categoryData?.data?.payload?.data?.length > 8 ?
                                <div className="retail-item">
                                    <Link to="/category-list/4">
                                        <img src={ProductSt8} alt="" />
                                    </Link>
                                    <p>More</p>
                                </div> : null
                            }

                        </div>
                        {/* mega slider */}

                        <div id='bannerSlider' className="banner-slider trslider-part">
                            <SliderWrapper>
                                <Slider {...bannerSettings}>
                                    {
                                        bannerData?.data?.payload?.data && bannerData?.data?.payload?.data?.map((val, index) => {
                                            return (
                                                <div className="carousel-cell" key={index}>
                                                    <img src={val?.path} alt="" />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </SliderWrapper>
                        </div>

                        <div className="retail-mainprt">
                            <div className="row">
                                {
                                    trendingCategoryData?.data?.payload?.map((val, index) => {
                                        return (
                                            <div className="col-6" key={index} onClick={() => history.push(`/sellers/${val?.id}`)}>
                                                <div className="retail-product">
                                                    <img src={val?.image} alt="" />
                                                    <span>{val?.description}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* health delivery */}


                        {
                            trendingSellerData?.data?.payload?.data?.length > 0 &&
                            <div className="delivery-parthm p-bottom">
                                <div className="trending-title pt-5">
                                    <h2>Trending</h2>
                                </div>
                                {trendingSellerData?.data?.payload?.data?.map((value, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="delivery-sechm" style={{ cursor: 'pointer' }} onClick={() => history.push(`/home-fashion-store/${value?.unique_uuid}`)}>
                                                <img className="delv-prd" src={value?.user_profiles?.profile_photo} alt="" />
                                                <h4>{value?.user_profiles?.firstname}</h4>
                                                <ul>
                                                    <li><img src={starCircleImg} alt="starCircleImg" />{value?.sellerRatings}</li>
                                                    <li><img src={clockImg} alt="clockImg" />{value?.distance}</li>
                                                    <li><img src={deliveryParcelImg} alt="deliveryParcelImg" />${value?.deliveryFee} Delivery fee</li>
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>

                        }
                        {pagination.totalRecords > pagination.recordsPerPage &&
                            <Box py={3} display="flex" justifyContent="center">
                                <Pagination
                                    key={page}
                                    count={pagination.totalPages || 0}
                                    color="secondary"
                                    variant="outlined"
                                    onChange={(e, value) => setPage(value)}
                                    defaultPage={page}
                                />
                            </Box>
                        }
                    </>}
        </>
    )
}

export default HomeFashion