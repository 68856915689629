import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from 'react-router-dom';
import { leftArrowImg, SearchImg, bxsCoinStackImg, tob1Img, tob2Img, tob3Img, tob4Img, tob5Img, tob6Img, tob7Img, tob8Img, tob9Img, tob10Img, tob11Img, tob12Img, tob13Img, tob14Img } from "../../../utilities/images";
import { getCategoryApiAsync, selectCategoryData } from "./dashboardSlice";

const CategoryListing = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const { id } = useParams()
    const categoryData = useSelector(selectCategoryData)
    useEffect(() => {
        dispatch(getCategoryApiAsync(id))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link>Category</h4>
                </div>
                <div className="header-right-item">
                    <button>
                        <img src={SearchImg} alt="SearchImg" />
                    </button>
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>
            <div className="main-content">
                <div className="tobacco-mainitem">
                    <ul>
                        {
                            categoryData?.data?.payload?.data?.map((val, index) => {
                                return (
                                    <li><Link to={`/sellers/${val?.id}`}><img src={val?.image} alt="tob1Img" /> {val?.name} <i className="fas fa-chevron-right"></i></Link></li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    );
}

export default CategoryListing;