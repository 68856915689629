import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ClockBlue, dotCircleImg, emailImg, FoodGallery1, FoodGallery2, FoodGallery3, FoodGallery4, FoodGallery5, FoodGallery6, HomeFood, LeftCircle2, Plater, searchCircleImg, starCircleImg, tellImg } from '../../../utilities/images'
import CustomModal from '../../components/shared/CustomModal'
import ReserveTableModal from './ReserveTableModal'
import ReserveTimeModal from './ReservationTimeModal'
import ReserveDateModal from './ReservationDateModal'
import { getProductListAsync, selectedProductData } from './dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../auth/authSlice'
import moment from 'moment-timezone'

const HomeFoodReservation = () => {
    const history = useHistory()
    const { id } = useParams()
    const [key, setKey] = useState(Math.random());
    const [slotId, setSlotId] = useState("")
    const [dateValue, setDateValue] = useState("")
    const [slotStartTime, setSlotStartTime] = useState("")
    const [slotEndTime, setSlotEndTime] = useState("")
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const productList = useSelector(selectedProductData)
    console.log(productList, "product list")

    const handleModalDetails = (data) => {
        if (data === 'time' || data === 'reservetable' || data === "date") {
            setModalDetail({ show: true, flag: data }); setKey(Math.random());
        }
    }
    const handleSlotDetails = (data) => {
        setSlotId(data.slotId)
        setSlotStartTime(data.startTime)
        setSlotEndTime(data.endTime)
        console.log(data, "data id")
    }
    const handleDateAsync = (data) => {
        setDateValue(moment(data).format('MM-DD-YYYY'))
    }
    let data = {
        "postData": id,
        "tokenData": auth?.payload?.token
    }
    useEffect(() => {
        dispatch(getProductListAsync(data))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="main-content">
            <div className="main">
                <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.banner_image} className="img-fluid" />
                <div className="topMain">
                    <div className="left-circle"><a href="/home-food"><img src={LeftCircle2} alt="" /></a></div><div className="store-iconrit rest-menu"><a href="/home-restaurent/b169ed4d-be27-44eb-9a08-74f997bc6a2a"><img src={searchCircleImg} alt="" /></a><a className="dot-menu" href="/home-restaurent/b169ed4d-be27-44eb-9a08-74f997bc6a2a"><img src={dotCircleImg} alt="" /></a></div>
                </div>
            </div>
            <div className="store-banner-part noshadow">
                <div className="store-box store-boxhmfd">
                    <div className="store-item">
                        <img src={productList?.data?.payload?.sellerDetails[0]?.user_profiles?.profile_photo} alt="" />
                    </div>

                    <div className="store-cnt hmserv-cnt">
                        <h4>{productList?.data?.payload?.sellerDetails[0]?.user_profiles?.username} <Link to="#"> <i className="far fa-heart"></i></Link></h4>
                        <p><img src={starCircleImg} alt="" /> {productList?.data?.payload?.sellerRatings} ({productList?.data?.payload?.totalCountOfRatings}+ ratings)</p>
                        <h6>Restaurant</h6>
                        <ul>
                            <li><img src={emailImg} alt="" /> {productList?.data?.payload?.sellerDetails[0]?.email}</li>
                            <li><img src={tellImg} alt="" /> {productList?.data?.payload?.sellerDetails[0]?.user_profiles?.phone_no}</li>
                            <li><img src={ClockBlue} alt="" /> OPEN till {productList?.data?.payload?.sellerDetails[0]?.user_profiles?.closing_time}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="hmfood-maincnt">
                <p>{productList?.data?.payload?.sellerDetails[0]?.user_profiles?.about_us}</p>

                <div className="hmfood-gallery">
                    {
                        productList?.data?.payload?.sellerRestaurantImages?.map((val, index) => {
                            return (
                                <div className="hmfood-glitem" key={index}>
                                    <img src={val?.image} alt="" />
                                </div>
                            )
                        })
                    }

                    {/* <div className="hmfood-glitem">
                        <img src={FoodGallery2} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery3} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery4} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery5} alt="" />
                    </div>
                    <div className="hmfood-glitem">
                        <img src={FoodGallery6} alt="" />
                    </div> */}
                </div>

                <div className="hmfood-btn">
                    <Link className="menu" to="#"><img src={Plater} alt="" /> Menu</Link>
                    <Link to="#" className="book-tble" onClick={() => { setModalDetail({ show: true, flag: "reservetable" }); setKey(Math.random()); }}>Book table</Link>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                size="md"
                child={modalDetail.flag === 'reservetable' ?
                    <ReserveTableModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} slotID={slotId} sellerId={id} date={dateValue} slotStartTime={slotStartTime} slotEndTime={slotEndTime}/>
                    : (modalDetail.flag === 'time') ?
                        <ReserveTimeModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} handleSlot={(e) => handleSlotDetails(e)} date={dateValue}/>
                        : (modalDetail.flag === 'date') ? <ReserveDateModal close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} getDate={(e) => handleDateAsync(e)}/>
                            : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default HomeFoodReservation