import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getAllBrowse,
} from "./browserApi";

const initialState = {
    category: null,
    status: "idle",
};

export const getBrowseAsync = createAsyncThunk("browse/data", async (data) => {
    const response = await getAllBrowse();
    return response;
});

export const browseSlice = createSlice({
    name: "browse",
    initialState,

    extraReducers: {
        [getBrowseAsync.pending](state) {
            state.status = "loading";
        },
        [getBrowseAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.category = payload;
        },
        [getBrowseAsync.rejected](state, { error }) {
            state.status = "idle";
            state.category = [];
        }
    },
});

export const selectBrowseData = (state) => state.browse.category;

export default browseSlice.reducer;