import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { leftArrowImg, bxsCoinStackImg } from "../../../utilities/images";
import { selectUserFaq } from "../faq/faqSlice";

const MyAccountTwo = (props) => {
    const history = useHistory();
    const location = useLocation();
    const FaqId = location.state ? location.state.faqid : '';
    const selectFaq = useSelector(selectUserFaq);
    let selectedFaq = selectFaq?.faq?.data?.find(item => item.id == FaqId);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link>{selectedFaq ? selectedFaq.question : "No question"}</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0  <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="update-accinfo">
                    <h4>{selectedFaq ? selectedFaq?.question : "No question"}</h4>
                    <p>{selectedFaq ? selectedFaq?.answer : "No answer"}</p>
                    <div className="help-btn">
                        <Link to="#" className="active">Helpful</Link>
                        <Link to="#">No! I need more help</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyAccountTwo;