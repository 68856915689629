import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
    leftArrowImg, bellImg, bagImg, bxsCoinStackImg,
} from "../../../utilities/images";
import MobileHeader from '../../components/shared/MobileHeader';
import DashboardPickup from './DashboardPickup';
import FoodDeliveryTab from './FoodDeliveryTab';
import FoodReservation from './FoodReservation';

const HomeFood = () => {
    const history = useHistory()
    const [tabData, setTabData] = useState("tab1")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <MobileHeader />
            <div className="main-content">
                <div className="home-tab">
                    <div className="tabs">
                        <div className="tab-upper tab-upperhm">
                            <ul className="tab-links">
                                <li className={(tabData === "tab1") ? "active" : ""} onClick={() => setTabData("tab1")}><Link to="#food-delivery">Delivery</Link></li>
                                <li className={(tabData === "tab2") ? "active" : ""} onClick={() => setTabData("tab2")}><Link to="#food-pickup">Pickup</Link></li>
                                <li className={(tabData === "tab3") ? "active" : ""} onClick={() => setTabData("tab3")}><Link to="#food-reservation">Reservation</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content">
                        {
                            (tabData === "tab1") ?
                                <FoodDeliveryTab />
                                : (tabData === "tab2") ?
                                    <DashboardPickup value="food" />
                                    : <FoodReservation />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeFood