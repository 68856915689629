import axios from "axios";

export function getUserFaq(data) {
    return new Promise((resolve, reject) =>
        axios.get(`${process.env.REACT_APP_SUPPORT}api/v1/faqs`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.error(error);
            })
    )
}