import React from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { bagImg, bellImg, CalenderDark2, ClockDark2, leftArrowImg } from '../../../utilities/images'

const AppointmentTime = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="order-heading">
                <h4><Link to="#" onClick={()=>history.goBack()}><img src={leftArrowImg} alt=""/></Link></h4>
                <div className="header-right-item">
                    <button type="button" onClick={()=>history.push('/notifications')}><img src={bellImg} alt=""/></button>
                    <button type="button"><img src={bagImg} alt=""/></button>
                </div>
            </div>

            <div className="main-content cart-mainpd">
                <div className="bodymsg-appoint-time">
                    <div className="appoint-timemain">
                        <h2>Appointment time</h2>
                        <p>When do you want to book time?</p>
                        <ul>
                            <li>
                                <Link to="#">
                                    <div className="appont-tmleft">
                                        <h4><img src={CalenderDark2} alt=""/>Appointment</h4>
                                    </div>
                                    <div className="appont-tmright">
                                        <span>Today, Sep 3, 2022 <i className="fas fa-chevron-right"></i></span>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <div className="appont-tmleft">
                                        <h4><img src={ClockDark2} alt=""/> Time</h4>
                                    </div>
                                    <div className="appont-tmright">
                                        <span>7:00 PM <i className="fas fa-chevron-right"></i></span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>                   

                    <div className="reserve-btn mt-auto">
                        <Link to="/book-appointment">Reserve</Link>
                    </div>
                </div>                                                 
            </div>
        </>
    )
}

export default AppointmentTime