import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import identityImg from "../../public/images/identity.png";
import idCardImg from "../../public/images/id-card.png";
import lefArrowImg from "../../public/images/left-arrow.svg";
import { selectIdCardPhoto1, selectIdCardPhoto2, uploadIdCardAsync, uploadIdCardAsync1 } from './authSlice';
import { selectLoginAuth, verifyAgeAsync } from './authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const AgeVerification = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const toastId = React.useRef(null)
    const history = useHistory();
    const [document, setDocument] = useState("");
    const [document1, setDocument1] = useState("");
    const [selectedData, setSelectedData] = useState("idcard")
    const idCardPhoto1 = useSelector(selectIdCardPhoto1);
    const idCardPhoto2 = useSelector(selectIdCardPhoto2);
    const loginAuth = useSelector(selectLoginAuth);

    // const confirmIdentity = location.state ?  location.state.imageSrc : "";

    const handleChange = (e, flag) => {
        const imageFile = e.target.files[0];
        if (flag == 'document_1') {
            setDocument(URL.createObjectURL(imageFile));
            let params = {
                document: imageFile
            }
            dispatch(uploadIdCardAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                }
                )
                .catch((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(obj?.message)
                        console.log(obj)
                    }
                })
        }
        if (flag == 'document_2') {
            setDocument1(URL.createObjectURL(imageFile));
            let params = {
                document: imageFile
            }
            dispatch(uploadIdCardAsync1(params))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                }
                )
                .catch((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(obj?.message)
                        console.log(obj)
                    }
                })
        }
    }

    let data = {
        "postData": {
            "identity_type": selectedData,
            "identity_urls": [
                {
                    "document": idCardPhoto1?.payload?.profile_photo
                },
                {
                    "document": idCardPhoto2?.payload?.profile_photo
                }
            ]
        },
        "tokenData": loginAuth?.payload?.token
    }
    // console.log(data, "login auth data")
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!document || !document1) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select the documents");
            }

            return;
        }
        await dispatch(verifyAgeAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/age-verification-check')
            }
            )
            .catch((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(obj?.message)
                    console.log(obj)
                }
            })
    }

    // let stateDataFront = { imageData: "back", loc: "/age-verification" }

    // useEffect(() => {
    //     setDocument(localStorage.getItem('idimage') ? localStorage.getItem('idimage') : idCardImg)
    //     setDocument1(localStorage.getItem('back') ? JSON.parse(localStorage.getItem('back')) : idCardImg)
    // }, [])

    useEffect(() => {
        window.scrollTo(0, 0)   
          }, [])

    return (
        <>
            <div className="age-upper">
                <Link to="#" onClick={() => { history.goBack() }}><img src={lefArrowImg} alt="lefArrowImg" /> Age verification</Link>
            </div>

            <div className="age-main">
                <div className="age-cnt">
                    <h2>Proof of Age</h2>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution</p>
                </div>
                <div className="age-item">
                    <h4>Choose your identity type</h4>
                    {/* <form method="post" action="#"> */}
                    <ul>
                        <li>
                            <div className="custom-radiobtn">
                                <input type="radio" id="idcard" name="identity" value="idcard" checked={selectedData === 'idcard'} onChange={e => { setSelectedData(e.target.value) }} />
                                <label htmlFor="idcard">ID card</label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-radiobtn">
                                <input type="radio" id="passport" name="identity" value="passport" checked={selectedData === 'passport'} onChange={e => { setSelectedData(e.target.value) }} />
                                <label htmlFor="passport">Passport</label>
                            </div>
                        </li>
                        <li>
                            <div className="custom-radiobtn">
                                <input type="radio" id="drlicence" name="identity" value="driving" checked={selectedData === 'driving'} onChange={e => { setSelectedData(e.target.value) }} />
                                <label htmlFor="drlicence">Driving licence</label>
                            </div>
                        </li>
                    </ul>
                    <div className="upload-btn-wrapper upload-btn-wrapper2">
                        <button className="filebtn">
                            <span className="upload-cnt">
                                <h4>Upload your ID card</h4>
                                <p>We accept only <span>ID card, passport, driving licence</span></p>
                            </span>
                            <span className="upload-item">
                                <img src={idCardPhoto1 ? idCardPhoto1?.payload?.profile_photo : idCardImg} alt="idCardImg" />
                            </span>
                        </button>
                        <input type="file" name="myfile" onChange={(e) => { handleChange(e, 'document_1') }} />
                    </div>
                    <div className="upload-btn-wrapper upload-btn-wrapper2">
                        <button className="filebtn">
                            <span className="upload-cnt">
                                <h4>Confirm your identiry</h4>
                                <p>We accept only <span>ID card, passport, driving licence</span></p>
                            </span>
                            <span className="upload-item" >
                                <img src={idCardPhoto2 ? idCardPhoto2?.payload?.profile_photo : identityImg} alt="identityImg" />
                                {/* onClick={() => { history.push({ pathname: '/AgeVerificationTwo', state: JSON.stringify(stateDataFront) }) }} */}
                            </span>
                        </button>
                        <input type="file" name="myfile" onChange={(e) => { handleChange(e, 'document_2') }} />
                    </div>
                    {/* </form> */}
                </div>
            </div>

            {
                (document && document1) ?
                    <div className="age-bottom">
                        <Link to="#" onClick={(e) => { handleOnSubmit(e) }}>Submit</Link>
                    </div> : ""
            }
        </>
    )
}

export default AgeVerification;