import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { leftArrowImg, bxsCoinStackImg, home2Img, edit2Img, workImg, invoiceImg } from "../../../utilities/images";
import { selectLoginProfile } from "../auth/authSlice";
import { allAddressAsync, selectAllAddress } from "./addressSlice";

const Address = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation()
    const selectLoginUser = useSelector(selectLoginProfile);
    const allAddresssData = useSelector(selectAllAddress);
    console.log(allAddresssData, "addresss data")

    useEffect(() => {
        let params = {
            token: selectLoginUser.payload.token,
        }
        dispatch(allAddressAsync(params))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="order-heading">
                <div className="header-left-item">
                    <h4><Link to="#" onClick={() => { history.goBack() }}><img src={leftArrowImg} alt="leftArrowImg" /></Link> Addresses</h4>
                </div>
                <div className="header-right-item">
                    <Link to="#">0 <img src={bxsCoinStackImg} alt="bxsCoinStackImg" /></Link>
                </div>
            </div>

            <div className="main-content">
                <div className="address-part">
                    <form action="#" method="POST">
                        {allAddresssData?.payload?.data?.length > 0
                            ?
                            <div className="row">
                                {allAddresssData?.payload?.data.map((address, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div key={index}
                                                onClick={() => { location.state ? history.push({ pathname: "/placing-1", state: JSON.stringify(address) }) : console.log() }}
                                                className={location.state ? "address-item add_pointer" : "address-item"}>
                                                <div className="radio-btn">
                                                    {
                                                        address?.address_type === "Home" ?
                                                            <img src={home2Img} alt="home2Img" />
                                                            : address?.address_type === "Work" ?
                                                                <i class="fa fa-briefcase"></i> :
                                                                address?.address_type === "Billing" ?
                                                                    <img src={invoiceImg} alt="invoiceImg" /> :
                                                                    <i className="fas fa-plus"></i>
                                                    }
                                                </div>
                                                <div className="address-cnt">
                                                    <h4>{address.address_type}</h4>
                                                    <p>{address.custom_address}<br /></p>
                                                </div>
                                                <div className="edit-btn">
                                                    <Link to={`/change-address/${address.id}`}>
                                                        <img src={edit2Img} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            :
                            <>
                                <div>
                                    <p style={{ textAlign: 'center' }}>No Data Found</p>
                                </div>
                            </>
                        }
                    </form>
                    <div className="address-btn" onClick={() => { history.push({ pathname: '/new-address', state: props?.location?.state }) }}>
                        <Link to="#">Add new address</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Address;