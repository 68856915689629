import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  registerSendOtp,
  verifyOtp,
  loginApi,
  registerApi,
  changePinApi,
  verifyAgeDocumentApi,
  getIdCardPhoto
} from "./authApi";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  otp: null,
  verify: null,
  changepin: null,
  verifyAge: null,
  uploadIdCardPhoto1: null,
  uploadIdCardPhoto2: null,
  status: "idle",
  status1: {
    otpStatus: "idle",
    verifyStatus: "idle",
    userStatus: "idle",
    loginStatus: "idle"
  }
};

export const registerOtpSendAsync = createAsyncThunk("auth/registerotp", async (data, { rejectWithValue }) => {
  const response = await registerSendOtp(data);
  return response.data;
});
export const registerOtpVerifyAsync = createAsyncThunk("auth/verifyotp", async (data) => {
  const response = await verifyOtp(data);
  return response.data;
});
export const loginAsync = createAsyncThunk("auth/login", async (data) => {
  const response = await loginApi(data);
  return response.data;
});

export const registerAsync = createAsyncThunk("auth/register", async (data) => {
  const response = await registerApi(data);
  return response.data;
});
export const changePinAsync = createAsyncThunk("auth/changepin", async (data) => {
  const response = await changePinApi(data);
  return response.data;
});
export const verifyAgeAsync = createAsyncThunk("auth/verifyage", async (data) => {
  const response = await verifyAgeDocumentApi(data.postData, data.tokenData);
  return response.data;
});
export const uploadIdCardAsync = createAsyncThunk("idCard/photo", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});
export const uploadIdCardAsync1 = createAsyncThunk("idCard/photo1", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.otp = null;
      state.verify = null;
      state.changepin = null;
      state.uploadIdCardPhoto1 = null;
      state.uploadIdCardPhoto2 = null;
      toast.success("Successfully Logout")
    },
  },

  extraReducers: {
    [registerOtpSendAsync.pending](state) {
      state.status1.otpStatus = "loading";
    },
    [registerOtpSendAsync.fulfilled](state, { payload }) {
      state.status1.otpStatus = "idle";
      state.otp = payload;
    },
    [registerOtpSendAsync.rejected](state, { error }) {
      state.status1.otpStatus = "idle";
      state.otp = [];
    },
    [registerOtpVerifyAsync.pending](state) {
      state.status1.verifyStatus = "loading";
    },
    [registerOtpVerifyAsync.fulfilled](state, { payload }) {
      state.status1.verifyStatus = "idle";
      state.verify = payload;
    },
    [registerOtpVerifyAsync.rejected](state, { error }) {
      state.status1.verifyStatus = "idle";
      state.verify = [];
    },
    [registerAsync.pending](state) {
      state.status1.userStatus = "loading";
    },
    [registerAsync.fulfilled](state, { payload }) {
      state.status1.userStatus = "idle";
      state.user = payload;
    },
    [registerAsync.rejected](state, { error }) {
      state.status1.userStatus = "idle";
      state.user = [];
    },
    [loginAsync.pending](state) {
      state.status1.loginStatus = "loading";
    },
    [loginAsync.fulfilled](state, { payload }) {
      state.status1.loginStatus = "idle";
      state.user = payload;
    },
    [loginAsync.rejected](state, { error }) {
      state.status1.loginStatus = "idle";
      state.user = [];
    },
    [changePinAsync.pending](state) {
      state.status = "loading";
    },
    [changePinAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.changepin = payload;
    },
    [changePinAsync.rejected](state, { error }) {
      state.status = "idle";
      state.changepin = [];
    },
    [verifyAgeAsync.pending](state) {
      state.status = "loading";
    },
    [verifyAgeAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.verifyAge = payload;
    },
    [verifyAgeAsync.rejected](state, { error }) {
      state.status = "idle";
      state.verifyAge = [];
    },
    [uploadIdCardAsync.pending](state) {
      state.status = "loading";
    },
    [uploadIdCardAsync.fulfilled](state, { payload }) {
      state.status = "idle";
      state.uploadIdCardPhoto1 = payload;
    },
    [uploadIdCardAsync.rejected](state, { error }) {
      state.status = "idle";
      state.uploadIdCardPhoto1 = [];
    },
    [uploadIdCardAsync1.pending](state) {
      state.status = "loading";
    },
    [uploadIdCardAsync1.fulfilled](state, { payload }) {
      state.status = "idle";
      state.uploadIdCardPhoto2 = payload;
    },
    [uploadIdCardAsync1.rejected](state, { error }) {
      state.status = "idle";
      state.uploadIdCardPhoto2 = [];
    }
  },
});

export const { logout } = authSlice.actions;
export const selectLoginAuth = (state) => state.auth.user;
export const selectLoginProfile = (state) => state.auth.user;
export const selectOtpId = (state) => state.auth.otp;
export const selectVerifyResponse = (state) => state.auth.verify;
export const selectIdCardPhoto1 = (state) => state.auth.uploadIdCardPhoto1;
export const selectIdCardPhoto2 = (state) => state.auth.uploadIdCardPhoto2;
export default authSlice.reducer;