import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getDeliveryTypesApi,
    getAllDeliveryTypesApi
} from "./deliveryApi";

const initialState = {
    deliveryTypes: [],
    status: {
        deliveryTypes: 'idle',
    },
};

export const getDeliveryTypesApiAsync = createAsyncThunk("delivery/getDeliveryTypesApi", async (data) => {
    const response = await getDeliveryTypesApi(data.postData, data.tokenData);
    return response;
});
export const getAllDeliveryTypesApiAsync = createAsyncThunk("delivery/getAllDeliveryTypesApi", async (data) => {
    const response = await getAllDeliveryTypesApi(data);
    return response;
});

export const deliverySlice = createSlice({
    name: "delivery",
    initialState,
    extraReducers: {
        [getDeliveryTypesApiAsync.pending](state) {
            state.status.deliveryTypes = "loading";
        },
        [getDeliveryTypesApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryTypes = "idle";
            state.deliveryTypes = payload;
        },
        [getDeliveryTypesApiAsync.rejected](state, { error }) {
            state.status.deliveryTypes = "idle";
            state.deliveryTypes = [];
        },
        [getAllDeliveryTypesApiAsync.pending](state) {
            state.status.deliveryTypes = "loading";
        },
        [getAllDeliveryTypesApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryTypes = "idle";
            state.deliveryTypes = payload;
        },
        [getAllDeliveryTypesApiAsync.rejected](state, { error }) {
            state.status.deliveryTypes = "idle";
            state.deliveryTypes = [];
        }
    },
});

export const selectDeliveryTypeData = (state) => state.delivery.deliveryTypes;

export default deliverySlice.reducer;